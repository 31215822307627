<template>
  <div>
    <div class="flex align-center">
      满<el-input ref="target" v-model="target.target" type="number" :min="1" class="margin-lr-sm" style="width: 150px"></el-input>
      {{ unit.target }}
      奖励<el-input ref="award" v-model="target.award" type="number" :min="1" class="margin-lr-sm" style="width: 150px"></el-input>
      {{ unit.award }}
    </div>
    <el-alert v-show="type==='lose'" class="margin-left-sm margin-top" type="warning" show-icon>
      “X天内”包含活动上线当日，第x+1天可领取
    </el-alert>
  </div>
</template>
<script>
export default {
  props: {
    type: {
      type: String,
      default: ''
    },
    unit: {
      type: Object,
      default: () => { return {}}
    },
    value: {
      type: Object,
      default: () => { return {}}
    },
  },
  data () {
    return {
      target: {}
    }
  },
  watch: {
    value: {
      handler: function(val) {
        this.target = val
      },
      immediate: true
    }
  }
}
</script>