import { formFetch, jsonFetch } from '@/utils/fetch'
import Qs from 'qs'

export function list (query) {
  return jsonFetch.post('/domain/list', query)
}

export function switchStatus (id, available) {
  return formFetch.post(`/domain/${id}/status`, Qs.stringify({ available }))
}

export function detail (id) {
  return formFetch.post(`/domain/${id}/detail`)
}

export function create (domain) {
  return jsonFetch.post(`/domain/create`, domain)
}

export function modify (domain) {
  return jsonFetch.post(`/domain/${domain.id}/modify`, domain)
}

export function del (id) {
  return formFetch.post(`/domain/${id}/delete`)
}
