import { formFetch, jsonFetch } from '@/utils/fetch'
import Qs from 'qs'

export function list (activityId) {
  return formFetch.post('/turntable/list', Qs.stringify({ activityId }))
}

export function switchStatus (id, available) {
  return formFetch.post(`/turntable/${id}/status`, Qs.stringify({ available }))
}

export function detail (id) {
  return formFetch.post(`/turntable/${id}/detail`)
}

export function create (turntable) {
  return jsonFetch.post(`/turntable/create`, turntable)
}

export function modify (turntable) {
  return jsonFetch.post(`/turntable/${turntable.id}/modify`, turntable)
}

export function del (id) {
  return formFetch.post(`/turntable/${id}/delete`)
}

export function sort (ids) {
  return jsonFetch.post(`/turntable/sort`, ids)
}