<template>
  <div v-loading="loading" class="param">
    <base-header class="pb-6">
      <b-row align-v="center" class="py-4">
        <b-col cols="7" lg="6">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-breadcrumb></route-breadcrumb>
          </nav>
        </b-col>
        <b-col lg="6" cols="5" class="text-right">
        </b-col>
      </b-row>
    </base-header>
    <b-container fluid class="mt--6">
      <b-card no-body>
        <!-- Card header -->
        <b-card-header>
          <div>
            <div class="float-left"><h3 class="mb-0">返水返佣配置</h3></div>
            <div class="float-right">
              <base-button type="secondary" size="sm" icon class="btn-icon" @click="getData">
                <span><i class="fa fa-refresh"></i></span>
                <span>刷新</span>
              </base-button>
              <base-button type="primary" size="sm" icon @click.native="save">
                <i class="fa fa-check"></i>保存
              </base-button>
            </div>
          </div>
        </b-card-header>
        <!-- Card body -->
        <b-card-body>
          <el-form :model="model" ref="form" label-position="left">
            <h4>返水</h4>
            <el-row :gutter="10">
              <el-col :span="10">
                <el-form-item prop="water_mode" label="返水时间" required>
                  <el-radio-group v-model="model.water_mode" style="margin-top:8px">
                    <el-radio label="manual">手动通过</el-radio>
                    <el-radio label="realTime">订单结算后实时返</el-radio>
                    <el-radio label="auto">定时自动反</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item prop="water_time" label="" :required="model.water_mode=='auto'">
                  <el-time-select
                    v-show="model.water_mode=='auto'" 
                    v-model="model.water_time"
                    :picker-options="{
                      start: '00:00',
                      end: '23:59',
                      step: '00:01'
                    }"
                    class="margin-left-sm" 
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="10">
              <el-col :span="8">
                <el-form-item prop="water_target" label="返水方式" required>
                  <el-radio-group v-model="model.water_target" style="margin-top:8px">
                    <el-radio label="welfare">红包</el-radio>
                    <el-radio label="balance">余额</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-row>
            <h4>返佣</h4>
            <el-row :gutter="10">
              <el-col :span="10">
                <el-form-item prop="commission_mode" label="返佣时间" required>
                  <el-radio-group v-model="model.commission_mode" style="margin-top:8px">
                    <el-radio label="manual">手动通过</el-radio>
                    <el-radio label="realTime">订单结算后实时返</el-radio>
                    <el-radio label="auto">定时自动反</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item prop="commission_time" label="" :required="model.commission_mode=='auto'">
                  <el-time-select
                    v-show="model.commission_mode=='auto'" 
                    v-model="model.commission_time"
                    :picker-options="{
                      start: '00:00',
                      end: '23:59',
                      step: '00:01'
                    }"
                    class="margin-left-sm" 
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="10">
              <el-col :span="8">
                <el-form-item prop="commission_target" label="返佣方式" required>
                  <el-radio-group v-model="model.commission_target" style="margin-top:8px">
                    <el-radio label="welfare">红包</el-radio>
                    <el-radio label="balance">余额</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </b-card-body>
      </b-card>
    </b-container>
  </div>
</template>

<script>
import { get, modify } from '@/api/config/param'
export default {
  data () {
    return {
      loading: false,
      model: {
        water_mode: '',
        water_time: '',
        water_target: '',
        commission_mode: '',
        commission_time: '',
        commission_target: ''
      }
    }
  },
  created () {
    this.getData()
  },
  methods: {
    getData () {
      this.loading = true
      get('water_mode', 'water_time', 'water_target', 'commission_mode', 'commission_time', 'commission_target').then(res => {
        this.loading = false
        if (res.success) {
          this.model = res.data
        }
      })
    },
    save () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          modify(this.model).then(res => {
            if (res.success) {
              this.$bvToast.toast('操作成功', {
                title: '提示',
                solid: true,
                variant: 'success',
                autoHideDelay: 3000,
                toaster: 'b-toaster-top-center'
              })
            }
          })
        }
      })
    }
  }
}
</script>
<style lang="scss">
.param {
  .el-upload-list--picture-card .el-upload-list__item {
    height: 100%;
  }
  .el-upload-list--picture-card .el-upload-list__item-thumbnail {
  width: 148px;
  height: 100%;
}
}
</style>