<template>
  <el-dialog
    v-modal-drag
    width="50%"
    :visible.sync="visible"
    :title="action==='add'?'新增游戏':'编辑游戏'"
    @close="close">
    <el-form :model="model" ref="form" label-position="top">
      <el-tabs v-model="activeName" type="border-card">
        <el-tab-pane label="基础配置" name="basic">
          <el-row :gutter="20" v-if="show_third_part_game">
            <el-col :span="8">
              <el-form-item prop="thirdPart" label="合作方">
                <el-select v-model="model.thirdPart" @change="handleThirdPartChange">
                  <el-option value="OWN" label="自有"></el-option>
                  <el-option value="NG" label="NG"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20" v-if="model.thirdPart=='NG'">
            <el-col :span="8">
              <el-form-item prop="platType" label="平台类型" required>
                <el-select v-model="model.platType" @change="handlePlatTypeChange">
                  <el-option v-for="item in platTypeList" :key="item.id" :value="item.code" :label="item.code+' - '+item.name">
                    <div class="flex align-center justify-between">
                      <span>{{ item.code }}</span>
                      <span>{{ item.name }}</span>
                    </div>
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item prop="type" label="游戏类型" required>
                <el-select v-model="model.type" clearable>
                  <el-option v-for="item in gameTypes" :value="item.value" :label="item.label"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="8" v-if="model.thirdPart=='OWN'">
              <el-form-item prop="type" label="游戏类型" required>
                <el-select v-model="model.type">
                  <el-option v-for="item in gameTypes" :value="item.value" :label="item.label"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item prop="code" label="游戏代码" required>
                <!-- <el-input v-model="model.code" v-if="model.thirdPart=='NG'" readOnly>
                  <el-button slot="append" icon="el-icon-search" @click="showNgGame"></el-button>
                </el-input> -->
                <el-select v-model="model.code" v-if="model.thirdPart=='NG'">
                  <el-option v-for="item in gameList" :value="item.gameCode">
                    <span style="float: left">{{ item.gameName['zh-hans'] }}</span>
                    <span style="float: right; color: #8492a6; font-size: 13px">{{ item.gameCode }}</span>
                  </el-option>
                </el-select>
                <el-input v-model="model.code" v-else>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item prop="name" label="游戏名称" required>
                <el-input v-model="model.name"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20" v-show="model.thirdPart=='OWN'">
            <el-col :span="8">
              <el-form-item prop="self" label="是否自开" required>
                <base-switch 
                  v-model="model.self" on-text="自开" off-text="非自开" type="success" size="sm">
                </base-switch>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="开奖周期（秒）" required>
                <el-input v-model="model.drawCycle" type="number"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="封盘时间（秒）" required>
                <el-input v-model="model.closeTime" type="number"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20" v-show="model.thirdPart=='OWN'">
            <el-col :span="8">
              <el-form-item label="单笔最小投注额" required>
                <el-input v-model="model.minBetOrder" type="number"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="单笔最大投注额" required>
                <el-input v-model="model.maxBetOrder" type="number"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="当期最大投注总额" required>
                <el-input v-model="model.maxBetIssue" type="number"></el-input>
              </el-form-item>
            </el-col>
            <!-- <el-col :span="8">
              <el-form-item label="单期最小投注" required>
                <el-input v-model="model.minBetIssue" type="number"></el-input>
              </el-form-item>
            </el-col> -->
          </el-row>
          <el-row :gutter="20" v-show="model.thirdPart=='OWN'">
            <el-col :span="8">
              <el-form-item label="机器人数量" required>
                <el-input v-model="model.robotNum" type="number"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="16">
              <el-form-item label="开奖视频网址" required>
                <el-input v-model="model.videoUrl"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="返佣比例(千分之几)" required>
                <el-input v-model="model.commissionRate" type="number"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="返水比例(千分之几)" required>
                <el-input v-model="model.returnRate" type="number"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="LOGO图">
                <base-upload
                  :img-name="model.logoImg"
                  :img-size="100" 
                  @success="handleLogoUploadSuccess">
                </base-upload>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="封面图，建议长宽比1.23:1，如130x105">
                <base-upload
                  :img-name="model.bgImg"
                  :img-size="100" 
                  @success="handleBgUploadSuccess"
                  class="bg-img">
                </base-upload>
              </el-form-item>
            </el-col>
          </el-row>
        </el-tab-pane>
        <el-tab-pane label="玩法说明" name="guide">
          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item prop="guide" label="玩法说明">
                <html-editor v-model="model.guide"></html-editor>
              </el-form-item>
            </el-col>
          </el-row>
        </el-tab-pane>
      </el-tabs>
    </el-form>
    <div slot="footer">
      <base-button size="sm" type="primary" @click="submit">保存</base-button>
      <base-button size="sm" type="link" class="ml-auto" @click="close">取消</base-button>
    </div>
    <el-dialog
      v-modal-drag
      :visible.sync="previewVisible"
      width="50%">
      <img width="100%" :src="previewImageUrl" alt="">
    </el-dialog>
    <el-dialog
      v-modal-drag
      :visible.sync="ngGameVisible"
      title="选择NG游戏"
      width="50%">
      <ng-game :platType="model.platType" @select="handleSelectNgGame"></ng-game>
    </el-dialog>
  </el-dialog>
</template>
<script>
import { get } from '@/api/config/param'
import { detail, create, modify, listNgPlatType, listNgGame } from '@/api/config/game'
import BaseUpload from '@/components/BaseUpload'
import HtmlEditor from '@/components/Inputs/HtmlEditor'
import NgGame from './ng-game'
export default {
  name: 'LotteryForm',
  props: ['visible', 'action', 'id', 'thirdPart'],
  components: {
    BaseUpload,
    HtmlEditor,
    NgGame
  },
  data () {
    return {
      activeName: 'basic',
      platTypeList: [], // ng游戏平台
      gameCodeList: [], // ng游戏代码
      ngGameVisible: false,
      show_third_part_game: false,
      model: {
        id: '',
        code: '',
        name: '',
        thirdPart: '',
        platType: '',
        self: false,
        drawCycle: 0,
        type: '',
        ingress: '',
        logoImg: '',
        bgImg: '',
        displayOrder: 0,
        guide: '',
        commissionRate: 0,
        robotNum: 0,
        closeTime: 0,
        returnRate: 0,
        maxBetOrder: 0,
        minBetOrder: 0,
        maxBetIssue: 0,
        minBetIssue: 0,
        videoUrl: ''
      }
    }
  },
  watch: {
    'thirdPart': {
      handler: function (val) {
        this.model.thirdPart = val
      },
      immediate: true
    }
  },
  computed: {
    gameTypes () {
      if (this.model.thirdPart=='OWN') {
        return [
          {value: 'PK', label:'PK'},
          {value: 'SS', label:'SS'},
          {value: 'PC20', label:'PC20'},
          {value: 'PC28', label:'PC28'},
          {value: 'PC32', label:'PC32'}
        ]
      } else {
        // 筛选平台支持的游戏类型
        let allTypes = [
          {value: '1', label:'视讯'},
          {value: '2', label:'老虎机'},
          {value: '3', label:'彩票'},
          {value: '4', label:'体育'},
          {value: '5', label:'电竞'},
          {value: '6', label:'捕猎'},
          {value: '7', label:'棋牌'}
        ]
        let supportGameTypes = []
        for (let i=0;i<this.platTypeList.length;i++) {
          let platTypeObj = this.platTypeList[i]
          if (platTypeObj.code == this.model.platType) {
            supportGameTypes = platTypeObj.gameTypes.split(',')
          }
        }
        return allTypes.filter(item => {
          return supportGameTypes.indexOf(item.value)>-1
        })
      }
    },
    gameList () {
      const that = this
      let list = []
      if (!this.model.type) {
        console.log(111)
        list = this.gameCodeList
      } else {
        console.log(222)
        list = this.gameCodeList.filter(item => {
          return item.gameType == that.model.type
        })
      }
      console.log('model.type', that.model.type)
      console.log('list', list)
      // 有的平台下某些游戏类型只提供游戏大厅，没有具体游戏代码，此时写死游戏代码为platType-type，获取游戏地址时不传游戏代码
      if (list.length == 0) {
        list = [{
          gameCode: this.model.platType + '-' + this.model.type,
          gameName: {
            'zh-hans': '自定义'
          }
        }]
      }
      console.log('final list', list)
      return list
    }
  },
  created () {
    get('show_third_part_game').then(res => {
      if (res.success) {
        this.show_third_part_game = res.data.show_third_part_game=='true'
      }
    })
    listNgPlatType().then(res => {
      if (res.success) {
        this.platTypeList = res.data
      }
    })
    if (this.action==='modify') {
      detail(this.id).then(res => {
        if (res.success) {
          this.model = res.data
        }
      })
    }
  },
  methods: {
    handlePlatTypeChange () {
      this.model.type = ''
      this.model.code = ''
      this.listNgGame()
    },
    listNgGame () {
      this.loading = true
      listNgGame(this.model.platType).then(res => {
        this.loading = false
        if (res.success) {
          this.gameCodeList = res.data
        }
      })
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.action === 'add') {
            create(this.model).then(res => {
              if (res.success) {
                this.close()
                this.$emit('submit')
              }
            })
          } else {
            modify(this.model).then(res => {
              if (res.success) {
                this.close()
                this.$emit('submit')
              }
            })
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    close () {
      this.$emit('update:visible', false)
    },
    handleLogoUploadSuccess (fileName) {
      this.model.logoImg = fileName
    },
    handleBgUploadSuccess (fileName) {
      this.model.bgImg = fileName
    },
    showNgGame() {
      this.ngGameVisible = true
    },
    handleSelectNgGame (game) {
      this.model.platType = game.platType
      this.model.type = game.gameType
      this.model.code = game.gameCode
      this.model.name = game.gameName['zh-hans']
      this.model.ingress = game.ingress
      this.ngGameVisible = false
    },
    handleThirdPartChange () {
      this.model.platType = ''
      this.model.type = ''
      this.model.code = ''
      this.model.name = ''
      this.model.ingress = ''
    }
  }
}
</script>
<style lang="scss">
.hide-upload-btn .el-upload--picture-card {
  display: none;
}
.bg-img {
  .el-upload-list--picture-card .el-upload-list__item,
  .el-upload-list--picture-card .el-upload-list__item-thumbnail {
    width: 195px;
    height: 148px;
  }
}
</style>