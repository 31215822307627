<template>    
  <div class="content">
    <base-header class="pb-6">
      <b-row align-v="center" class="py-4">
        <b-col cols="7" lg="6">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-breadcrumb></route-breadcrumb>
          </nav>
        </b-col>
        <b-col lg="6" cols="5" class="text-right">
        </b-col>
      </b-row>
    </base-header>
    <b-container fluid class="mt--6">
      <div>
        <card class="no-border-card" body-classes="px-0 pb-1" footer-classes="pb-2">
          <div>
            <el-form inline class="pl-3">
              <div class="float-right">
                <el-form-item>
                  <base-button
                    @click.native="showModal(0, 'form', 'add')"
                    type="primary"
                    size="sm"
                    icon
                  >
                    <i class="fa fa-plus"></i>新增
                  </base-button>
                </el-form-item>
              </div>
            </el-form>
            <el-table :data="tableData"
                      size="small"
                      row-key="id"
                      border
                      v-loading="loading"
                      header-row-class-name="thead-light">
              <el-table-column prop="id" label="ID" width="100" align="center">
              </el-table-column>
              <el-table-column prop="domain" label="域名" width="200" align="center"></el-table-column>
              <el-table-column prop="type" label="类型" width="100" align="center">
                <template v-slot="{row}">
                  {{ row.type | domainType }}
                </template>
              </el-table-column>
              <el-table-column prop="status" label="状态" width="100" align="center">
                <div slot-scope="{$index, row}" class="flex justify-center">
                  <base-switch 
                      v-model="row.available" on-text="启用" off-text="禁用" 
                      type="success" size="sm" @change="handleSwitchStatus(row)">
                    </base-switch>
                </div>
              </el-table-column>
              <el-table-column prop="createTime" label="创建时间" width="150" align="center"></el-table-column>
              <el-table-column prop="updateTime" label="更新时间" width="150" align="center"></el-table-column>
              <el-table-column width="170px" align="center" label="操作">
                <div slot-scope="{$index, row}" class="flex justify-center">
                  <base-button
                    @click.native="showModal(row.id, 'form', 'modify')"
                    type="info"
                    size="sm"
                    outline
                  >
                    <i class="fa fa-edit"></i>编辑
                  </base-button>
                  <base-button
                    @click.native="handleDelete(row.id)"
                    type="danger"
                    size="sm"
                    outline
                  >
                    <i class="fa fa-trash"></i>删除
                  </base-button>
                </div>
              </el-table-column>
            </el-table>
          </div>
          <div
            slot="footer"
          >
          </div>
        </card>
      </div>
    </b-container>
    <!--Modals-->
    <edit-form v-if="modal.form" :visible.sync="modal.form" :id="id" :action="formAction" @submit="handleEditDone"></edit-form>
  </div>
</template>
<script>
import { list, switchStatus, del } from '@/api/config/domain'
import EditForm from './form'
export default {
  components: {
    EditForm
  },
  data() {
    return {
      loading: false,
      query: {
        filter: {
          type: 'landing'
        },
      },
      totalCount: 0,
      tableData: [],
      selectedRows: [],
      formAction: '',
      id: '',
      modal: {
        form: false
      }
    };
  },
  created () {
    this.getData()
  },
  filters: {
    domainType (value) {
      if (value=='entry') {
        return '入口域名'
      }
      if (value=='auth') {
        return '鉴权域名'
      }
      if (value=='landing') {
        return '落地域名'
      }
      return value
    }
  },
  methods: {
    getData(clear) {
      this.loading = true
      if (clear) {
        this.tableData = []
      }
      list(this.query).then(res => {
        this.loading = false
        if (res.success) {
          this.totalCount = res.totalCount
          this.tableData = res.data
        }
      })
    },
    handleSwitchStatus(row) {
      switchStatus(row.id, row.available).then(res => {
        if (res.success) {
          this.getData()
        }
      })
    },
    showModal(id, type, action) {
      this.id = id
      this.formAction = action
      this.modal[type] = true
    },
    handleEditDone () {
      this.modal.form = false
      this.getData(true)
    },
    handleDelete(id) {
      this.$bvModal.msgBoxConfirm('确定删除?', {
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: '确定',
        cancelTitle: '取消',
        centered: true
      })
        .then(value => {
          if (value) {
            del(id).then(res => {
              if (res.success) {
                this.getData()
                this.$bvToast.toast('操作成功', {
                  title: '提示',
                  solid: true,
                  variant: 'success',
                  autoHideDelay: 3000,
                  toaster: 'b-toaster-top-center'
                })
              }
            })
          }
        })
    },
  }
};
</script>
<style>
  .no-border-card .card-footer{
    border-top: 0;
  }
</style>
