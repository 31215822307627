<template>
  <div class="flex justify-between padding-lr-xs padding-tb-xs bg-white" style="width:100%;">
    <div class="text-lg flex align-center">
      <span :class="icon?icon+' margin-right-xs':'cuIcon-titles'" style="color:#5e72e4"></span>
      <span >{{title}}</span>
      <slot name="tip"></slot>
    </div>
    <div class="flex align-center">
      <slot name="center"></slot>
    </div>
    <div class="flex align-center right">
      <slot name="right"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TitleBar',
  props: ['title', 'icon']
}
</script>
<style lang="scss" scoped>
.right {
  .el-button--text {
    margin-left: 5px;
    color: #606266;
  }
}
</style>