import { formFetch, jsonFetch } from '@/utils/fetch'
import Qs from 'qs'

export function list (query) {
  return jsonFetch.post('/game/list', query)
}

export function switchStatus (id, available) {
  return formFetch.post(`/game/${id}/status`, Qs.stringify({ available }))
}

export function detail (id) {
  return formFetch.post(`/game/${id}/detail`)
}

export function create (game) {
  return jsonFetch.post(`/game/create`, game)
}

export function modify (game) {
  return jsonFetch.post(`/game/${game.code}/modify`, game)
}

export function del (id) {
  return formFetch.post(`/game/${id}/delete`)
}

export function sort (ids) {
  return jsonFetch.post(`/game/sort`, ids)
}

export function listNgPlatType () {
  return formFetch.post('/game/listNgPlatType')
}

export function listNgGame (platType) {
  return formFetch.post('/game/listNgGame', Qs.stringify({ platType }))
}