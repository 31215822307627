import { formFetch, jsonFetch } from '@/utils/fetch'
import Qs from 'qs'

export function list (query) {
  return jsonFetch.post('/quick-reply/list', query)
}

export function detail (id) {
  return formFetch.post(`/quick-reply/${id}/detail`)
}

export function create (quickReply) {
  return jsonFetch.post(`/quick-reply/create`, quickReply)
}

export function modify (quickReply) {
  return jsonFetch.post(`/quick-reply/${quickReply.id}/modify`, quickReply)
}

export function del (id) {
  return formFetch.post(`/quick-reply/${id}/delete`)
}
