<template>
  <el-dialog
    v-modal-drag
    width="70%"
    :visible.sync="visible"
    :title="'帐变记录-'+uid"
    @close="close">
    <div class="content">
      <el-form inline class="flex align-items-center">
        <el-form-item label="帐变类型">
          <el-select v-model="query.filter.tradeType" placeholder="" size="small" style="width:110px" @change="getData">
            <el-option value="" label="全部"></el-option>
            <el-option value="WIN" label="中奖"></el-option>
            <el-option value="ORDER" label="投注"></el-option>
            <el-option value="MANUAL" label="手动改分"></el-option>
            <el-option value="UP" label="上分"></el-option>
            <el-option value="DOWN" label="下分"></el-option>
            <el-option value="RETURN_WATER" label="返水"></el-option>
            <el-option value="COMMISSION" label="返佣"></el-option>
            <el-option value="BONUS" label="活动奖励"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="日期">
          <date-range v-model="daterange" @change="handleDateRangeChange"></date-range>
        </el-form-item>
        <el-form-item>
          <base-button type="primary" size="sm" icon class="btn-icon" @click="getData">
            <span><i class="fa fa-search"></i></span>
            <span>搜索</span>
          </base-button>
        </el-form-item>
      </el-form>
      <el-table :data="tableData"
                ref="table"
                size="mini"
                row-key="id"
                v-loading="loading"
                border
                element-loading-text="加载中..."
                header-row-class-name="thead-light">
        <el-table-column prop="tradeType" label="变动项目" align="center" sortable>
          <div slot-scope="{$index, row}">
            {{ row.tradeType | tradeType}}
          </div>
        </el-table-column>
        <el-table-column prop="amount" label="金额" width="100"  align="right" header-align="center" sortable>
          <template slot-scope="scope">
            <span :class="scope.row.amount>=0?'text-green':'text-red'">{{scope.row.amount}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="before" label="变动前" width="100" align="right" header-align="center">
          <template slot-scope="scope">
            <span class="text-orange">{{scope.row.before}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="after" label="变动后" width="100" align="right" header-align="center">
          <template slot-scope="scope">
            <span class="text-blue">{{scope.row.after}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="notes" label="备注" align="center">
        </el-table-column>
        <el-table-column prop="createTime" label="记录时间" width="180" align="center" sortable>
        </el-table-column>
      </el-table>
      <base-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :query="query"
        :total="totalCount">
      </base-pagination>
    </div>
    <div slot="footer">
    </div>
  </el-dialog>
</template>

<script>
import DateRange from '@/components/DateRange'
import { balanceHis } from '@/api/member'
import dayjs from 'dayjs'
export default {
  props: ['visible','uid'],
  components: {
    DateRange
  },
  data() {
    return {
      loading: false,
      quickDate: 'today',
      daterange: ['', ''],
      query: {
        filter: {
          uid: this.uid,
          tradeType: '',
          startDate: dayjs().format('YYYY-MM-DD'),
          endDate: dayjs().format('YYYY-MM-DD')
        },
        start: 0,
        limit: 10,
        sort: null
      },
      totalCount: 0,
      tableData: [],
      selectedRows: []
    };
  },
  methods: {
    getData () {
      this.loading = true
      balanceHis(this.query).then(res => {
        this.loading = false
        if (res.success) {
          this.totalCount = res.totalCount
          this.tableData = res.data
        }
      })
    },
    handleSizeChange () {
      this.getData()
    },
    handleCurrentChange () {
      this.getData()
    },
    close () {
      this.$emit('update:visible', false)
    },
    handleDateRangeChange (val) {
      this.daterange = val.daterange
      this.query.filter.startDate = val.daterange[0]
      this.query.filter.endDate = val.daterange[1]
      this.getData()
    }
  }
};
</script>