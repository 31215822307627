<template>
  <div class="pk">
    <base-button
      class="save-btn"
      @click.native="save"
      type="primary"
      size="sm"
      icon
    >
      <i class="fa fa-check"></i>保存
    </base-button>
    <el-tabs v-model="activeName" type="border-card">
      <el-tab-pane label="1-10车道" name="lane">
        <el-table :data="conf.lane"
            v-loading="loading"
            size="mini"
            border
            fit
            header-row-class-name="thead-light">
          <el-table-column
            prop="option" label="车道" width="50" align="center">
          </el-table-column>
          <el-table-column
            v-for="typeColumn in columnsLane"
            :key="typeColumn.type"
            :label-class-name="typeColumn.className"
            align="center">
            <div slot="header" class="padding-sm">
              <b-button
                :id="'edit-btn-'+typeColumn.type"
                variant="outline-primary"
                size="sm"
                icon
              >
                <i class="fa fa-edit"></i>批量修改
              </b-button>
            </div>
            <el-table-column
              v-for="column in typeColumn.columns"
              :key="column.prop"
              width="65"
              :prop="column.prop"
              :label="column.label"
              :label-class-name="column.className"
              align="center">
              <template v-slot="{row}">
                <el-input v-if="column.prop in row" size="mini" maxlength="5" v-model="row[column.prop]"></el-input>
              </template>
            </el-table-column>
          </el-table-column>
        </el-table>
      </el-tab-pane>
      <el-tab-pane label="冠亚和" name="fs">
        <div class="text-center padding">
          <b-button
            id="edit-btn-other"
            variant="outline-primary"
            size="sm"
            icon
          >
            <i class="fa fa-edit"></i>批量修改
          </b-button>
        </div>
        <el-form label-position="right" label-width="50px">
          <el-row>
            <el-col :span="4">
              <el-form-item label="大"><el-input size="mini" v-model="conf.fs['big']"></el-input></el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item label="小"><el-input size="mini" v-model="conf.fs['small']"></el-input></el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item label="单"><el-input size="mini" v-model="conf.fs['single']"></el-input></el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item label="双"><el-input size="mini" v-model="conf.fs['double']"></el-input></el-form-item>
            </el-col>  
          </el-row>
          <el-row>
            <el-col :span="4">
              <el-form-item label="3"><el-input size="mini" v-model="conf.fs['3']"></el-input></el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item label="4"><el-input size="mini" v-model="conf.fs['4']"></el-input></el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item label="5"><el-input size="mini" v-model="conf.fs['5']"></el-input></el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item label="6"><el-input size="mini" v-model="conf.fs['6']"></el-input></el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item label="7"><el-input size="mini" v-model="conf.fs['7']"></el-input></el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item label="8"><el-input size="mini" v-model="conf.fs['8']"></el-input></el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="4">
              <el-form-item label="9"><el-input size="mini" v-model="conf.fs['9']"></el-input></el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item label="10"><el-input size="mini" v-model="conf.fs['10']"></el-input></el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item label="11"><el-input size="mini" v-model="conf.fs['11']"></el-input></el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item label="12"><el-input size="mini" v-model="conf.fs['12']"></el-input></el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item label="13"><el-input size="mini" v-model="conf.fs['13']"></el-input></el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item label="14"><el-input size="mini" v-model="conf.fs['14']"></el-input></el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="4">
              <el-form-item label="15"><el-input size="mini" v-model="conf.fs['15']"></el-input></el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item label="16"><el-input size="mini" v-model="conf.fs['16']"></el-input></el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item label="17"><el-input size="mini" v-model="conf.fs['17']"></el-input></el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item label="18"><el-input size="mini" v-model="conf.fs['18']"></el-input></el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item label="19"><el-input size="mini" v-model="conf.fs['19']"></el-input></el-form-item>
            </el-col>
            <el-col :span="4"> 
            </el-col>
          </el-row>
        </el-form>
      </el-tab-pane>
    </el-tabs>
    <b-popover target="edit-btn-num" triggers="click blur">
      <template #title>
        <b-button @click="hideBatchEdit" class="close" aria-label="Close">
          <span></span>
        </b-button>
        批量修改
      </template>
      <b-input-group>
        <b-form-input v-model="globalVal"></b-form-input>
        <b-input-group-append>
          <b-button size="sm" variant="outline-primary" @click="submitBatchEdit('num')">确定</b-button>
        </b-input-group-append>
      </b-input-group>
    </b-popover>
    <b-popover target="edit-btn-sm" triggers="click blur">
      <template #title>
        <b-button @click="hideBatchEdit" class="close" aria-label="Close">
          <span></span>
        </b-button>
        批量修改
      </template>
      <b-input-group>
        <b-form-input v-model="globalVal"></b-form-input>
        <b-input-group-append>
          <b-button size="sm" variant="outline-primary" @click="submitBatchEdit('sm')">确定</b-button>
        </b-input-group-append>
      </b-input-group>
    </b-popover>
    <b-popover target="edit-btn-ts" triggers="click blur">
      <template #title>
        <b-button @click="hideBatchEdit" class="close" aria-label="Close">
          <span></span>
        </b-button>
        批量修改
      </template>
      <b-input-group>
        <b-form-input v-model="globalVal"></b-form-input>
        <b-input-group-append>
          <b-button size="sm" variant="outline-primary" @click="submitBatchEdit('ts')">确定</b-button>
        </b-input-group-append>
      </b-input-group>
    </b-popover>
    <b-popover target="edit-btn-other" triggers="click blur">
      <template #title>
        <b-button @click="hideBatchEdit" class="close" aria-label="Close">
          <span></span>
        </b-button>
        批量修改
      </template>
      <b-input-group>
        <b-form-input v-model="globalVal"></b-form-input>
        <b-input-group-append>
          <b-button size="sm" variant="outline-primary" @click="submitBatchEdit('other')">确定</b-button>
        </b-input-group-append>
      </b-input-group>
    </b-popover>
  </div>
</template>

<script>
const initOdds = {
  lane: [
    {'option': '1', '1': 0, '2': 0, '3': 0, '4': 0, '5': 0, '6': 0, '7': 0, '8': 0, '9': 0, '10': 0, 'big': 0, 'small': 0, 'single': 0, 'double': 0, 'dragon': 0, 'tiger': 0},
    {'option': '2', '1': 0, '2': 0, '3': 0, '4': 0, '5': 0, '6': 0, '7': 0, '8': 0, '9': 0, '10': 0, 'big': 0, 'small': 0, 'single': 0, 'double': 0, 'dragon': 0, 'tiger': 0},
    {'option': '3', '1': 0, '2': 0, '3': 0, '4': 0, '5': 0, '6': 0, '7': 0, '8': 0, '9': 0, '10': 0, 'big': 0, 'small': 0, 'single': 0, 'double': 0, 'dragon': 0, 'tiger': 0},
    {'option': '4', '1': 0, '2': 0, '3': 0, '4': 0, '5': 0, '6': 0, '7': 0, '8': 0, '9': 0, '10': 0, 'big': 0, 'small': 0, 'single': 0, 'double': 0, 'dragon': 0, 'tiger': 0},
    {'option': '5', '1': 0, '2': 0, '3': 0, '4': 0, '5': 0, '6': 0, '7': 0, '8': 0, '9': 0, '10': 0, 'big': 0, 'small': 0, 'single': 0, 'double': 0, 'dragon': 0, 'tiger': 0},
    {'option': '6', '1': 0, '2': 0, '3': 0, '4': 0, '5': 0, '6': 0, '7': 0, '8': 0, '9': 0, '10': 0, 'big': 0, 'small': 0, 'single': 0, 'double': 0},
    {'option': '7', '1': 0, '2': 0, '3': 0, '4': 0, '5': 0, '6': 0, '7': 0, '8': 0, '9': 0, '10': 0, 'big': 0, 'small': 0, 'single': 0, 'double': 0},
    {'option': '8', '1': 0, '2': 0, '3': 0, '4': 0, '5': 0, '6': 0, '7': 0, '8': 0, '9': 0, '10': 0, 'big': 0, 'small': 0, 'single': 0, 'double': 0},
    {'option': '9', '1': 0, '2': 0, '3': 0, '4': 0, '5': 0, '6': 0, '7': 0, '8': 0, '9': 0, '10': 0, 'big': 0, 'small': 0, 'single': 0, 'double': 0},
    {'option': '10', '1': 0, '2': 0, '3': 0, '4': 0, '5': 0, '6': 0, '7': 0, '8': 0, '9': 0, '10': 0, 'big': 0, 'small': 0, 'single': 0, 'double': 0},
  ],
  fs: {'option': 'fs', 'big':0, 'small':0, 'single':0, 'double':0, '3':0, '4':0, '5':0, '6':0, '7':0, '8':0, '9':0, '10':0, 
    '11':0, '12':0, '13':0, '14':0, '15':0, '16':0, '17':0, '18':0, '19':0
  },
}
import TitleBar from '@/components/TitleBar'
import { list, save } from '@/api/config/odds'
export default {
  props: ['gameCode'],
  components: {
    TitleBar
  },
  data () {
    return {
      loading: false,
      columnsLane: [  //1-10车道
        {
          type: 'num',
          className: 'bg-num',
          columns: [
            {label:'1', prop:'1', className: 'bg-num'},
            {label:'2', prop:'2', className: 'bg-num'},
            {label:'3', prop:'3', className: 'bg-num'},
            {label:'4', prop:'4', className: 'bg-num'},
            {label:'5', prop:'5', className: 'bg-num'},
            {label:'6', prop:'6', className: 'bg-num'},
            {label:'7', prop:'7', className: 'bg-num'},
            {label:'8', prop:'8', className: 'bg-num'},
            {label:'9', prop:'9', className: 'bg-num'},
            {label:'10', prop:'10', className: 'bg-num'},
          ]
        },
        {
          type: 'sm',
          className: 'bg-sm',
          columns: [
            {label:'大', prop:'big', className: 'bg-sm'},
            {label:'小', prop:'small', className: 'bg-sm'},
            {label:'单', prop:'single', className: 'bg-sm'},
            {label:'双', prop:'double', className: 'bg-sm'},
          ]
        },
        {
          type: 'ts',
          className: 'bg-ts',
          columns: [
            {label:'龙', prop:'dragon', className: 'bg-ts'},
            {label:'虎', prop:'tiger', className: 'bg-ts'}
          ]
        }
      ],
      conf: initOdds,
      activeName: 'lane',
      globalVal: 0
    }
  },
  computed: {
    config() {
      return this.conf.lane.concat(this.conf.fs)
    }
  },
  watch: {
    'gameCode': {
      handler (val) {
        this.loading = true
        this.conf = initOdds
        list(val).then(res => {
          this.loading = false
          if (res.success) {
            this.conf = res.data
          }
        })
      },
      immediate: true
    }
  },
  methods: {
    save () {
      save(this.gameCode, this.config).then(res => {
        if (res.success) {
          this.$bvToast.toast('操作成功', {
            title: '提示',
            solid: true,
            variant: 'success',
            autoHideDelay: 3000,
            toaster: 'b-toaster-top-center'
          })
        }
      })
    },
    hideBatchEdit () {
      this.$root.$emit('bv::hide::popover')
    },
    submitBatchEdit (type) {
      if (type=='num') {
        this.conf.lane.forEach(row => {
          for (let key in row) {
            if (key=='1' || key=='2' || key=='3' || key=='4' || key=='5' || key=='6' || key=='7' || key=='8' || key=='9' || key=='10') {
              row[key] = this.globalVal
            }
          }
        })
      } else if (type=='sm') {
        this.conf.lane.forEach(row => {
          for (let key in row) {
            if (key=='big' || key=='small' || key=='single' || key=='double') {
              row[key] = this.globalVal
            }
          }
        })
      } else if (type=='ts') {
        this.conf.lane.forEach(row => {
          for (let key in row) {
            if (key=='dragon' || key=='tiger') {
              row[key] = this.globalVal
            }
          }
        })
      } else if (type=='other') {
        for (let key in this.conf.fs) {
          if (key!='option') {
            this.conf.fs[key] = this.globalVal
          }
        }
      }
      this.hideBatchEdit()
    }
  }
}
</script>

<style lang="scss">
.pk {
  .el-input {
    width: 65px;
  }
  .save-btn {
    position: absolute;
    z-index: 100;
    top: 95px;
    right: 16px
  }
  .edit-btn {
    position: absolute;
    z-index: 100;
    top: 95px;
    right: 100px;
  }
  .el-table__cell {
    padding: 0;
  }
  .bg-num {
    background-color: rgb(250, 236, 216);
  }
  .bg-sm {
    background-color: rgb(225, 243, 216);
  }
  .bg-ts {
    background-color: rgb(253, 226, 226);
  }
}
</style>