<template>
  <div class="content">
    <base-header class="pb-6">
      <b-row align-v="center" class="py-4">
        <b-col cols="7" lg="6">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-breadcrumb></route-breadcrumb>
          </nav>
        </b-col>
        <b-col lg="6" cols="5" class="text-right">
        </b-col>
      </b-row>
    </base-header>
    <b-container fluid class="mt--6">
      <div>
        <card class="no-border-card" body-classes="px-0 pb-1" footer-classes="pb-2">
          <div class="padding">
            <div class="flex">
              <el-input v-model="query.filter.uid" clearable style="width:200px">
                <template slot="prepend">UID</template>
              </el-input>
              <el-input v-model="query.filter.parent" clearable style="width:200px;margin-left:20px">
                <template slot="prepend">上级ID</template>
              </el-input>
              <el-input v-model="query.filter.nickName" clearable style="width:200px;margin-left:20px">
                <template slot="prepend">昵称</template>
              </el-input>
              <el-input v-model="query.filter.notes" clearable style="width:200px;margin-left:20px">
                <template slot="prepend">备注</template>
              </el-input>
            </div>
            <div class="flex margin-top">
              <date-range v-model="daterange" @change="handleDateRangeChange"></date-range>
              <base-button type="primary" size="sm" icon class="btn-icon margin-left" @click="getData">
                <span><i class="fa fa-search"></i></span>
                <span>搜索</span>
              </base-button>
              <base-button type="secondary" size="sm" icon class="btn-icon" @click="reset">
                <span><i class="fa fa-mail-reply"></i></span>
                <span>重置</span>
              </base-button>  
            </div>
            <el-table :data="tableData"
                      size="small"
                      row-key="id"
                      border
                      v-loading="loading"
                      :show-summary="true"
                      header-row-class-name="thead-light"
                      :default-sort = "{prop: 'uid', order: 'ascending'}"
                      @sort-change="handleSortChange"
                      class="margin-top">
              <el-table-column prop="uid" label="UID" width="60" align="center" header-align="center" sortable="custom"></el-table-column>
              <el-table-column label="头像昵称" align="left" header-align="center"width="160">
                <template v-slot="{row}">
                  <div class="flex align-center">
                    <member-info :member="row"></member-info>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="parent" label="上级ID" width="60" align="center" header-align="center"></el-table-column>
              <el-table-column prop="orderCount" label="投注笔数" width="60" align="right" header-align="center"></el-table-column>
              <el-table-column prop="orderAmount" label="总投注" width="70" align="right" header-align="center" sortable="custom"></el-table-column>
              <el-table-column prop="sumProfit" label="总盈亏" width="70" align="right" header-align="center" sortable="custom"></el-table-column>
              <el-table-column prop="thirdPartAmount" label="三方投注" width="60" align="right" header-align="center"></el-table-column>
              <el-table-column prop="thirdPartProfit" label="三方盈亏" width="70" align="right" header-align="center"></el-table-column>
              <el-table-column prop="upBalance" label="上分金额" width="60" align="right" header-align="center"></el-table-column>
              <el-table-column prop="downBalance" label="下分金额" width="60" align="right" header-align="center"></el-table-column>
              <el-table-column prop="settledWater" label="已返水" width="60" align="right" header-align="center"></el-table-column>
              <el-table-column prop="unsettledWater" label="待返水" width="60" align="right" header-align="center"></el-table-column>
              <el-table-column prop="settledCommission" label="已结佣金" width="70" align="right" header-align="center"></el-table-column>
              <el-table-column prop="unsettledCommission" label="待结佣金" width="70" align="right" header-align="center"></el-table-column>
              <el-table-column prop="redPackBonus" label="红包" width="60" align="right" header-align="center"></el-table-column>
              <el-table-column prop="activityBonus" label="活动" width="60" align="right" header-align="center"></el-table-column>
              <el-table-column width="70px" align="center" label="操作">
                <div slot-scope="{$index, row}" class="flex justify-center">
                  <base-button
                    @click.native="showModal(row, 'orderList')"
                    type="info"
                    size="sm"
                    outline
                  >
                    详情
                  </base-button>
                </div>
              </el-table-column>
            </el-table>
          </div>
          <div
            slot="footer"
          >
          </div>
        </card>
      </div>
    </b-container>
    <order-list v-if="modal.orderList" :visible.sync="modal.orderList" :uid="uid"></order-list>
  </div>
</template>
<script>
import dayjs from 'dayjs'
import DateRange from '@/components/DateRange'
import MemberInfo from '@/views/Components/MemberInfo'
import OrderList from '@/views/data/member/order-list'
import { getMemberReport } from '@/api/report'
export default {
  components: {
    DateRange,
    MemberInfo,
    OrderList
  },
  data () {
    return {
      loading: false,
      query: {
        filter: {
          uid: '',
          parent: '',
          nickName: '',
          notes: '',
          startDate: dayjs().format('YYYY-MM-DD'),
          endDate: dayjs().format('YYYY-MM-DD')
        },
        start: 0,
        limit: 10,
        sort: {
          prop: 'uid',
          order: 'asc'
        }
      },
      daterange: ['', ''],
      totalCount: 0,
      tableData: [],
      uid: '',
      modal: {
        orderList: false
      }
    }
  },
  methods: {
    getData() {
      this.loading = true
      getMemberReport(this.query).then(res => {
        this.loading = false
        if (res.success) {
          this.totalCount = res.totalCount
          this.tableData = res.data
          this.$bvToast.toast('数据已更新', {
            title: '提示',
            solid: true,
            variant: 'success',
            autoHideDelay: 1000,
            toaster: 'b-toaster-top-center'
          })
        }
      })
    },
    reset () {
      this.query = {
        filter: {
          uid: '',
          parent: '',
          nickName: '',
          notes: '',
          startDate: this.daterange[0],
          endDate: this.daterange[1]
        },
        start: 0,
        limit: 10,
        sort: null
      }
      this.getData()
    },
    handleDateRangeChange (val) {
      this.daterange = val.daterange
      this.query.filter.startDate = val.daterange[0]
      this.query.filter.endDate = val.daterange[1]
      this.getData()
    },
    handleSortChange ({column, prop, order}) {
      if (order === 'ascending') {
        order = 'asc'
      } else {
        order = 'desc'
      }
      this.query.sort = { prop, order }
      this.getData()
    },
    showModal(row, type) {
      this.uid = row.uid
      this.modal[type] = true
    },
  }
}
</script>