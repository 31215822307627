<template>
  <div>    

    <base-header class="pb-6">
      <b-row aling-v="center" class="py-4">
        <b-col cols="7" lg="6">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-breadcrumb></route-breadcrumb>
          </nav>
        </b-col>
      </b-row>
      <!-- Card stats -->
      <b-row>
        <b-col xl="3" md="6">
          <stats-card title="三方总余额"
                      type="gradient-green"
                      :sub-title="data.sumBalance"
                      icon="el-icon-coin">
          </stats-card>

        </b-col>
        <b-col xl="3" md="6">
          <stats-card title="占用余额"
                      type="gradient-red"
                      :sub-title="data.usingBalance"
                      icon="ni ni-money-coins">
          </stats-card>
        </b-col>
      </b-row>
    </base-header>

    <!--Charts-->
    <b-container fluid class="mt--6">
    </b-container>

  </div>
</template>
<script>
  import { qeuryBalance } from '@/api/third-part'
  import StatsCard from '@/components/Cards/StatsCard';
  export default {
    components: {
      StatsCard
    },
    data() {
      return {
        data: {
          sumBalance: '0',
          usingBalance: '0'
        }
      }
    },
    methods: {
      getData() {
        qeuryBalance().then(res => {
          if (res.success) {
            this.data.sumBalance = res.data
          } else {
            this.data.sumBalance = '查询失败'
          }
        }).catch(err => {
          this.data.sumBalance = '查询失败'
        })
      }
    },
    mounted() {
      this.getData()
    }
  };
</script>
<style></style>
