<template>    
  <div class="content">
    <base-header class="pb-6">
      <b-row align-v="center" class="py-4">
        <b-col cols="7" lg="6">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-breadcrumb></route-breadcrumb>
          </nav>
        </b-col>
        <b-col lg="6" cols="5" class="text-right">
        </b-col>
      </b-row>
    </base-header>
    <b-container fluid class="mt--6">
      <div>
        <card class="no-border-card" body-classes="px-0 pb-1" footer-classes="pb-2">
          <div>
            <el-form inline class="pl-3">
              <div class="float-right">
                <el-form-item>
                  <base-button size="sm" icon class="btn-icon" @click="getData">
                    <span><i class="fa fa-refresh"></i></span>
                    <span>刷新</span>
                  </base-button>
                </el-form-item>
                <el-form-item>
                  <base-button
                    @click.native="showModal(0, 'form', 'add')"
                    type="primary"
                    size="sm"
                    icon
                  >
                    <i class="fa fa-plus"></i>新增
                  </base-button>
                </el-form-item>
              </div>
            </el-form>
            <el-table :data="tableData"
                      size="small"
                      row-key="id"
                      border
                      v-loading="loading"
                      header-row-class-name="thead-light">
              <el-table-column prop="id" label="ID" width="50" align="center"></el-table-column>
              <el-table-column prop="title" label="名称" width="120" align="center"></el-table-column>
              <el-table-column prop="minAmount" label="最小金额" width="80" align="center"></el-table-column>
              <el-table-column prop="maxAmount" label="最大金额" width="80" align="center"></el-table-column>
              <el-table-column prop="sumAmount" label="已领取/总金额" width="100" align="center">
                <div slot-scope="{$index, row}" class="flex justify-center">
                  <span class="text-red margin-right-xs">{{row.receivedAmount}}</span>/<span class="text-green margin-left-xs">{{row.sumAmount}}</span>
                </div>
              </el-table-column>
              <el-table-column prop="sumTimes" label="已领取/总份数" width="100" align="center">
                <div slot-scope="{$index, row}" class="flex justify-center">
                  <span class="text-red margin-right-xs">{{row.receivedTimes}}</span>/<span class="text-green margin-left-xs">{{row.sumTimes}}</span>
                </div>
              </el-table-column>
              <el-table-column prop="creator" label="创建人" width="100" align="center"></el-table-column>
              <el-table-column prop="createTime" label="创建时间" width="150" align="center" sortable="custom"></el-table-column>
              <el-table-column prop="expireTime" label="过期时间" width="150" align="center" sortable="custom"></el-table-column>
              <el-table-column label="状态" width="80" align="center" header-align="center">
                <div slot-scope="{$index, row}" class="flex justify-center">
                  <base-switch 
                    v-model="row.available" on-text="启用" off-text="禁用" 
                    type="success" size="sm" @change="handleSwitchStatus(row)">
                  </base-switch>
                </div>
              </el-table-column>
              <el-table-column width="200px" align="center" label="操作">
                <div slot-scope="{$index, row}" class="flex justify-center">
                  <base-button
                    @click.native="showModal(row.id, 'form', 'modify')"
                    type="info"
                    size="sm"
                    outline
                  >
                    <i class="fa fa-edit"></i>编辑
                  </base-button>
                  <base-button
                    @click.native="showModal(row.id, 'record', 'modify')"
                    type="info"
                    size="sm"
                    outline
                  >
                    <i class="fa fa-list"></i>领取记录
                  </base-button>
                </div>
              </el-table-column>
            </el-table>
          </div>
          <div slot="footer">
            <base-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :query="query"
              :total="totalCount">
            </base-pagination>
          </div>
        </card>
      </div>
    </b-container>
    <!--Modals-->
    <edit-form v-if="modal.form" :visible.sync="modal.form" :id="id" :action="formAction" @submit="handleEditDone"></edit-form>
    <record v-if="modal.record" :visible.sync="modal.record" :redPackId="id"></record>
  </div>
</template>
<script>
import { list, switchStatus } from '@/api/config/red-pack'
import EditForm from './form'
import Record from './record'
export default {
  components: {
    EditForm,
    Record
  },
  data() {
    return {
      loading: false,
      query: {
        start: 0,
        limit: 10,
        sort: {
          prop: 'createTime',
          order: 'desc'
        }
      },
      totalCount: 0,
      tableData: [],
      selectedRows: [],
      formAction: '',
      id: '',
      modal: {
        form: false,
        record: false
      }
    };
  },
  created () {
    this.getData()
  },
  methods: {
    handleSizeChange () {
      this.getData()
    },
    handleCurrentChange () {
      this.getData()
    },
    handleSortChange ({column, prop, order}) {
      if (order === 'ascending') {
        order = 'asc'
      } else {
        order = 'desc'
      }
      this.query.sort = { prop, order }
      this.getData()
    },
    getData(clear) {
      this.loading = true
      if (clear) {
        this.tableData = []
      }
      list(this.query).then(res => {
        this.loading = false
        if (res.success) {
          this.totalCount = res.totalCount
          this.tableData = res.data
        }
      })
    },
    handleSwitchStatus(row) {
      switchStatus(row.id, row.available).then(res => {
        if (res.success) {
          this.getData()
        }
      })
    },
    showModal(id, type, action) {
      this.id = id
      this.formAction = action
      this.modal[type] = true
    },
    handleEditDone () {
      this.modal.form = false
      this.getData(true)
    },
    handleDelete(id) {
      this.$bvModal.msgBoxConfirm('确定删除?', {
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: '确定',
        cancelTitle: '取消',
        centered: true
      })
        .then(value => {
          if (value) {
            del(id).then(res => {
              if (res.success) {
                this.getData()
                this.$bvToast.toast('操作成功', {
                  title: '提示',
                  solid: true,
                  variant: 'success',
                  autoHideDelay: 3000,
                  toaster: 'b-toaster-top-center'
                })
              }
            })
          }
        })
    }
  }
};
</script>
<style>
  .no-border-card .card-footer{
    border-top: 0;
  }
</style>
