<template>
  <el-dialog
    v-modal-drag
    width="60%"
    :visible.sync="visible"
    :title="'下级列表-'+uid"
    @close="close">
    <div class="content">
      <el-form inline class="flex align-items-center">
        <el-form-item label="日期">
          <date-range v-model="daterange" @change="handleDateRangeChange" default-range='all'></date-range>
        </el-form-item>
        <el-form-item>
          <base-button type="primary" size="sm" icon class="btn-icon" @click="getData">
            <span><i class="fa fa-search"></i></span>
            <span>搜索</span>
          </base-button>
        </el-form-item>
      </el-form>
      <el-table :data="tableData"
                ref="table"
                size="mini"
                row-key="id"
                v-loading="loading"
                element-loading-text="加载中..."
                header-row-class-name="thead-light">
        <el-table-column prop="uid" label="UID" width="100" sortable>
        </el-table-column>
        <el-table-column label="头像昵称" width="300">
          <div slot-scope="{$index, row}" class="flex justify-start">
            <member-info :member="row" @saved="getData"></member-info>
          </div>
        </el-table-column>
        <el-table-column prop="commission" label="贡献金额" width="100" sortable>
          <template slot-scope="scope">
            <span :class="scope.row.commission>=0?'text-green':'text-red'">{{scope.row.commission}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="regTime" label="注册时间" width="180" sortable>
        </el-table-column>
        <el-table-column width="100px" align="center" label="操作" fit>
          <div slot-scope="{$index, row}" class="flex justify-center">
            <b-button-group>
              <base-button
                @click.native="showModal(row, 'orderList')"
                type="info"
                size="sm"
                outline
              >
              详情
              </base-button>
            </b-button-group>
          </div>
        </el-table-column>
      </el-table>
      <base-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :query="query"
        :total="totalCount">
      </base-pagination>
    </div>
    <div slot="footer">
    </div>
    <!--Modals-->
    <order-list v-if="modal.orderList" :visible.sync="modal.orderList" :uid="uid" default-range='all'></order-list>
  </el-dialog>
</template>

<script>
import MemberInfo from '@/views/Components/MemberInfo'
import OrderList from '@/views/data/member/order-list'
import { BasePagination } from '@/components'
import DateRange from '@/components/DateRange'
import { listChildren } from '@/api/member'
import dayjs from 'dayjs'
export default {
  props: ['visible','uid'],
  components: {
    MemberInfo,
    OrderList,
    BasePagination,
    DateRange
  },
  data() {
    return {
      loading: false,
      quickDate: 'all',
      daterange: ['', ''],
      query: {
        filter: {
          uid: this.uid,
          tradeType: '',
          startDate: '',
          endDate: ''
        },
        start: 0,
        limit: 10,
        sort: null
      },
      totalCount: 0,
      tableData: [],
      selectedRows: [],
      modal: {
        orderList: false
      }
    };
  },
  methods: {
    getData () {
      this.loading = true
      listChildren(this.query).then(res => {
        this.loading = false
        if (res.success) {
          this.totalCount = res.totalCount
          this.tableData = res.data
        }
      })
    },
    handleSizeChange () {
      this.getData()
    },
    handleCurrentChange () {
      this.getData()
    },
    close () {
      this.$emit('update:visible', false)
    },
    handleDateRangeChange (val) {
      this.daterange = val.daterange
      this.query.filter.startDate = val.daterange[0]
      this.query.filter.endDate = val.daterange[1]
      this.getData()
    },
    showModal(row, type) {
      this.uid = row.uid
      this.modal[type] = true
    },
  }
};
</script>