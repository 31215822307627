<template>    
  <div class="content">
    <el-table :data="tableData"
              ref="menuTable"
              size="mini"
              border
              row-key="id"
              default-expand-all
              :tree-props="{children: 'childNodes', hasChildren: 'hasChildren'}"
              v-loading="loading"
              element-loading-text="加载中..."
              header-row-class-name="thead-light"
              @selection-change="selectionChange">
      <el-table-column
        v-for="column in tableColumns"
        :key="column.prop"
        :prop="column.prop"
        v-bind="column"
      >
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
import { Table, TableColumn, Select, Option, Link } from 'element-ui';
import { list } from '@/api/system/menu'
import { selectMenu, grantMenu } from '@/api/system/role'
export default {
  props: ['roleId'],
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Link.name]: Link
  },
  data() {
    return {
      loading: false,
      tableColumns: [
        {
          type: 'selection'
        },
        {
          prop: 'id',
          label: 'ID',
          minWidth: 100,
          sortable: true
        },
        {
          prop: 'name',
          label: '名称',
          minWidth: 100,
          sortable: true
        },
        {
          prop: 'label',
          label: '标题',
          minWidth: 150,
          sortable: true
        },
        {
          prop: 'url',
          label: 'url',
          minWidth: 150
        },
        {
          prop: 'order',
          label: '排序',
          minWidth: 100,
          sortable: true
        }
      ],
      tableData: [],
      selectedRows: []
    };
  },
  created() {
    this.getData()
  },
  methods: {
    selectionChange(selectedRows) {
      console.log('selectedRows', selectedRows)
      this.selectedRows = selectedRows
    },
    getData () {
      this.loading = true
      list().then(res => {
        if (res.success) {
          this.tableData = res.data
          selectMenu(this.roleId).then(res => {
            if (res.success) {
              this.$nextTick(function () {
                if (res.data.length > 0) {
                  this.tableData.forEach(row => {
                    if (res.data.indexOf(row.id)>-1) {
                      this.$refs.menuTable.toggleRowSelection(row, true)
                    }
                    if (row.childNodes.length > 0) {
                      row.childNodes.forEach(child => {
                        if (res.data.indexOf(child.id)>-1) {
                          this.$refs.menuTable.toggleRowSelection(child, true)
                        }
                      })
                    }
                  })
                  this.loading = false
                } else {
                  this.loading = false
                }
              })
            }
          })
        }
      })
    },
    grantMenu () {
      let menuIds = []
      this.selectedRows.forEach(row => {
        menuIds.push(row.id)
      })
      this.loading = true
      grantMenu(this.roleId, menuIds).then(res => {
        this.loading = false
        if (res.success) {
          this.$emit('submit')
          this.$bvToast.toast('操作成功', {
            title: '提示',
            solid: true,
            variant: 'success',
            autoHideDelay: 3000,
            toaster: 'b-toaster-top-center'
          })
        }
      })
    }
  }
};
</script>
<style>
  .no-border-card .card-footer{
    border-top: 0;
  }
</style>
