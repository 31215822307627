<template>
  <div class="odds">
    <base-header class="pb-6">
      <b-row align-v="center" class="py-4">
        <b-col cols="7" lg="6">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-breadcrumb></route-breadcrumb>
          </nav>
        </b-col>
        <b-col lg="6" cols="5" class="text-right">
        </b-col>
      </b-row>
    </base-header>
    <b-container fluid class="mt--6">
      <b-card no-body>
        <!-- Card header -->
        <b-card-header>
          <div class="flex justify-between align-center">
            <div class="flex">
              <game-select v-model="game" label="选择游戏" thirdPart="OWN"></game-select>
            </div>
          </div>
        </b-card-header>
        <!-- Card body -->
        <b-card-body>
          <pk v-if="game.type=='PK'" :gameCode="game.code"></pk>
          <ss v-else-if="game.type=='SS'" :gameCode="game.code"></ss>
          <pc-20 v-else-if="game.type=='PC20'" :gameCode="game.code"></pc-20>
          <pc-28 v-else-if="game.type=='PC28'" :gameCode="game.code"></pc-28>
          <pc-32 v-else-if="game.type=='PC32'" :gameCode="game.code"></pc-32>
        </b-card-body>
      </b-card>
    </b-container>
  </div>
</template>

<script>
import GameSelect from '@/views/Components/game-select'
import Pk from './pk'
import Ss from './ss'
import Pc20 from './pc20'
import Pc28 from './pc28'
import Pc32 from './pc32'
export default {
  components: {
    GameSelect,
    Pk,
    Ss,
    Pc20,
    Pc28,
    Pc32
  },
  data () {
    return {
      loading: false,
      tableData: [],
      game: {
        code: 10010,
        type: 'SS',
        name: '澳洲幸运5'
      }
    }
  },
  computed: {
    colums () {

    }
  },
  created () {
  },
  methods: {
    save () {
      // modify(this.model).then(res => {
      //   if (res.success) {
      //     this.$bvToast.toast('操作成功', {
      //       title: '提示',
      //       solid: true,
      //       variant: 'success',
      //       autoHideDelay: 3000,
      //       toaster: 'b-toaster-top-center'
      //     })
      //   }
      // })
    }
  }
}
</script>
<style lang="scss">
.odds {
  .el-table .cell {
    padding-left: 0;
    padding-right: 0;
  }
}
</style>