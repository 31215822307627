<template>
  <div class="draw-code-result">
    <div v-if="gameType=='PK'">
      <img
        v-for="tempCode in codeArray"
        :key="tempCode"
        :src="'/img/num/'+tempCode+'.png'"
        class="num"
        :class="'bg-num-'+parseInt(tempCode)">
      </img>
    </div>
    <div v-else-if="gameType=='SS'" class="flex">
      <el-avatar :size="25" v-for="(tempCode, i) in codeArray" :key="i"  :class="['bg-num-ss', 'margin-left']">{{tempCode | formatCode}}</el-avatar>
    </div>
    <div v-else-if="gameType=='PC20' || gameType=='PC28' || gameType=='PC32'" class="flex">
      <el-avatar :size="25" class="bg-num-ss margin-left-xs">
        <span>{{codeArray[0] | formatCode}}</span>
      </el-avatar>
      <div class="margin-left-xs">+</div>
      <el-avatar :size="25" class="bg-num-ss margin-left-xs">
        <span>{{codeArray[1] | formatCode}}</span>
      </el-avatar>
      <div class="margin-left-xs">+</div>
      <el-avatar :size="25" class="bg-num-ss margin-left-xs">
        <span>{{codeArray[2] | formatCode}}</span>
      </el-avatar>
      <div class="margin-left-xs">=</div>
      <el-avatar :size="25" :class="['cu-avatar', 'sm', 'round', 'bg-red', 'margin-left-xs']">
        <span>{{ codeSum }}</span>
      </el-avatar>
    </div>
  </div>
</template>
<script>
export default {
  props: ['gameType','code'],
  data () {
    return {}
  },
  computed: {
    codeArray () {
      if (!this.code) {
        return []
      }
      return this.code.split(',')
    },
    codeSum () {
      let sum = 0
      this.codeArray.forEach(code => {
        sum += parseInt(code)
      })
      return sum
    },
  },
  filters: {
    formatCode (value) {
      return parseInt(value)
    },
    threeResultName (value) {
      if (value==0) return '杂六'
      if (value==1) return '半顺'
      if (value==2) return '顺子'
      if (value==3) return '对子'
      if (value==4) return '豹子'
      return value
    }
  }
}
</script>
<style lang="scss">
.draw-code-result {
  .num {
    margin-right:2px;
    width:25px;
    height:25px;
  }
  .bg-num-ss {
    background-color: #0092DE;
  }
  .bg-num-pc {
    background-color: #FF2400;
  }
}
</style>