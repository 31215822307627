<template>
  <div class="content">
    <base-header class="pb-6">
      <b-row align-v="center" class="py-4">
        <b-col cols="7" lg="6">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-breadcrumb></route-breadcrumb>
          </nav>
        </b-col>
        <b-col lg="6" cols="5" class="text-right">
        </b-col>
      </b-row>
    </base-header>
    <b-container fluid class="mt--6">
      <div>
        <card class="no-border-card" body-classes="px-0 pb-1" footer-classes="pb-2">
          <div>
            <el-form inline class="pl-3">
              <el-form-item label="日期">
                <date-range v-model="daterange" @change="handleDateRangeChange"></date-range>
              </el-form-item>
              <el-form-item>
                <base-button type="primary" size="sm" icon class="btn-icon" @click="getData">
                  <span><i class="fa fa-search"></i></span>
                  <span>搜索</span>
                </base-button>
                <base-button type="secondary" size="sm" icon class="btn-icon" @click="reset">
                  <span><i class="fa fa-mail-reply"></i></span>
                  <span>重置</span>
                </base-button>
              </el-form-item>
              <div class="float-right">
              </div>
            </el-form>
            <el-table :data="tableData"
                      size="small"
                      row-key="id"
                      border
                      v-loading="loading"
                      header-row-class-name="thead-light">
              <el-table-column prop="date" label="日期" width="80" align="center" header-align="center"></el-table-column>
              <el-table-column prop="newUser" label="注册人数" width="70" align="right" header-align="center"></el-table-column>
              <el-table-column prop="experienceUser" label="体验人数" width="70" align="right" header-align="center"></el-table-column>
              <el-table-column prop="upUser" label="上分人数" width="70" align="right" header-align="center"></el-table-column>
              <el-table-column prop="upBalance" label="上分金额" width="70" align="right" header-align="center"></el-table-column>
              <el-table-column prop="downUser" label="下分人数" width="70" align="right" header-align="center"></el-table-column>
              <el-table-column prop="downBalance" label="下分金额" width="70" align="right" header-align="center"></el-table-column>
              <el-table-column prop="orderAmount" label="总投注" width="60" align="right" header-align="center"></el-table-column>
              <el-table-column prop="thirdPartAmount" label="三方投注" width="70" align="right" header-align="center"></el-table-column>
              <!-- <el-table-column prop="thirdPartProfit" label="第三方输赢" width="80" align="right" header-align="center"></el-table-column> -->
              <el-table-column prop="settledWater" label="已返水" width="60" align="right" header-align="center"></el-table-column>
              <el-table-column prop="unsettledWater" label="待返水" width="60" align="right" header-align="center"></el-table-column>
              <el-table-column prop="settledCommission" label="已结佣金" width="70" align="right" header-align="center"></el-table-column>
              <el-table-column prop="unsettledCommission" label="待结佣金" width="70" align="right" header-align="center"></el-table-column>
              <el-table-column prop="redPackBonus" label="红包" width="60" align="right" header-align="center"></el-table-column>
              <el-table-column prop="activityBonus" label="活动" width="60" align="right" header-align="center"></el-table-column>
              <el-table-column prop="sumProfit" label="总盈亏" width="60" align="right" header-align="center"></el-table-column>
              <el-table-column prop="winAmount" label="中奖金额" width="60" align="right" header-align="center"></el-table-column>
              <el-table-column prop="sumBalance" label="平台余额" width="70" align="right" header-align="center"></el-table-column>
              <el-table-column label="入账" width="70" align="right" header-align="center">
                <div slot-scope="{$index, row}" class="flex justify-center">
                  {{row.upBalance - row.downBalance}}
                </div>
              </el-table-column>
            </el-table>
          </div>
          <div
            slot="footer"
          >
          </div>
        </card>
      </div>
    </b-container>
  </div>
</template>
<script>
import dayjs from 'dayjs'
import DateRange from '@/components/DateRange'
import { getSumReport } from '@/api/report'
export default {
  components: {
    DateRange
  },
  data () {
    return {
      loading: false,
      query: {
        filter: {
          startDate: dayjs().format('YYYY-MM-DD'),
          endDate: dayjs().format('YYYY-MM-DD')
        },
        start: 0,
        limit: 10,
        sort: null
      },
      daterange: ['', ''],
      totalCount: 0,
      tableData: []
    }
  },
  methods: {
    getData() {
      this.loading = true
      getSumReport(this.query).then(res => {
        this.loading = false
        if (res.success) {
          this.totalCount = res.totalCount
          this.tableData = res.data
          this.$bvToast.toast('数据已更新', {
            title: '提示',
            solid: true,
            variant: 'success',
            autoHideDelay: 1000,
            toaster: 'b-toaster-top-center'
          })
        }
      })
    },
    reset () {
      this.query = {
        filter: {
          startDate: this.daterange[0],
          endDate: this.daterange[1]
        },
        start: 0,
        limit: 10,
        sort: null
      }
      this.getData()
    },
    handleDateRangeChange (val) {
      this.daterange = val.daterange
      this.query.filter.startDate = val.daterange[0]
      this.query.filter.endDate = val.daterange[1]
      this.getData()
    },
  }
}
</script>