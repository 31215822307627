<template>
  <el-dialog
    v-modal-drag
    :title="action==='add'?'新增游戏类别':'编辑游戏类别'"
    :visible.sync="visible"
    width="30%"
    :before-close="close">
    <el-form :model="model" ref="form" label-position="top">
      <el-row :gutter="20">
        <el-col :span="24">
          <el-form-item prop="name" label="代码" required>
            <el-input v-model="model.name"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="24">
          <el-form-item prop="label" label="标题" required>
            <el-input v-model="model.label"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="24">
          <el-form-item prop="col" label="列数" required>
            <el-input v-model="model.col" type="number" :min="1"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div slot="footer">
      <base-button size="sm" type="primary" @click="submit">保存</base-button>
      <base-button size="sm" type="link" class="ml-auto" @click="close">取消</base-button>
    </div>
  </el-dialog>
</template>
<script>
import { Modal } from '@/components';
import { detail, create, modify } from '@/api/config/category'
export default {
  name: 'CategoryForm',
  props: ['visible', 'action', 'id'],
  components: {
    Modal
  },
  data () {
    return {
      model: {
        id: '',
        name: '',
        label: '',
        col: 1
      }
    }
  },
  created () {
    let that = this
    if (this.action==='modify') {
      detail(this.id).then(res => {
        if (res.success) {
          this.model = res.data
        }
      })
    }
  },
  methods: {
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.action === 'add') {
            create(this.model).then(res => {
              if (res.success) {
                this.close()
                this.$emit('submit')
              }
            })
          } else {
            modify(this.model).then(res => {
              if (res.success) {
                this.close()
                this.$emit('submit')
              }
            })
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    close () {
      this.$emit('update:visible', false)
    },
  }
}
</script>
<style lang="scss">
</style>