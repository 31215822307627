<template>
  <div class="base-upload">
    <el-upload
      class="avatar-uploader"
      :limit="1"
      :class="{'hide-upload-btn': upload.fileList.length>0}"
      list-type="picture-card"
      :headers="upload.headers"
      :data="upload.data"
      :file-list="upload.fileList"
      :action="upload.serverHost + '/upload'"
      :before-upload="beforeUpload"
      :on-preview="onPreview"
      :on-success="onSuccess"
      :on-remove="onRemove">
      <i class="el-icon-plus"></i>
    </el-upload>
    <el-dialog
      v-modal-drag
      width="30%"
      :visible.sync="previewVisible">
      <el-image fit="contain" :src="previewImageUrl" alt=""/>
    </el-dialog>
  </div>
</template>

<script>
import * as imageConversion from 'image-conversion'
import { BASE_URL } from '@/utils/fetch' 
export default {
  props: {
    imgName: {
      type: String,
      default: ''
    },
    imgSize: {
      type: Number,
      default: 100
    }
  },
  data () {
    return {
      upload: {
        serverHost: BASE_URL,
        header: {},
        data: {},
        fileList: []
      },
      previewVisible: false,
      previewImageUrl: ''
    }
  },
  watch: {
    'imgName': {
      handler(val) {
        // console.log('-->', val)
        if (val) {
          this.upload.fileList = [{
            name: val,
            url: this.upload.serverHost + 'static/system/' + val
          }]
        }
      },
      immediate: true
    }
  },
  methods: {
    beforeUpload (file) {
      let that = this
      const isImg = (file.type === 'image/jpeg' || file.type === 'image/png')
      if (!isImg) {
        that.$bvToast.toast('请上传jpg或png格式图片', {
          title: '提示',
          solid: true,
          variant: 'warning',
          autoHideDelay: 3000,
          toaster: 'b-toaster-top-center'
        })
        return
      } 
      // 压缩图片
      return new Promise((resolve, reject) => {
        if (file.type === 'image/jpeg' && file.size > that.imgSize*1000) {
          imageConversion.compressAccurately(file, that.imgSize).then(res => {
            res = new File([res], file.name, {type: res.type, lastModified: Date.now()})
            resolve(res)
          })
        }
        resolve()
      })
    },
    onPreview (file) {
      this.previewImageUrl = file.url
      this.previewVisible = true
    },
    onSuccess (response, file, fileList) {
      let that = this
      if (response.success) {
        this.upload.fileList = [{url: that.upload.serverHost + '/static/system/' + response.data}]
        this.$emit('success', response.data)
      } else {
        this.upload.fileList = []
        let msg = response.msg
        if (response.errCode == 'MaxUploadSizeExceeded') {
          msg = '文件大小超限，最大支持'+this.imgSize+'K'
        }
        this.$bvToast.toast(msg, {
          title: '上传失败',
          solid: true,
          variant: 'warning',
          autoHideDelay: 3000,
          toaster: 'b-toaster-top-center'
        })
      }
    },
    onRemove () {
      this.upload.fileList = []
    }
  }
}
</script>
<style lang="scss">
.base-upload {
  .hide-upload-btn .el-upload--picture-card {
    display: none;
  }
  // 去掉动画效果
  .el-upload-list__item {
    transition: none !important;
  }
}

</style>