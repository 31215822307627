<template>
  <div v-loading="loading" class="param">
    <base-header class="pb-6">
      <b-row align-v="center" class="py-4">
        <b-col cols="7" lg="6">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-breadcrumb></route-breadcrumb>
          </nav>
        </b-col>
        <b-col lg="6" cols="5" class="text-right">
        </b-col>
      </b-row>
    </base-header>
    <b-container fluid class="mt--6">
      <b-card no-body>
        <!-- Card header -->
        <b-card-header>
          <div>
            <div class="float-left"><h3 class="mb-0">体验金配置</h3></div>
            <div class="float-right">
              <base-button type="secondary" size="sm" icon class="btn-icon" @click="getData">
                <span><i class="fa fa-refresh"></i></span>
                <span>刷新</span>
              </base-button>
              <base-button type="primary" size="sm" icon @click.native="save">
                <i class="fa fa-check"></i>保存
              </base-button>
            </div>
          </div>
        </b-card-header>
        <!-- Card body -->
        <b-card-body>
          <el-form :model="model" ref="form" label-position="top">
            <el-row :gutter="10">
                <el-form-item prop="experience_open" label="是否赠送体验金" required>
                  <div class="flex align-center">
                    <base-switch 
                      v-model="model.experience_open" on-text="是" off-text="否" 
                      type="success" size="sm">
                    </base-switch>
                    <span v-show="model.experience_open" class="margin-left text-gray">新会员自动赠送体验金，完成兑换任务或充值后转为正式会员</span>
                    <span v-show="!model.experience_open" class="margin-left text-gray">新会员不会赠送体验金，只能充值后转为正式会员</span>
                  </div>
                </el-form-item>
                <el-form-item prop="experience_amount" label="赠送额度" required>
                  <el-input v-model="model.experience_amount"></el-input>
                </el-form-item>
                <el-form-item prop="experience_expire_hours" label="体验金过期时间(小时)" required>
                  <el-input v-model="model.experience_expire_hours"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </b-card-body>
      </b-card>
    </b-container>
  </div>
</template>

<script>
import { get, modify } from '@/api/config/param'
export default {
  data () {
    return {
      loading: false,
      model: {
        experience_open: true,
        experience_amount: 0,
        experience_expire_hours: 24
      }
    }
  },
  created () {
    this.getData()
  },
  methods: {
    getData () {
      this.loading = true
      get('experience_open', 'experience_amount', 'experience_expire_hours').then(res => {
        this.loading = false
        if (res.success) {
          this.model = res.data
          this.model.experience_open = res.data.experience_open=='true'
        }
      })
    },
    save () {
      modify(this.model).then(res => {
        if (res.success) {
          this.$bvToast.toast('操作成功', {
            title: '提示',
            solid: true,
            variant: 'success',
            autoHideDelay: 3000,
            toaster: 'b-toaster-top-center'
          })
        }
      })
    }
  }
}
</script>
<style lang="scss">
.param {
  .el-upload-list--picture-card .el-upload-list__item {
    height: 100%;
  }
  .el-upload-list--picture-card .el-upload-list__item-thumbnail {
  width: 148px;
  height: 100%;
}
}
</style>