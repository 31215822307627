import { formFetch, jsonFetch } from '@/utils/fetch'
import Qs from 'qs'

export function list (query) {
  return jsonFetch.post('/faq/list', query)
}

export function detail (id) {
  return formFetch.post(`/faq/${id}/detail`)
}

export function create (quickReply) {
  return jsonFetch.post(`/faq/create`, quickReply)
}

export function modify (quickReply) {
  return jsonFetch.post(`/faq/${quickReply.id}/modify`, quickReply)
}

export function del (id) {
  return formFetch.post(`/faq/${id}/delete`)
}

export function switchStatus (id, available) {
  return formFetch.post(`/faq/${id}/status`, Qs.stringify({ available }))
}

export function sort (ids) {
  return jsonFetch.post(`/faq/sort`, ids)
}
