<template>
  <el-dialog
    v-modal-drag
    width="30%"
    :visible.sync="visible"
    :title="action==='add'?'新增域名':'编辑域名'"
    @close="close">
    <el-form :model="model" ref="form" label-position="top">
      <el-row :gutter="10">
        <el-col :span="24">
          <el-form-item prop="type" label="类型" required>
            <el-select v-model="model.type" disabled>
              <el-option value="entry" label="入口域名"></el-option>
              <el-option value="auth" label="鉴权域名"></el-option>
              <el-option value="landing" label="落地域名"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="24">
          <el-form-item prop="domain" label="域名" required>
            <el-input v-model="model.domain"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div slot="footer">
      <base-button size="sm" type="primary" @click="submit">保存</base-button>
      <base-button size="sm" type="link" class="ml-auto" @click="close">取消</base-button>
    </div>
  </el-dialog>
</template>
<script>
import { detail, create, modify } from '@/api/config/domain'
export default {
  name: 'DomainForm',
  props: ['visible', 'action', 'id'],
  data () {
    return {
      model: {
        id: '',
        domain: '',
        type: 'landing'
      }
    }
  },
  created () {
    if (this.action==='modify') {
      detail(this.id).then(res => {
        if (res.success) {
          this.model = res.data
        }
      })
    }
  },
  methods: {
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.action === 'add') {
            create(this.model).then(res => {
              if (res.success) {
                this.close()
                this.$emit('submit')
              }
            })
          } else {
            modify(this.model).then(res => {
              if (res.success) {
                this.close()
                this.$emit('submit')
              }
            })
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    close () {
      this.$emit('update:visible', false)
    }
  }
}
</script>
<style lang="scss">

</style>