<template>    
  <div class="content member">
    <base-header class="pb-6">
      <b-row align-v="center" class="py-4">
        <b-col cols="7" lg="6">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-breadcrumb></route-breadcrumb>
          </nav>
        </b-col>
        <b-col lg="6" cols="5" class="text-right">
        </b-col>
      </b-row>
    </base-header>
    <b-container fluid class="mt--6">
      <div>
        <card class="no-border-card" body-classes="px-0 pb-1" footer-classes="pb-2">
          <!-- <template slot="header">
            <h3 class="mb-0">Paginated tables</h3>
            <p class="text-sm mb-0">
              This is a client side example of paginated tables using element-ui tables.
            </p>
          </template> -->
          <div>
            <el-form inline class="pl-3">
              <el-form-item label="状态">
                <el-select v-model="query.filter.available">
                  <el-option value="" label="全部"></el-option>
                  <el-option :value="1" label="正常"></el-option>
                  <el-option :value="0" label="封号"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="UID">
                <el-input v-model="query.filter.uid" clearable></el-input>
              </el-form-item>
              <el-form-item label="昵称">
                <el-input v-model="query.filter.nickName" clearable></el-input>
              </el-form-item>
              <el-form-item>
                <base-button type="primary" size="sm" icon class="btn-icon" @click="getData">
                  <span><i class="fa fa-search"></i></span>
                  <span>搜索</span>
                </base-button>
                <base-button type="secondary" size="sm" icon class="btn-icon" @click="reset">
                  <span><i class="fa fa-mail-reply"></i></span>
                  <span>重置</span>
                </base-button>
              </el-form-item>
            </el-form>
            <el-table :data="tableData"
                      size="small"
                      row-key="id"
                      border
                      v-loading="loading"
                      header-row-class-name="thead-light"
                      @sort-change="handleSortChange">
              <el-table-column prop="uid" label="UID" width="100" align="center" sortable="custom"></el-table-column>
              <el-table-column label="头像昵称" width="200">
                <div slot-scope="{$index, row}" class="flex justify-start">
                  <member-info :member="row" @saved="getData"></member-info>
                </div>
              </el-table-column>
              <el-table-column prop="children" label="推广人数" width="100" sortable="custom"></el-table-column>
              <el-table-column prop="sumCommission" label="总佣金" width="100" align="right" sortable="custom"></el-table-column>
              <el-table-column prop="restCommission" label="佣金余额" width="100" align="right" sortable="custom"></el-table-column>
              <el-table-column width="200px" align="center" label="操作" fit>
                <div slot-scope="{$index, row}" class="flex justify-center">
                  <base-button
                    @click.native="showChildren(row.uid)"
                    type="info"
                    size="sm"
                    outline
                  >
                    下级列表
                  </base-button>
                  <base-button
                    @click.native="delAgent(row.uid)"
                    type="info"
                    size="sm"
                    outline
                  >
                    删除代理
                  </base-button>
                </div>
              </el-table-column>
            </el-table>
          </div>
          <div
            slot="footer"
          >
          <base-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :query="query"
            :total="totalCount">
          </base-pagination>
          </div>
        </card>
      </div>
    </b-container>
    <!--Modals-->
    <children-list v-if="modalChildren" :visible.sync="modalChildren" :uid="uid"></children-list>
  </div>
</template>
<script>
import MemberInfo from '@/views/Components/MemberInfo'
import { BasePagination } from '@/components';
import { list, del } from '@/api/agent'
import ChildrenList from './children-list'
export default {
  components: {
    MemberInfo,
    BasePagination,
    ChildrenList
  },
  data() {
    return {
      loading: false,
      query: {
        filter: {
          available: '',
          uid: '',
          nickName: ''
        },
        start: 0,
        limit: 10,
        sort: null
      },
      totalCount: 0,
      tableData: [],
      selectedRows: [],
      uid: '',
      modalChildren: false
    };
  },
  created () {
    this.getData()
  },
  methods: {
    handleSizeChange () {
      this.getData()
    },
    handleCurrentChange () {
      this.getData()
    },
    handleSortChange ({column, prop, order}) {
      console.log({column, prop, order})
      if (order === 'ascending') {
        order = 'asc'
      } else {
        order = 'desc'
      }
      this.query.sort = { prop, order }
      this.getData()
    },
    getData() {
      this.loading = true
      list(this.query).then(res => {
        this.loading = false
        if (res.success) {
          this.totalCount = res.totalCount
          this.tableData = res.data
        }
      })
    },
    reset () {
      this.query = {
        filter: {
          available: '',
          uid: '',
          nickName: ''
        },
        start: 0,
        limit: 10,
        sort: null
      }
      this.getData()
    },
    showChildren(uid) {
      this.uid = uid
      this.modalChildren = true
    },
    delAgent (uid) {
      this.$bvModal.msgBoxConfirm('确定删除?', {
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: '确定',
        cancelTitle: '取消',
        centered: true
      })
        .then(value => {
          if (value) {
            del(uid).then(res => {
              if (res.success) {
                this.getData()
                this.$bvToast.toast('操作成功', {
                  title: '提示',
                  solid: true,
                  variant: 'success',
                  autoHideDelay: 3000,
                  toaster: 'b-toaster-top-center'
                })
              }
            })
          }
        })
    },
  }
};
</script>
<style lang="scss">
.member {
  .no-border-card .card-footer{
    border-top: 0;
  }
}
</style>
