<template>
  <el-dialog
    v-modal-drag
    width="70%"
    :visible.sync="visible"
    :title="'领取记录 - '+redPackId"
    @close="close">
    <card class="no-border-card" body-classes="px-0 pb-1" footer-classes="pb-2">
      <div>
        <el-table :data="tableData"
                  size="small"
                  row-key="id"
                  border
                  v-loading="loading"
                  header-row-class-name="thead-light">
          <el-table-column prop="uid" label="UID" width="100" align="center"></el-table-column>
          <el-table-column prop="nickName" label="头像昵称" width="200" align="center">
            <div slot-scope="{$index, row}" class="flex justify-center">
              <member-info :member="row" readOnly></member-info>
            </div>
          </el-table-column>
          <el-table-column prop="redPackName" label="红包名称" width="200" align="center"></el-table-column>
          <el-table-column prop="amount" label="领取金额" width="100" align="center"></el-table-column>
          <el-table-column prop="createTime" label="领取时间" width="150" align="center" sortable="custom"></el-table-column>
        </el-table>
      </div>
      <div slot="footer">
        <base-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :query="query"
          :total="totalCount">
        </base-pagination>
      </div>
    </card>
  </el-dialog>
</template>
<script>
import MemberInfo from '@/views/Components/MemberInfo'
import { record } from '@/api/config/red-pack'
export default {
  props: ['visible','redPackId'],
  components: {
    MemberInfo
  },
  data() {
    return {
      loading: false,
      query: {
        start: 0,
        limit: 10,
        sort: {
          prop: 'createTime',
          order: 'desc'
        }
      },
      totalCount: 0,
      tableData: [],
      selectedRows: [],
      id: ''
    };
  },
  created () {
    this.getData()
  },
  methods: {
    handleSizeChange () {
      this.getData()
    },
    handleCurrentChange () {
      this.getData()
    },
    handleSortChange ({column, prop, order}) {
      if (order === 'ascending') {
        order = 'asc'
      } else {
        order = 'desc'
      }
      this.query.sort = { prop, order }
      this.getData()
    },
    getData(clear) {
      this.loading = true
      if (clear) {
        this.tableData = []
      }
      record(this.redPackId, this.query).then(res => {
        this.loading = false
        if (res.success) {
          this.totalCount = res.totalCount
          this.tableData = res.data
        }
      })
    },
    close () {
      this.$emit('update:visible', false)
    },
  }
};
</script>
<style>
  .no-border-card .card-footer{
    border-top: 0;
  }
</style>
