import { formFetch, jsonFetch } from '@/utils/fetch'
import Qs from 'qs'

export function list () {
  return formFetch.post('/carousel/list')
}

export function switchStatus (id, available) {
  return formFetch.post(`/carousel/${id}/status`, Qs.stringify({ available }))
}

export function detail (id) {
  return formFetch.post(`/carousel/${id}/detail`)
}

export function create (carousel) {
  return jsonFetch.post(`/carousel/create`, carousel)
}

export function modify (carousel) {
  return jsonFetch.post(`/carousel/${carousel.id}/modify`, carousel)
}

export function del (id) {
  return formFetch.post(`/carousel/${id}/delete`)
}

export function sort (ids) {
  return jsonFetch.post(`/carousel/sort`, ids)
}