<template>    
  <div class="content balance">
    <base-header class="pb-6">
      <b-row align-v="center" class="py-4">
        <b-col cols="7" lg="6">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-breadcrumb></route-breadcrumb>
          </nav>
        </b-col>
        <b-col lg="6" cols="5" class="text-right">
        </b-col>
      </b-row>
    </base-header>
    <b-container fluid class="mt--6">
      <div>
        <card class="no-border-card" body-classes="px-0 pb-1" footer-classes="pb-2">
          <!-- <template slot="header">
            <h3 class="mb-0">Paginated tables</h3>
            <p class="text-sm mb-0">
              This is a client side example of paginated tables using element-ui tables.
            </p>
          </template> -->
          <div>
            <el-form inline class="pl-3">
              <el-form-item label="状态">
                <el-select v-model="query.filter.state" @change="getData" style="width:150px">
                  <el-option value="" label="全部"></el-option>
                  <el-option :value="0" label="审核中"></el-option>
                  <el-option :value="1" label="通过"></el-option>
                  <el-option :value="2" label="拒绝"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="UID">
                <el-input v-model="query.filter.uid"></el-input>
              </el-form-item>
              <el-form-item label="昵称">
                <el-input v-model="query.filter.nickName"></el-input>
              </el-form-item>
              <el-form-item>
                <base-button type="primary" size="sm" icon class="btn-icon" @click="getData">
                  <span><i class="fa fa-search"></i></span>
                  <span>搜索</span>
                </base-button>
                <base-button type="secondary" size="sm" icon class="btn-icon" @click="reset">
                  <span><i class="fa fa-mail-reply"></i></span>
                  <span>重置</span>
                </base-button>
              </el-form-item>
            </el-form>
            <el-table :data="tableData"
                      size="small"
                      row-key="id"
                      border
                      v-loading="loading"
                      header-row-class-name="thead-light"
                      :row-class-name="tableRowClassName"
                      @sort-change="handleSortChange">
              <el-table-column prop="id" label="序号" width="80" align="center" sortable="custom"></el-table-column>
              <el-table-column prop="uid" label="UID" width="80" align="center" sortable="custom"></el-table-column>
              <el-table-column prop="nickName" label="头像昵称" width="200" align="center">
                <div slot-scope="{$index, row}" class="flex justify-start">
                  <member-info :member="row" @saved="getData"></member-info>
                </div>
              </el-table-column>
              <el-table-column prop="amount" label="金额" width="80" align="right" header-align="center" sortable="custom"></el-table-column>
              <el-table-column prop="channel" label="渠道" width="100" align="right" header-align="center" sortable="custom"></el-table-column>
              <el-table-column prop="createTime" label="申请时间" width="150" align="center" sortable="custom"></el-table-column>
              <el-table-column prop="state" label="状态" width="80" align="center" sortable="custom">
                <div slot-scope="{$index, row}">
                  {{row.state | balanceState}}
                </div>
              </el-table-column>
              <el-table-column prop="handleTime" label="处理时间" width="150" align="center" sortable="custom"></el-table-column>
              <el-table-column prop="operator" label="处理人" width="100" align="center"></el-table-column>
              <el-table-column prop="notes" label="备注" align="center"></el-table-column>
              <el-table-column width="150px" align="center" label="操作" fit>
                <div slot-scope="{$index, row}" v-if="row.state==0">
                  <base-button
                    @click.native="handleAccept(row)"
                    type="info"
                    size="sm"
                    outline
                  >
                    通过
                  </base-button>
                  <base-button
                    @click.native="handleReject(row)"
                    type="danger"
                    size="sm"
                    outline
                  >
                    拒绝
                  </base-button>
                </div>
              </el-table-column>
            </el-table>
          </div>
          <div
            slot="footer"
          >
            <base-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :query="query"
              :total="totalCount">
            </base-pagination>
          </div>
        </card>
      </div>
    </b-container>
    <!--Modals-->
    <el-dialog
      v-modal-drag
      width="30%"
      :visible.sync="modalConfirm"
      :title="title"
      @open="notes = ''">
      <el-row>
        <el-col :span="12">
          <div>UID: {{ row.uid}}</div>
        </el-col>
        <el-col :span="12">
          <div>昵称: {{ row.nickName}}</div>
        </el-col>
      </el-row>
      <el-row class="margin-top-sm">
        <el-col :span="12">
          <div>上分金额: {{ row.amount}}</div>
        </el-col>
        <el-col :span="12">
          <div>申请时间: {{ row.createTime}}</div>
        </el-col>
      </el-row>
      <div class="margin-top-sm">备注(选填)</div>
      <el-input v-model="notes" type="textarea" rows="3" maxlength="50" show-word-limit placeholder="请输入备注(选填)"></el-input>
      <div slot="footer">
        <base-button size="sm" type="primary" @click="handleConfirm">确认</base-button>
        <base-button size="sm" type="link" class="ml-auto" @click="modalConfirm = false">取消</base-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { Table, TableColumn, Select, Option } from 'element-ui';
import MemberInfo from '@/views/Components/MemberInfo'
import { BasePagination } from '@/components';
import { listUpDownBalance, accept, reject } from '@/api/finance/balance'
export default {
  components: {
    BasePagination,
    MemberInfo,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  data() {
    return {
      loading: false,
      query: {
        filter: {
          type: 'UP',
          state: '',
          uid: '',
          nickName: ''
        },
        start: 0,
        limit: 10,
        sort: null
      },
      totalCount: 0,
      tableData: [],
      row: {},
      accept: false,
      title: '',
      notes: '',
      modalConfirm: false
    };
  },
  filters: {
    balanceState (value) {
      if (value==0) {
        return '审核中'
      }
      if (value==1) {
        return '通过'
      }
      if (value==2) {
        return '拒绝'
      }
      return value
    }
  },
  created () {
    this.getData()
  },
  methods: {
    handleSizeChange () {
      this.getData()
    },
    handleCurrentChange () {
      this.getData()
    },
    handleSortChange ({column, prop, order}) {
      console.log({column, prop, order})
      if (order === 'ascending') {
        order = 'asc'
      } else {
        order = 'desc'
      }
      this.query.sort = { prop, order }
      this.getData()
    },
    getData() {
      this.loading = true
      listUpDownBalance(this.query).then(res => {
        this.loading = false
        if (res.success) {
          this.totalCount = res.totalCount
          this.tableData = res.data
        }
      })
    },
    reset () {
      this.query = {
        filter: {
          type: 'UP',
          state: '',
          uid: '',
          nickName: ''
        },
        start: 0,
        limit: 10,
        sort: null
      }
      this.getData()
    },
    handleAccept (row) {
      this.title = '确认通过上分？'
      this.accept = true
      this.row = row
      this.modalConfirm = true
    },
    handleReject (row) {
      this.title = '确认拒绝上分？'
      this.accept = false
      this.row = row
      this.modalConfirm = true
    },
    handleConfirm () {
      if (this.accept) {
        accept(this.row.id, this.notes).then(res => {
          if (res.success) {
            this.modalConfirm = false
            this.getData()
            this.$bvToast.toast('操作成功', {
              title: '提示',
              solid: true,
              variant: 'success',
              autoHideDelay: 3000,
              toaster: 'b-toaster-top-center'
            })
          }
        })
      } else {
        reject(this.row.id, this.notes).then(res => {
          if (res.success) {
            this.modalConfirm = false
            this.getData()
            this.$bvToast.toast('操作成功', {
              title: '提示',
              solid: true,
              variant: 'success',
              autoHideDelay: 3000,
              toaster: 'b-toaster-top-center'
            })
          }
        })
      }
    },
    tableRowClassName ({row, rowIndex}) {
      if (row.state == 1) {
        return 'success-row'
      } else if (row.state == 2) {
        return 'warning-row'
      }
      return ''
    }
  }
};
</script>
<style lang="scss">
.balance {
  .no-border-card .card-footer{
    border-top: 0;
  }
  .el-table .warning-row {
    background-color: #ffe8cc;
  }
  .el-table .success-row {
    background-color: #d3f9d8;
  }
}
</style>
