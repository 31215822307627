import { formFetch, jsonFetch } from '@/utils/fetch'
import Qs from 'qs'

export function list () {
  return formFetch.post('/param/list')
}

export function get (...keys) {
  return jsonFetch.post('/param/get', keys)
}

export function modify (paramMap) {
  return jsonFetch.post(`/param//modify`, paramMap)
}