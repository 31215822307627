export function generateOddsGrid(type) {
  let grid = {
    columns: [],
    tableData: []
  }
  if (type=='pk') {

  } else if (type=='ss') {

  } else if (type=='pc') {

  }
  return grid
}