<template>
  <div>
    <base-header class="pb-6">
      <b-row align-v="center" class="py-4">
        <b-col cols="7" lg="6">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-breadcrumb></route-breadcrumb>
          </nav>
        </b-col>
        <b-col lg="6" cols="5" class="text-right">
        </b-col>
      </b-row>
    </base-header>
    <b-container fluid class="mt--6">
      <div>
        <card class="no-border-card" body-classes="px-0 pb-1" footer-classes="pb-2">
          <div>
            <el-form inline class="pl-3">
              <el-row :gutter="10">
                <el-col :span="8">
                  <el-form-item label="投注时间">
                    <date-range v-model="daterange" mode="button" @change="handleDateRangeChange"></date-range>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item prop="uid" label="UID">
                    <el-input v-model="query.filter.uid" clearable style="width:150px"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item prop="nickName" label="昵称">
                    <el-input v-model="query.filter.nickName" clearable style="width:150px"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="10">
                <el-col :span="8">
                  <el-form-item prop="gameCode" label="游戏彩种">
                    <game-select v-model="query.filter.gameCode" @change="search" clearable></game-select>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item prop="drawIssue" label="期号">
                    <el-input v-model="query.filter.drawIssue" clearable style="width:150px"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item prop="state" label="是否中奖">
                    <el-radio-group v-model="query.filter.state" size="small" @change="search">
                      <el-radio-button label="">全部</el-radio-button>
                      <el-radio-button label="1">是</el-radio-button>
                      <el-radio-button label="2">否</el-radio-button>
                    </el-radio-group>
                  </el-form-item>
                </el-col>
              </el-row>
              <div class="padding-bottom-sm flex justify-between align-center">
                <div>
                  <span>在线投注人数：</span><span :class="'text-'+(statData.orderUser>0?'green':'red')">{{statData.orderUser}}</span>
                  <span class="margin-left">未开奖金额：</span><span :class="'text-'+(statData.orderUser>0?'green':'red')">{{statData.unsettledAmount}}</span>
                  <span class="margin-left">上期输赢：</span><span :class="'text-'+(statData.orderUser>0?'green':'red')">{{statData.preIssueProfit}}</span>
                </div>
                <div class="padding-lr">
                  <base-button type="primary" size="sm" icon class="btn-icon" @click="search">
                    <span><i class="fa fa-search"></i></span>
                    <span>搜索</span>
                  </base-button>
                  <base-button type="secondary" size="sm" icon class="btn-icon" @click="reset">
                    <span><i class="fa fa-mail-reply"></i></span>
                    <span>重置</span>
                  </base-button>
                </div>
              </div>
            </el-form>
            <el-table :data="tableData"
                      ref="table"
                      size="mini"
                      row-key="id"
                      border
                      element-loading-text="加载中..."
                      header-row-class-name="thead-light">
              <el-table-column prop="uid" label="UID" width="100" align="center">
              </el-table-column>
              <el-table-column prop="nickName" label="头像昵称" width="200" align="center">
                <div slot-scope="{$index, row}" class="flex justify-start">
                  <member-info :member="row" readOnly></member-info>
                </div>
              </el-table-column>
              <el-table-column prop="gameName" label="彩种名称" width="150" align="center">
              </el-table-column>
              <el-table-column prop="issue" label="投注期号" width="120" align="center">
              </el-table-column>
              <el-table-column prop="createTime" label="投注时间" width="150" align="center">
              </el-table-column>
              <el-table-column prop="optionName" label="娱乐玩法" align="center">
              </el-table-column>
              <el-table-column prop="betName" label="投注内容" align="center">
              </el-table-column>
              <el-table-column prop="amount" label="投注金额" align="center">
                <template slot-scope="scope">
                  {{scope.row.amount | balance}}
                </template>
              </el-table-column>
              <el-table-column prop="profit" label="中奖金额" align="center">
                <template slot-scope="scope">
                  <span v-if="scope.row.canceled">撤单</span>
                  <span v-else :class="scope.row.profit>=0?'text-green':'text-red'">{{scope.row.profit | balance}}</span>
                </template>
              </el-table-column>
              <el-table-column prop="state" label="状态" align="center">
                <template slot-scope="scope">
                  {{scope.row.state | orderState}}
                </template>
              </el-table-column>
              <el-table-column prop="opened" label="是否开奖" align="center">
                <div slot-scope="{$index, row}" class="flex justify-center">
                  {{ row.opened ? '是' : '否'}}
                </div>
              </el-table-column>
              <el-table-column label="开奖结果" width="300" align="center">
                <template slot-scope="{$index, row}">
                  <draw-code-result v-if="row.drawCode" :gameType="row.gameType" :code="row.drawCode"></draw-code-result>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div slot="footer">
            <base-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :query="query"
              :total="totalCount">
            </base-pagination>
          </div>
        </card>
      </div>
    </b-container>
  </div>
</template>

<script>
import GameSelect from '@/views/Components/game-select'
import DateRange from '@/components/DateRange'
import MemberInfo from '@/views/Components/MemberInfo'
import DrawCodeResult from '@/views/Components/DrawCodeResult'
import { orderList, orderStat } from '@/api/config/monitor'
import dayjs from 'dayjs'
export default {
  components: {
    GameSelect,
    DateRange,
    MemberInfo,
    DrawCodeResult
  },
  data() {
    return {
      loading: false,
      gameList: [],
      quickDate: 'today',
      daterange: ['', ''],
      query: {
        filter: {
          uid: '',
          nickName: '',
          gameCode: '',
          issue: '',
          state: '',
          startDate: dayjs().format('YYYY-MM-DD'),
          endDate: dayjs().format('YYYY-MM-DD')
        },
        start: 0,
        limit: 10,
        sort: null
      },
      totalCount: 0,
      tableData: [],
      statData: {
        orderUser: 0,
        unsettledAmount: 0,
        preIssueProfit: 0
      },
      detailModal: false,
      issue: '',
      timer: null
    };
  },
  mounted () {
    this.getData()
    const that = this
    this.timer = setInterval (function () {
      that.getData()
    }, 5000)
  },
  beforeDestroy () {
    clearInterval(this.timer)
  },
  methods: {
    getData () {
      this.loading = true
      orderStat(this.query).then(res => {
        this.loading = false
        if (res.success) {
          this.statData = res.data
        }
      })
      orderList(this.query).then(res => {
        this.loading = false
        if (res.success) {
          this.totalCount = res.totalCount
          this.tableData = res.data
        }
      })
    },
    search () {
      this.query.start = 0
      this.getData()
    },
    reset () {
      this.query = {
        filter: {
          uid: '',
          nickName: '',
          gameCode: '',
          issue: '',
          state: '',
          startDate: dayjs().format('YYYY-MM-DD'),
          endDate: dayjs().format('YYYY-MM-DD')
        },
        start: 0,
        limit: 10,
        sort: null
      }
      this.getData()
    },
    handleLotteryChange () {
      this.search()
    },
    handleSizeChange () {
      this.search()
    },
    handleCurrentChange () {
      this.getData()
    },
    handleDateRangeChange (val) {
      this.daterange = val.daterange
      this.query.filter.startDate = val.daterange[0]
      this.query.filter.endDate = val.daterange[1]
      this.search()
    }
  }
};
</script>