<template>
  <el-dialog
    v-modal-drag
    width="30%"
    :visible.sync="visible"
    :title="action==='add'?'新增充值类型':'编辑充值类型'"
    @close="close">
    <el-form :model="model" ref="form" label-position="top">
      <el-form-item prop="id" label="ID" required v-if="action==='modify'">
        <el-input v-model="model.id" disabled></el-input>
      </el-form-item>
      <el-form-item prop="title" label="标题" required>
        <el-input v-model="model.title"></el-input>
      </el-form-item>
      <el-form-item label="图片">
        <base-upload
          :img-name="model.img"
          :img-size="30" 
          @success="handleUploadSuccess">
        </base-upload>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <base-button size="sm" type="primary" @click="submit">保存</base-button>
      <base-button size="sm" type="link" class="ml-auto" @click="close">取消</base-button>
    </div>
  </el-dialog>
</template>
<script>
import { detail, create, modify } from '@/api/config/charge'
import BaseUpload from '@/components/BaseUpload'
export default {
  name: 'GameForm',
  props: ['visible', 'action', 'id'],
  components: {
    BaseUpload
  },
  data () {
    return {
      model: {
        id: '',
        title: '',
        img: '',
        displayOrder: 0
      }
    }
  },
  created () {
    let that = this
    if (this.action==='modify') {
      detail(this.id).then(res => {
        if (res.success) {
          this.model = res.data
          if (this.model.img) {
            console.log('this.model.img', this.model.img)
            this.upload.fileList = [{
              name: this.model.img,
              url: this.upload.serverHost + 'static/system/' +this.model.img
            }]
          }
        }
      })
    }
  },
  methods: {
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.action === 'add') {
            create(this.model).then(res => {
              if (res.success) {
                this.close()
                this.$emit('submit')
              }
            })
          } else {
            modify(this.model).then(res => {
              if (res.success) {
                this.close()
                this.$emit('submit')
              }
            })
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    close () {
      this.$emit('update:visible', false)
    },
    handleUploadSuccess (fileName) {
      this.model.img = fileName
    }
  }
}
</script>
<style lang="scss">
.hide-upload-btn .el-upload--picture-card {
  display: none;
}
</style>