<template>    
  <div class="content">
    <base-header class="pb-6">
      <b-row align-v="center" class="py-4">
        <b-col cols="7" lg="6">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-breadcrumb></route-breadcrumb>
          </nav>
        </b-col>
        <b-col lg="6" cols="5" class="text-right">
        </b-col>
      </b-row>
    </base-header>
    <b-container fluid class="mt--6">
      <div>
        <card class="no-border-card" body-classes="px-0 pb-1" footer-classes="pb-2">
          <div>
            <el-form inline class="pl-3">
              <el-form-item label="UID">
                <el-input v-model="query.filter.uid" clearable style="width:150px"></el-input>
              </el-form-item>
              <!-- <el-form-item prop="gameCode" label="彩种" class="margin-left">
                <el-select v-model="query.filter.gameCode" clearable>
                  <el-option v-for="item in gameList" :key="item.id" :value="item.id" :label="item.name"></el-option>
                </el-select>
              </el-form-item> -->
              <el-form-item label="日期">
                <date-range v-model="daterange" @change="handleDateRangeChange"></date-range>
              </el-form-item>
              <!-- <el-form-item label="状态">
                <el-select v-model="query.filter.state" placeholder="" size="small" style="width:110px" @change="getData">
                  <el-option value="" label="全部"></el-option>
                  <el-option value="1" label="待领取"></el-option>
                  <el-option value="2" label="已领取"></el-option>
                  <el-option value="3" label="已过期"></el-option>
                  <el-option value="-1" label="已拒绝"></el-option>
                </el-select>
              </el-form-item> -->
              <el-form-item>
                <base-button type="primary" size="sm" icon class="btn-icon" @click="getData">
                  <span><i class="fa fa-search"></i></span>
                  <span>搜索</span>
                </base-button>
                <base-button type="secondary" size="sm" icon class="btn-icon" @click="reset">
                  <span><i class="fa fa-mail-reply"></i></span>
                  <span>重置</span>
                </base-button>
              </el-form-item>
              <div class="float-right">
              </div>
            </el-form>
            <el-table :data="tableData"
                      size="small"
                      row-key="id"
                      border
                      v-loading="loading"
                      header-row-class-name="thead-light">
              <el-table-column prop="date" label="日期" width="100" align="center" header-align="center"></el-table-column>
              <el-table-column prop="uid" label="UID" width="100" align="center" header-align="center"></el-table-column>
              <el-table-column label="头像昵称" width="200" align="center" header-align="center">
                <template v-slot="{row}">
                  <div class="flex align-start">
                    <member-info :member="row" @saved="getData" readOnly></member-info>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="orderAmount" label="贡献流水" width="100" align="right" header-align="center">
                <div slot-scope="{$index, row}" class="flex justify-center">
                  <el-link type="primary" title="点击查看注单" @click.native="showOrderList(row.uid, row.date, '')">
                    {{row.orderAmount}}
                  </el-link>
                </div>
              </el-table-column>
              <el-table-column prop="childrenCount" label="贡献人数" width="100" align="right" header-align="center"></el-table-column>
              <el-table-column prop="szAmount" label="数字" width="80" align="right" header-align="center">
                <div slot-scope="{$index, row}" class="flex justify-center">
                  <el-link type="primary" title="点击查看注单" @click.native="showOrderList(row.uid, row.date, 'sz')">
                    {{(row.szAmount/row.orderAmount*100).toFixed(0)}}%
                  </el-link>
                </div>
              </el-table-column>
              <el-table-column prop="smAmount" label="双面" width="80" align="right" header-align="center">
                <div slot-scope="{$index, row}" class="flex justify-center">
                  <el-link type="primary" title="点击查看注单" @click.native="showOrderList(row.uid, row.date, 'sm')">
                    {{(row.smAmount/row.orderAmount*100).toFixed(0)}}%
                  </el-link>
                </div>
              </el-table-column>
              <el-table-column prop="tsAmount" label="特殊" width="80" align="right" header-align="center">
                <div slot-scope="{$index, row}" class="flex justify-center">
                  <el-link type="primary" title="点击查看注单" @click.native="showOrderList(row.uid, row.date, 'ts')">
                    {{(row.tsAmount/row.orderAmount*100).toFixed(0)}}%
                  </el-link>
                </div>
              </el-table-column>
              <el-table-column prop="returnAmount" label="返佣额" width="100" align="right" header-align="center"></el-table-column>
              <!-- <el-table-column prop="state" label="状态" width="100" align="center" header-align="center">
                <template v-slot="{row}">
                  <span :class="getStateClass(row.state)">{{ row.state | returnState}}</span>
                </template>
              </el-table-column> -->
            </el-table>
          </div>
          <div slot="footer">
            <base-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :query="query"
              :total="totalCount">
            </base-pagination>
          </div>
        </card>
      </div>
    </b-container>
    <order-detail-list v-if="modal.orderList" :visible.sync="modal.orderList" :parent="uid" :date="date" :type="type"></order-detail-list>
  </div>
</template>
<script>
import MemberInfo from '@/views/Components/MemberInfo'
import DateRange from '@/components/DateRange'
import OrderDetailList from '@/views/Components/order-detail-list'
import { listSettled } from '@/api/finance/return-commission'
export default {
  components: {
    MemberInfo,
    DateRange,
    OrderDetailList
  },
  data() {
    return {
      loading: false,
      daterange: ['', ''],
      query: {
        filter: {
          gameCode: '',
          uid: '',
          state: '',
          startDate: '',
          endDate: ''
        },
        start: 0,
        limit: 10,
        sort: null
      },
      totalCount: 0,
      tableData: [],
      selectedRows: [],
      uid: '',
      date: '',
      type: '',
      modal: {
        orderList: false
      },
      gameList: []
    };
  },
  filters: {
    returnState (value) {
      if (value==0) {
        return '待返水'
      }
      if (value==1) {
        return '待领取'
      }
      if (value==2) {
        return '已领取'
      }
      if (value==3) {
        return '已过期'
      }
      if (value==4) {
        return '已拒绝'
      }
      return value
    }
  },
  created () {
  },
  methods: {
    handleSizeChange () {
      this.getData()
    },
    handleCurrentChange () {
      this.getData()
    },
    handleSortChange ({column, prop, order}) {
      if (order === 'ascending') {
        order = 'asc'
      } else {
        order = 'desc'
      }
      this.query.sort = { prop, order }
      this.getData()
    },
    getData(clear) {
      this.loading = true
      if (clear) {
        this.tableData = []
      }
      listSettled(this.query).then(res => {
        this.loading = false
        if (res.success) {
          this.totalCount = res.totalCount
          this.tableData = res.data
          this.$bvToast.toast('数据已更新', {
            title: '提示',
            solid: true,
            variant: 'success',
            autoHideDelay: 2000,
            toaster: 'b-toaster-top-center'
          })
        }
      })
    },
    reset () {
      this.query = {
        filter: {
          gameCode: '',
          uid: '',
          state: '',
          startDate: this.daterange[0],
          endDate: this.daterange[1]
        },
        start: 0,
        limit: 10,
        sort: null
      }
      this.getData()
    },
    handleDateRangeChange (val) {
      this.daterange = val.daterange
      this.query.filter.startDate = val.daterange[0]
      this.query.filter.endDate = val.daterange[1]
      this.getData()
    },
    getStateClass (state) {
      if (state == 2) {
        return 'text-green'
      }
      if (state == 3) {
        return 'text-red'
      }
      if (state == 4) {
        return 'text-green'
      }
      return ''
    },
    showOrderList(uid, date, type) {
      this.uid = uid
      this.date = date
      this.type = type
      this.modal.orderList = true
    }
  }
};
</script>
<style>
  .no-border-card .card-footer{
    border-top: 0;
  }
</style>
