<template>
  <div>
    <el-dialog
      v-modal-drag
      width="70%"
      :visible.sync="visible"
      :title="'投注记录-'+uid"
      @close="close">
      <div class="content">
        <el-form inline class="flex align-items-center">
          <el-form-item label="游戏">
            <game-select v-model="query.filter.gameCode" @change="handleLotteryChange" clearable></game-select>
          </el-form-item>
          <el-form-item label="日期">
            <date-range v-model="daterange" @change="handleDateRangeChange" :default-range="defaultRange"></date-range>
          </el-form-item>
          <el-form-item>
            <base-button type="primary" size="sm" icon class="btn-icon" @click="getData">
              <span><i class="fa fa-search"></i></span>
              <span>搜索</span>
            </base-button>
          </el-form-item>
        </el-form>
        <el-table :data="tableData"
                  ref="table"
                  size="mini"
                  row-key="id"
                  show-summary
                  :summary-method="getSummaries"
                  v-loading="loading"
                  element-loading-text="加载中..."
                  header-row-class-name="thead-light">
          <el-table-column prop="gameName" label="游戏">
          </el-table-column>
          <el-table-column prop="issue" label="期号">
          </el-table-column>
          <el-table-column prop="orderCount" label="笔数">
          </el-table-column>
          <el-table-column prop="amount" label="流水">
            <div slot-scope="{$index, row}" class="d-flex">
              {{row.amount}}
              <el-tag v-if="row.memberType=='experience'" class="margin-left-sm" type="warning">体验金</el-tag>
            </div>
          </el-table-column>
          <el-table-column prop="profit" label="结果" width="100">
            <template slot-scope="scope">
              <span v-if="scope.row.canceled">撤单</span>
              <span v-else :class="scope.row.profit>=0?'text-green':'text-red'">{{scope.row.profit}}</span>
            </template>
          </el-table-column>
          <el-table-column width="100px" label="操作" fit>
            <div slot-scope="{$index, row}" class="d-flex">
              <b-button-group>
                <base-button
                  @click.native="showDetail(row)"
                  type="info"
                  size="sm"
                  outline
                >
                详情
                </base-button>
              </b-button-group>
            </div>
          </el-table-column>
        </el-table>
        <base-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :query="query"
          :total="totalCount">
        </base-pagination>
      </div>
      <div slot="footer">
      </div>
    </el-dialog>
    <order-detail v-if="detailModal" :visible.sync="detailModal" :uid="uid" :issue="issue"></order-detail>
  </div>
</template>

<script>
import GameSelect from '@/views/Components/game-select'
import DateRange from '@/components/DateRange'
import OrderDetail from './order-detail'
import { orderList } from '@/api/member'
import dayjs from 'dayjs'
export default {
  props: ['visible','uid','defaultRange'],
  components: {
    GameSelect,
    DateRange,
    OrderDetail
  },
  data() {
    return {
      loading: false,
      gameList: [],
      daterange: ['', ''],
      query: {
        filter: {
          uid: this.uid,
          gameCode: '',
          startDate: '',
          endDate: ''
        },
        start: 0,
        limit: 10,
        sort: null
      },
      totalCount: 0,
      tableData: [],
      selectedRows: [],
      detailModal: false,
      issue: ''
    };
  },
  methods: {
    getData () {
      this.loading = true
      orderList(this.query).then(res => {
        this.loading = false
        if (res.success) {
          this.totalCount = res.totalCount
          this.tableData = res.data
        }
      })
    },
    handleLotteryChange () {
      this.getData()
    },
    handleSizeChange () {
      this.getData()
    },
    handleCurrentChange () {
      this.getData()
    },
    close () {
      this.$emit('update:visible', false)
    },
    handleDateRangeChange (val) {
      this.daterange = val.daterange
      this.query.filter.startDate = val.daterange[0]
      this.query.filter.endDate = val.daterange[1]
      this.getData()
    },
    getSummaries (param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        }
        if (!column.property || column.property==='issue') {
          sums[index] = ''
          return
        }
        const values = data.map(item => Number(item[column.property]))
        console.log('column', column)
        if (!values.every(value => isNaN(value))) {
          sums[index] = values
            .reduce((prev, curr) => {
              const value = Number(curr)
              if (!isNaN(value)) {
                return prev + curr
              } else {
                return prev
              }
            }, 0)
            .toFixed(2)
        } else {
          sums[index] = 'N/A'
        }
      })
      return sums
    },
    showDetail (row) {
      this.issue = row.issue
      this.detailModal = true
    }
  }
};
</script>