<template>
  <el-dialog
    v-modal-drag
    width="30%"
    :visible.sync="visible"
    :title="action==='add'?'新增红包':'编辑红包'"
    @close="close">
    <el-form :model="model" ref="form" label-position="top">
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item prop="title" label="名称" required>
            <el-input v-model="model.title" maxLength="20"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop="expireTime" label="过期时间" required>
            <el-date-picker
              v-model="model.expireTime"
              type="datetime"
              value-format="yyyy-MM-dd HH:mm:ss"
              format="yyyy-MM-dd HH:mm:ss">
            </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item prop="sumAmount" label="总金额" required>
            <el-input v-model="model.sumAmount" type="number" min="1"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop="sumTimes" label="总份数" required>
            <el-input v-model="model.sumTimes" type="number" min="1"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item prop="minAmount" label="最小金额" required>
            <el-input v-model="model.minAmount" type="number" min="1"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop="maxAmount" label="最大金额" required>
            <el-input v-model="model.maxAmount" type="number" min="1"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="24">
          <el-form-item prop="description" label="领取规则">
            <el-input v-model="model.description" type="textarea" rows="3" maxlength="100" show-word-limit></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div slot="footer">
      <base-button size="sm" type="primary" @click="submit">保存</base-button>
      <base-button size="sm" type="link" class="ml-auto" @click="close">取消</base-button>
    </div>
  </el-dialog>
</template>
<script>
import dayjs from 'dayjs'
import { detail, create, modify } from '@/api/config/red-pack'
export default {
  name: 'RedPackForm',
  props: ['visible', 'action', 'id'],
  data () {
    return {
      model: {
        id: '',
        title: '',
        sumAmount: '',
        minAmount: '',
        maxAmount: '',
        sumTimes: '',
        expireTime: '',
        description: ''
      }
    }
  },
  created () {
    if (this.action==='modify') {
      detail(this.id).then(res => {
        if (res.success) {
          this.model = res.data
        }
      })
    }
  },
  methods: {
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.action === 'add') {
            create(this.model).then(res => {
              if (res.success) {
                this.close()
                this.$emit('submit')
              }
            })
          } else {
            modify(this.model).then(res => {
              if (res.success) {
                this.close()
                this.$emit('submit')
              }
            })
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    close () {
      this.$emit('update:visible', false)
    }
  }
}
</script>
<style lang="scss">

</style>