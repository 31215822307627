<template>
  <el-dialog
    v-modal-drag
    width="70%"
    :visible.sync="visible"
    :title="'反佣记录-'+uid"
    @close="close">
    <div class="content">
      <el-form inline class="flex align-items-center">
        <el-form-item label="日期">
          <date-range v-model="daterange" @change="handleDateRangeChange"></date-range>
        </el-form-item>
        <el-form-item>
          <base-button type="primary" size="sm" icon class="btn-icon" @click="getData">
            <span><i class="fa fa-search"></i></span>
            <span>搜索</span>
          </base-button>
        </el-form-item>
      </el-form>
      <el-table :data="tableData"
                ref="table"
                size="mini"
                border
                show-summary
                :summary-method="getSummaries"
                row-key="id"
                v-loading="loading"
                element-loading-text="加载中..."
                header-row-class-name="thead-light">
        <el-table-column prop="uid" label="UID" width="100" sortable>
        </el-table-column>
        <el-table-column label="头像昵称" width="300">
          <div slot-scope="{$index, row}" class="flex justify-start">
            <member-info :member="row" @saved="getData"></member-info>
          </div>
        </el-table-column>
        <el-table-column prop="amount" label="贡献金额" width="100" sortable>
          <template slot-scope="scope">
            <span :class="scope.row.amount>=0?'text-green':'text-red'">{{scope.row.amount}}</span>
          </template>
        </el-table-column>
        </el-table-column>
        <el-table-column width="100px" align="center" label="操作" fit>
          <div slot-scope="{$index, row}" class="d-flex">
            <b-button-group>
              <base-button
                @click.native="showModal(row, 'orderList')"
                type="info"
                size="sm"
                outline
              >
              详情
              </base-button>
            </b-button-group>
          </div>
        </el-table-column>
      </el-table>
      <base-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :query="query"
        :total="totalCount">
      </base-pagination>
    </div>
    <div slot="footer">
    </div>
    <order-list v-if="modal.orderList" :visible.sync="modal.orderList" :uid="uid" :default-range="quickDate"></order-list>
  </el-dialog>
</template>

<script>
import MemberInfo from '@/views/Components/MemberInfo'
import DateRange from '@/components/DateRange'
import OrderList from './order-list'
import { commissionHis } from '@/api/member'
import dayjs from 'dayjs'
export default {
  props: ['visible','uid'],
  components: {
    MemberInfo,
    OrderList,
    DateRange
  },
  data() {
    return {
      loading: false,
      quickDate: 'today',
      daterange: ['', ''],
      query: {
        filter: {
          uid: this.uid,
          tradeType: '',
          startDate: dayjs().format('YYYY-MM-DD'),
          endDate: dayjs().format('YYYY-MM-DD')
        },
        start: 0,
        limit: 10,
        sort: null
      },
      totalCount: 0,
      tableData: [],
      selectedRows: [],
      modal: {
        orderList: false
      }
    };
  },
  methods: {
    showModal(row, type) {
      this.uid = row.uid
      this.modal[type] = true
    },
    getData () {
      this.loading = true
      commissionHis(this.query).then(res => {
        this.loading = false
        if (res.success) {
          this.totalCount = res.totalCount
          this.tableData = res.data
        }
      })
    },
    handleSizeChange () {
      this.getData()
    },
    handleCurrentChange () {
      this.getData()
    },
    close () {
      this.$emit('update:visible', false)
    },
    handleDateRangeChange (val) {
      this.daterange = val.daterange
      this.quickDate = val.quickDate
      this.query.filter.startDate = val.daterange[0]
      this.query.filter.endDate = val.daterange[1]
      this.getData()
    },
    getSummaries (param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        }
        if (!column.property || column.property==='issue') {
          sums[index] = ''
          return
        }
        const values = data.map(item => Number(item[column.property]))
        console.log('column', column)
        if (!values.every(value => isNaN(value))) {
          sums[index] = values
            .reduce((prev, curr) => {
              const value = Number(curr)
              if (!isNaN(value)) {
                return prev + curr
              } else {
                return prev
              }
            }, 0)
            .toFixed(2)
        } else {
          sums[index] = 'N/A'
        }
      })
      return sums
    }
  }
};
</script>