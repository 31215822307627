<template>
  <div>
    <el-form :model="model" ref="form" label-position="top">
      <el-form-item prop="username" label="用户名" required>
        <el-input v-model="model.username"/>
      </el-form-item>
      <el-form-item prop="password" label="密码" :required="action==='create'?true:false">
        <el-input v-model="model.password" :placeholder="action==='create'?'':'若不修改请留空'"></el-input>
      </el-form-item>
      <el-form-item prop="serviceGroup" label="客服分组">
        <el-radio v-model="model.serviceGroup" label="online">在线客服</el-radio>
        <el-radio v-model="model.serviceGroup" label="welfare">福利客服</el-radio>
        <el-radio v-model="model.serviceGroup" label="">无</el-radio>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import { detail, create, modify } from '@/api/system/user'
export default {
  name: 'UserForm',
  props: ['action', 'id'],
  data () {
    return {
      model: {
        id: '',
        username: '',
        password: '',
        serviceGroup: ''
      }
    }
  },
  created () {
    if (this.action==='modify') {
      detail(this.id).then(res => {
        if (res.success) {
          this.model = res.data
        }
      })
    } else {
      this.model = {
        id: '',
        username: '',
        password: '',
        serviceGroup: ''
      }
    }
  },
  methods: {
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.action === 'modify') {
            modify(this.model).then(res => {
              if (res.success) {
                this.$emit('submit')
              }
            })
          } else {
            create(this.model).then(res => {
              if (res.success) {
                this.$emit('submit')
              }
            })
          }
        }
      })
    },
  }
}
</script>
