<template>
  <el-dialog
    v-modal-drag
    :visible.sync="visible"
    width="50%"
    :title="action==='add'?'新增话术':'编辑话术'"
    @hide="close">
    <el-form :model="model" ref="form" label-position="top">
      <el-form-item prop="id" label="ID" required v-if="action==='modify'">
        <el-input v-model="model.id" disabled></el-input>
      </el-form-item>
      <el-form-item prop="gameCode" label="彩种" required>
        <game-select v-model="model.gameCode" thirdPart="OWN" @change="handleLotteryChange"></game-select>
      </el-form-item>
      <!-- <el-form-item prop="script" label="话术" required>
        <el-input v-model="model.script" maxlength="50"></el-input>
      </el-form-item> -->
      <el-form-item prop="script" label="话术">
        <el-row :gutter="5">
          <el-col :span="8">
            <el-select v-model="option" placeholder="类型" value-key="option" @change="handleOptionChange">
              <el-option v-for="option in optionList" :value="option" :label="option.label"></el-option>
            </el-select>
          </el-col>
          <el-col :span="8">
            <el-select v-model="bet" placeholder="投注项" value-key="name">
              <el-option v-for="bet in betList" :value="bet" :label="bet.label"></el-option>
            </el-select>
          </el-col>
          <el-col :span="8">
            <el-input v-model="amount" type="number" placeholder="投注金额"></el-input>
          </el-col>
        </el-row>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <base-button size="sm" type="primary" @click="submit">保存</base-button>
      <base-button size="sm" type="link" class="ml-auto" @click="close">取消</base-button>
    </div>
  </el-dialog>
</template>
<script>
import GameSelect from '@/views/Components/game-select'
import { detail, create, modify, listBetOption } from '@/api/config/robot-script'
export default {
  name: 'RobotForm',
  props: ['visible', 'action', 'id'],
  components: {
    GameSelect
  },
  data () {
    return {
      model: {
        id: '',
        gameCode: '',
        script: '',
      },
      option: {},
      optionList: [],
      bet: {},
      betList: [],
      amount: 0
    }
  },
  created () {
    if (this.action==='modify') {
      detail(this.id).then(res => {
        if (res.success) {
          this.model = res.data
        }
      })
    }
  },
  methods: {
    handleLotteryChange (gameCode) {
      listBetOption(gameCode).then(res => {
        if (res.success) {
          this.optionList = res.data
        }
      })
    },
    handleOptionChange (option) {
      console.log('handleOptionChange', option)
      for (var i=0;i<this.optionList.length;i++) {
        if (this.optionList[i].option==option.option) {
          this.betList = this.optionList[i].betOdds
          break
        }
      }
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (!this.option.option) {
            this.$bvToast.toast('请选择类型', {
              title: '提示',
              solid: true,
              variant: 'warning',
              autoHideDelay: 3000,
              toaster: 'b-toaster-top-center'
            })
            return
          }
          if (!this.bet.name) {
            this.$bvToast.toast('请选择投注项', {
              title: '提示',
              solid: true,
              variant: 'warning',
              autoHideDelay: 3000,
              toaster: 'b-toaster-top-center'
            })
            return
          }
          if (this.amount<=0) {
            this.$bvToast.toast('请选择投注金额', {
              title: '提示',
              solid: true,
              variant: 'warning',
              autoHideDelay: 3000,
              toaster: 'b-toaster-top-center'
            })
            return
          }
          this.model.script = this.option.label + '/' + this.bet.label + '/' + this.amount
          if (this.action === 'add') {
            create(this.model).then(res => {
              if (res.success) {
                this.close()
                this.$emit('submit')
              }
            })
          } else {
            modify(this.model).then(res => {
              if (res.success) {
                this.close()
                this.$emit('submit')
              }
            })
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    close () {
      this.$emit('update:visible', false)
    },
  }
}
</script>
<style lang="scss">

</style>