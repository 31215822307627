<template>
  <el-dialog
    v-modal-drag
    width="30%"
    :visible.sync="visible"
    :title="'修改分数-'+uid"
    @close="close">
    <el-form :model="model" ref="form">
      <el-form-item prop="uid" label="UID">
        <el-input v-model="uid" disabled></el-input>
      </el-form-item>
      <el-form-item prop="balance" label="会员余分">
        <el-input v-model="model.balance" disabled></el-input>
      </el-form-item>
      <el-form-item prop="action" label="积分类型">
        <el-radio-group v-model="model.action">
          <el-radio label="add">加分</el-radio>
          <el-radio label="reduce">减分</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item prop="amount" :label="model.action==='add'?'加多少分':'减多少分'" required>
        <el-input v-model="model.amount" type="number" :min="1"></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <base-button size="sm" type="primary" @click="submit">保存</base-button>
      <base-button size="sm" type="link" class="ml-auto" @click="close">取消</base-button>
    </div>
  </el-dialog>
</template>
<script>
import { find, changeBalance } from '@/api/member'
export default {
  name: 'ChangeBalance',
  props: ['visible','uid'],
  data () {
    return {
      loading: false,
      model: {
        balance: 0,
        action: 'add',
        amount: 0
      }
    }
  },
  computed: {
    amount () {
      if (this.model.action==='add') {
        return this.model.amount
      } else {
        return this.model.amount * -1
      }
    }
  },
  watch: {
    'uid': {
      handler: function (val) {
        if (val) {
          this.getData()
        }
      },
      immediate: true
    }
  },
  methods: {
    getData() {
      find(this.uid).then(res => {
        if (res.success) {
          this.model.balance = res.data.balance
        }
      })
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          changeBalance(this.uid, this.amount).then(res => {
            if (res.success) {
              this.close()
              this.$emit('submit')
            }
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    close () {
      this.$emit('update:visible', false)
    }

  }
}
</script>