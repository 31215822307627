import { formFetch, jsonFetch } from '@/utils/fetch'
import Qs from 'qs'

export function list () {
  return formFetch.post('/role/list')
}

export function switchStatus (id, available) {
  return formFetch.post(`/role/${id}/status`, Qs.stringify({ available }))
}

export function detail (id) {
  return formFetch.post(`/role/${id}/detail`)
}

export function create (role) {
  return jsonFetch.post(`/role/create`, role)
}

export function modify (role) {
  return jsonFetch.post(`/role/${role.id}/modify`, role)
}

export function del (id) {
  return formFetch.post(`/role/${id}/delete`)
}

export function selectMenu (id) {
  return formFetch.post(`/role/${id}/select-menu`)
}

export function grantMenu (roleId, menus) {
  return jsonFetch.post(`/role/${roleId}/grant-menu`, menus)
}