import { formFetch, jsonFetch } from '@/utils/fetch'
import Qs from 'qs'

export function list (query) {
  return jsonFetch.post('/robotscript/list', query)
}

export function switchStatus (id, available) {
  return formFetch.post(`/robotscript/${id}/status`, Qs.stringify({ available }))
}

export function detail (id) {
  return formFetch.post(`/robotscript/${id}/detail`)
}

export function create (robotscript) {
  return jsonFetch.post(`/robotscript/create`, robotscript)
}

export function modify (robotscript) {
  return jsonFetch.post(`/robotscript/${robotscript.id}/modify`, robotscript)
}

export function del (id) {
  return formFetch.post(`/robotscript/${id}/delete`)
}

export function listBetOption (gameCode) {
  return formFetch.post('/robotscript/listBetOption', Qs.stringify({ gameCode }))
}