<template>
  <div class="ss">
    <base-button
      class="save-btn"
      @click.native="save"
      type="primary"
      size="sm"
      icon
    >
      <i class="fa fa-check"></i>保存
    </base-button>
    <el-tabs v-model="activeName" type="border-card">
      <el-tab-pane label="1-5球" name="lane">
        <el-table :data="conf.lane"
            v-loading="loading"
            size="mini"
            border
            fit
            header-row-class-name="thead-light">
          <el-table-column
            prop="option" label="球" width="50" align="center">
          </el-table-column>
          <el-table-column
            v-for="typeColumn in columnsLane"
            :key="typeColumn.type"
            :label-class-name="typeColumn.className"
            align="center">
            <div slot="header" class="padding-sm">
              <b-button
                :id="'edit-btn-'+typeColumn.type"
                variant="outline-primary"
                size="sm"
                icon
              >
                <i class="fa fa-edit"></i>批量修改
              </b-button>
            </div>
            <el-table-column
              v-for="column in typeColumn.columns"
              :key="column.prop"
              width="65"
              :prop="column.prop"
              :label="column.label"
              :label-class-name="column.className"
              align="center">
              <template v-slot="{row}">
                <el-input v-if="column.prop in row" size="mini" maxlength="5" v-model="row[column.prop]"></el-input>
              </template>
            </el-table-column>
          </el-table-column>
        </el-table>
      </el-tab-pane>
      <el-tab-pane label="总和 / 龙虎 / 特殊" name="other">
        <div class="text-center padding">
          <b-button
            id="edit-btn-other"
            variant="outline-primary"
            size="sm"
            icon
          >
            <i class="fa fa-edit"></i>批量修改
          </b-button>
        </div>
        <el-form label-position="right" label-width="50px">
          <el-row class="flex align-center">
            <el-col :span="2">
              <span class="text-bold">总和</span>
            </el-col>
            <el-col :span="4">
              <el-form-item label="大"><el-input size="mini" v-model="conf.sum['big']"></el-input></el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item label="小"><el-input size="mini" v-model="conf.sum['small']"></el-input></el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item label="单"><el-input size="mini" v-model="conf.sum['single']"></el-input></el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item label="双"><el-input size="mini" v-model="conf.sum['double']"></el-input></el-form-item>
            </el-col>  
          </el-row>
          <el-row class="flex align-center">
            <el-col :span="2">
              <span class="text-bold">1vs5龙虎</span>
            </el-col>
            <el-col :span="4">
              <el-form-item label="龙"><el-input size="mini" v-model="conf.dt['dragon']"></el-input></el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item label="虎"><el-input size="mini" v-model="conf.dt['tiger']"></el-input></el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item label="和"><el-input size="mini" v-model="conf.dt['equal']"></el-input></el-form-item>
            </el-col>
            <el-col :span="4">
            </el-col>  
          </el-row>
          <el-row class="flex align-center">
            <el-col :span="2">
              <span class="text-bold">前三</span>
            </el-col>
            <el-col :span="4">
              <el-form-item label="豹子"><el-input size="mini" v-model="conf.behindThree['4']"></el-input></el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item label="顺子"><el-input size="mini" v-model="conf.behindThree['2']"></el-input></el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item label="对子"><el-input size="mini" v-model="conf.behindThree['3']"></el-input></el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item label="半顺"><el-input size="mini" v-model="conf.behindThree['1']"></el-input></el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item label="杂六"><el-input size="mini" v-model="conf.behindThree['0']"></el-input></el-form-item>
            </el-col>
          </el-row>
          <el-row class="flex align-center">
            <el-col :span="2">
              <span class="text-bold">中三</span>
            </el-col>
            <el-col :span="4">
              <el-form-item label="豹子"><el-input size="mini" v-model="conf.betweenThree['4']"></el-input></el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item label="顺子"><el-input size="mini" v-model="conf.betweenThree['2']"></el-input></el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item label="对子"><el-input size="mini" v-model="conf.betweenThree['3']"></el-input></el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item label="半顺"><el-input size="mini" v-model="conf.betweenThree['1']"></el-input></el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item label="杂六"><el-input size="mini" v-model="conf.betweenThree['0']"></el-input></el-form-item>
            </el-col>
          </el-row>
          <el-row class="flex align-center">
            <el-col :span="2">
              <span class="text-bold">后三</span>
            </el-col>
            <el-col :span="4">
              <el-form-item label="豹子"><el-input size="mini" v-model="conf.lastThree['4']"></el-input></el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item label="顺子"><el-input size="mini" v-model="conf.lastThree['2']"></el-input></el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item label="对子"><el-input size="mini" v-model="conf.lastThree['3']"></el-input></el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item label="半顺"><el-input size="mini" v-model="conf.lastThree['1']"></el-input></el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item label="杂六"><el-input size="mini" v-model="conf.lastThree['0']"></el-input></el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-tab-pane>
    </el-tabs>
    <b-popover target="edit-btn-num" triggers="click blur">
      <template #title>
        <b-button @click="hideBatchEdit" class="close" aria-label="Close">
          <span></span>
        </b-button>
        批量修改
      </template>
      <b-input-group>
        <b-form-input v-model="globalVal"></b-form-input>
        <b-input-group-append>
          <b-button size="sm" variant="outline-primary" @click="submitBatchEdit('num')">确定</b-button>
        </b-input-group-append>
      </b-input-group>
    </b-popover>
    <b-popover target="edit-btn-sm" triggers="click blur">
      <template #title>
        <b-button @click="hideBatchEdit" class="close" aria-label="Close">
          <span></span>
        </b-button>
        批量修改
      </template>
      <b-input-group>
        <b-form-input v-model="globalVal"></b-form-input>
        <b-input-group-append>
          <b-button size="sm" variant="outline-primary" @click="submitBatchEdit('sm')">确定</b-button>
        </b-input-group-append>
      </b-input-group>
    </b-popover>
    <b-popover target="edit-btn-other" triggers="click blur">
      <template #title>
        <b-button @click="hideBatchEdit" class="close" aria-label="Close">
          <span></span>
        </b-button>
        批量修改
      </template>
      <b-input-group>
        <b-form-input v-model="globalVal"></b-form-input>
        <b-input-group-append>
          <b-button size="sm" variant="outline-primary" @click="submitBatchEdit('other')">确定</b-button>
        </b-input-group-append>
      </b-input-group>
    </b-popover>
  </div>
</template>

<script>
const initOdds = {
  lane: [
    {'option': '1', '0': 0, '1': 0, '2': 0, '3': 0, '4': 0, '5': 0, '6': 0, '7': 0, '8': 0, '9': 0, 'big': 0, 'small': 0, 'single': 0, 'double': 0},
    {'option': '2', '0': 0, '1': 0, '2': 0, '3': 0, '4': 0, '5': 0, '6': 0, '7': 0, '8': 0, '9': 0, 'big': 0, 'small': 0, 'single': 0, 'double': 0},
    {'option': '3', '0': 0, '1': 0, '2': 0, '3': 0, '4': 0, '5': 0, '6': 0, '7': 0, '8': 0, '9': 0, 'big': 0, 'small': 0, 'single': 0, 'double': 0},
    {'option': '4', '0': 0, '1': 0, '2': 0, '3': 0, '4': 0, '5': 0, '6': 0, '7': 0, '8': 0, '9': 0, 'big': 0, 'small': 0, 'single': 0, 'double': 0},
    {'option': '5', '0': 0, '1': 0, '2': 0, '3': 0, '4': 0, '5': 0, '6': 0, '7': 0, '8': 0, '9': 0, 'big': 0, 'small': 0, 'single': 0, 'double': 0}
  ],
  sum: {'option': 'sum', 'big':0, 'small':0, 'single':0, 'double':0},
  dt: {'option': 'dt', 'dragon':0, 'tiger':0, 'equal':0},
  // 0=杂六 1=半顺 2=顺子 3=对子 4=豹子
  behindThree: {'option': 'behindThree', '0':0, '1':0, '2':0, '3':0, '4':0},
  betweenThree: {'option': 'betweenThree', '0':0, '1':0, '2':0, '3':0, '4':0},
  lastThree: {'option': 'lastThree', '0':0, '1':0, '2':0, '3':0, '4':0},
}
import TitleBar from '@/components/TitleBar'
import { list, save } from '@/api/config/odds'
export default {
  props: ['gameCode'],
  components: {
    TitleBar
  },
  data () {
    return {
      loading: false,
      columnsLane: [  //1-5球
        {
          type: 'num',
          className: 'bg-num',
          columns: [
            {label:'0', prop:'0', className: 'bg-num'},
            {label:'1', prop:'1', className: 'bg-num'},
            {label:'2', prop:'2', className: 'bg-num'},
            {label:'3', prop:'3', className: 'bg-num'},
            {label:'4', prop:'4', className: 'bg-num'},
            {label:'5', prop:'5', className: 'bg-num'},
            {label:'6', prop:'6', className: 'bg-num'},
            {label:'7', prop:'7', className: 'bg-num'},
            {label:'8', prop:'8', className: 'bg-num'},
            {label:'9', prop:'9', className: 'bg-num'},
          ]
        },
        {
          type: 'sm',
          className: 'bg-sm',
          columns: [
            {label:'大', prop:'big', className: 'bg-sm'},
            {label:'小', prop:'small', className: 'bg-sm'},
            {label:'单', prop:'single', className: 'bg-sm'},
            {label:'双', prop:'double', className: 'bg-sm'}
          ]
        },
      ],
      conf: initOdds,
      activeName: 'lane',
      globalVal: 0
    }
  },
  computed: {
    config() {
      return this.conf.lane.concat(this.conf.sum)
        .concat(this.conf.dt)
        .concat(this.conf.behindThree)
        .concat(this.conf.betweenThree)
        .concat(this.conf.lastThree)
    }
  },
  watch: {
    'gameCode': {
      handler (val) {
        this.loading = true
        this.conf = initOdds
        list(val).then(res => {
          this.loading = false
          if (res.success) {
            if (res.data) {
              this.conf = res.data
            }
          }
        })
      },
      immediate: true
    }
  },
  methods: {
    save () {
      save(this.gameCode, this.config).then(res => {
        if (res.success) {
          this.$bvToast.toast('操作成功', {
            title: '提示',
            solid: true,
            variant: 'success',
            autoHideDelay: 3000,
            toaster: 'b-toaster-top-center'
          })
        }
      })
    },
    hideBatchEdit () {
      this.$root.$emit('bv::hide::popover')
    },
    submitBatchEdit (type) {
      if (type=='num') {
        this.conf.lane.forEach(row => {
          for (let key in row) {
            if (key=='0' || key=='1' || key=='2' || key=='3' || key=='4' || key=='5' || key=='6' || key=='7' || key=='8' || key=='9') {
              row[key] = this.globalVal
            }
          }
        })
      } else if (type=='sm') {
        this.conf.lane.forEach(row => {
          for (let key in row) {
            if (key=='big' || key=='small' || key=='single' || key=='double') {
              row[key] = this.globalVal
            }
          }
        })
      } else if (type=='other') {
        for (let key in this.conf.sum) {
          if (key!='option') {
            this.conf.sum[key] = this.globalVal
          }
        }
        for (let key in this.conf.dt) {
          if (key!='option') {
            this.conf.dt[key] = this.globalVal
          }
        }
        for (let key in this.conf.behindThree) {
          if (key!='option') {
            this.conf.behindThree[key] = this.globalVal
          }
        }
        for (let key in this.conf.betweenThree) {
          if (key!='option') {
            this.conf.betweenThree[key] = this.globalVal
          }
        }
        for (let key in this.conf.lastThree) {
          if (key!='option') {
            this.conf.lastThree[key] = this.globalVal
          }
        }
      }
      this.hideBatchEdit()
    }
  }
}
</script>

<style lang="scss">
.ss {
  .el-input {
    width: 65px;
  }
  .save-btn {
    position: absolute;
    z-index: 100;
    top: 95px;
    right: 16px
  }
  .el-form-item {
    margin-bottom: 0;
  }
  .el-table__cell {
    padding: 0;
  }
  .bg-num {
    background-color: rgb(250, 236, 216);
  }
  .bg-sm {
    background-color: rgb(225, 243, 216);
  }
}
</style>