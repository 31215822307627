<template>
  <el-dialog
    class="carousel-form"
    v-modal-drag
    width="30%"
    :visible.sync="visible"
    :title="action==='add'?'新增轮播图':'编辑轮播图'"
    @close="close">
    <el-form :model="model" ref="form" label-position="top">
      <el-form-item prop="id" label="ID" required v-if="action==='modify'">
        <el-input v-model="model.id" disabled></el-input>
      </el-form-item>
      <el-form-item prop="title" label="标题" required>
        <el-input v-model="model.title"></el-input>
      </el-form-item>
      <el-form-item prop="link" label="链接" required>
        <el-input v-model="model.link"></el-input>
      </el-form-item>
      <el-form-item label="图片 建议大小:390 x 150">
        <base-upload
          :img-name="model.img"
          :img-size="100" 
          @success="handleUploadSuccess">
        </base-upload>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <base-button size="sm" type="primary" @click="submit">保存</base-button>
      <base-button size="sm" type="link" class="ml-auto" @click="close">取消</base-button>
    </div>
  </el-dialog>
</template>
<script>
import { detail, create, modify } from '@/api/config/carousel'
import BaseUpload from '@/components/BaseUpload'
export default {
  name: 'GameForm',
  props: ['visible', 'action', 'id'],
  components: {
    BaseUpload
  },
  data () {
    return {
      model: {
        id: '',
        title: '',
        link: '',
        img: '',
        displayOrder: 0
      }
    }
  },
  created () {
    let that = this
    if (this.action==='modify') {
      detail(this.id).then(res => {
        if (res.success) {
          this.model = res.data
        }
      })
    }
  },
  methods: {
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.action === 'add') {
            create(this.model).then(res => {
              if (res.success) {
                this.close()
                this.$emit('submit')
              }
            })
          } else {
            modify(this.model).then(res => {
              if (res.success) {
                this.close()
                this.$emit('submit')
              }
            })
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    close () {
      this.$emit('update:visible', false)
    },
    handleUploadSuccess (fileName) {
      this.model.img = fileName
    }
  }
}
</script>
<style lang="scss">
.carousel-form {
  .el-upload--picture-card,
  .el-upload-list--picture-card .el-upload-list__item {
    width: 390px;
    height: 150px;
  }
}
</style>