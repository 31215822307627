<template>
  <div>
    <span v-if="label" class="margin-right-sm">{{label}}</span>
    <el-select v-if="value && typeof(value)==='object'" v-model="game" value-key="id" @change="handleChange" :clearable="clearable">
      <el-option v-for="item in gameList" :key="item.code" :value="item" :label="item.code+'-'+item.name">
        <div class="flex align-center justify-between">
          <span>{{ item.code }}-{{ item.name }}</span>
          <div>
            <el-tag v-show="item.self" type="success" class="margin-left-sm">自开</el-tag>
            <el-tag type="warning" class="margin-left-sm">{{ item.type }}</el-tag>
          </div>
        </div>
      </el-option>
    </el-select>
    <el-select v-else v-model="game.code" @change="handleChange" :clearable="clearable">
      <el-option v-for="item in gameList" :key="item.code" :value="item.code" :label="item.code+'-'+item.name">
        <div class="flex align-center justify-between">
          <span>{{ item.code }}-{{ item.name }}</span>
          <div>
            <el-tag v-show="item.self" type="success" class="margin-left-sm">自开</el-tag>
            <el-tag type="warning" class="margin-left-sm">{{ item.type }}</el-tag>
          </div>
        </div>
      </el-option>
    </el-select>
  </div>
</template>
<script>
import { list } from '@/api/config/game'
export default {
  props: {
    label: String,
    thirdPart: String,
    clearable: {
      type: Boolean,
      default: false
    },
    autoSelectFirst: {
      type: Boolean,
      default: false
    },
    value: {
      type: [Object, String, Number],
      default: () => ({})
    }
  },
  data () {
    return {
      query: {
        filter: {
          thirdPart: '',
          code: '',
          name: '',
          self: '',
          type: '',
          platType: ''
        },
        start: 0,
        limit: 0,
        sort: null
      },
      game: {},
      gameList: []
    }
  },
  watch: {
    value: {
      handler: function (val) {
        if (!val) {
          return
        }
        if (typeof(val) === 'Object') {
          this.game = val
        } else {
          this.selectItem()
        }
      },
      immediate: true
    },
    'thirdPart': {
      handler: function (val) {
        this.query.filter.thirdPart = val
      },
      immediate: true
    }
  },
  created () {
    list(this.query).then(res => {
      if (res.success) {
        this.gameList = res.data
        if (!this.game.code) {
          this.selectItem()
        }
        if (this.autoSelectFirst) {
          this.selectFirst()
        }
      }
    })
  },
  methods: {
    handleChange (val) {
      console.log('handleChange', val)
      this.$emit('input', val)
      this.$emit('change', val)
    },
    selectItem () {
      if (!this.game.code) {
        for (var i=0; i<this.gameList.length; i++) {
          let game = this.gameList[i]
          if (game.code==this.value) {
            this.game = game
            console.log('seleced', this.value)
            break
          }
        }
      }
    },
    selectFirst () {
      if (this.gameList.length>0) {
        this.game = this.gameList[0]
      }
    }
  }
}
</script>