<template>
  <div class="date-range">
    <el-date-picker
      v-show="mode=='full' || mode=='picker'"
      v-model="daterange"
      type="daterange"
      value-format="yyyy-MM-dd"
      format="yyyy-MM-dd"
      @change="handleDateRangeChange"
      range-separator="~"
      start-placeholder="开始日期"
      end-placeholder="结束日期">
    </el-date-picker>
    <el-radio-group v-show="mode=='full' || mode=='button'" v-model="quickDate" style="margin-left:10px" size="small">
      <el-radio-button label="all">全部</el-radio-button>
      <el-radio-button label="today">今天</el-radio-button>
      <el-radio-button label="yestoday">昨天</el-radio-button>
      <el-radio-button label="thisWeek">本周</el-radio-button>
      <el-radio-button label="lastWeek">上周</el-radio-button>
    </el-radio-group>
  </div>
</template>
<script>
import dayjs from 'dayjs'
export default {
  props: {
    value: {
      type: Array,
      default: ()=>[]
    },
    mode: {
      type: String,
      default: 'full'
    },
    defaultRange: {
      type: String,
      default: 'today'
    }
  },
  data() {
    return {
      quickDate: 'today',
      daterange: [dayjs().format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD')],
    }
  },
  watch: {
    defaultRange: {
      handler: function(val) {
        this.quickDate = val
      },
      immediate: true
    },
    quickDate: {
      handler: function(val) {
        let startDate, endDate
        if (val === 'today') {
          startDate = dayjs().format('YYYY-MM-DD')
          endDate = dayjs().format('YYYY-MM-DD')
        } else if (val === 'yestoday') {
          startDate = dayjs().subtract(1, 'day').format('YYYY-MM-DD')
          endDate = dayjs().subtract(1, 'day').format('YYYY-MM-DD')
        } else if (val === 'thisWeek') {
          startDate = dayjs().startOf('week').add(1, 'day').format('YYYY-MM-DD')
          endDate = dayjs().endOf('week').add(1, 'day').format('YYYY-MM-DD')
        } else if (val === 'lastWeek') {
          startDate = dayjs().add(-1, 'week').startOf('week').add(1, 'day').format('YYYY-MM-DD')
          endDate = dayjs().add(-1, 'week').endOf('week').add(1, 'day').format('YYYY-MM-DD')
        } else {
          startDate = ''
          endDate = ''
        }
        this.daterange = [startDate, endDate]
        this.handleDateRangeChange(this.daterange)
      },
      immediate: true
    }
  },
  methods: {
    handleDateRangeChange (val) {
      console.log('handleDateRangeChange', val)
      this.$emit('change', {daterange: this.daterange, quickDate: this.quickDate})
    }
  }
}
</script>
<style lang="scss">
.date-range {
  .el-radio-button--small .el-radio-button__inner {
    padding: 9px 8px;
  }
}
</style>
