<template>
  <el-dialog
    class="announcement-form"
    width="60%"
    :visible.sync="visible"
    :title="action==='add'?'新增公告':'编辑公告'"
    @close="close">
    <el-form :model="model" ref="form" label-position="top">
      <el-form-item prop="id" label="ID" required v-if="action==='modify'">
        <el-input v-model="model.id" disabled></el-input>
      </el-form-item>
      <el-form-item prop="title" label="标题" required>
        <el-input v-model="model.title"></el-input>
      </el-form-item>
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item prop="popup" label="是否弹窗" required>
            <base-switch 
              v-model="model.popup" on-text="是" off-text="否" type="success" size="sm">
            </base-switch>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop="popupTimes" label="弹窗次数(0代表永久弹窗，1以上是次数)" required>
            <el-input-number v-model="model.popupTimes" :min="0"></el-input-number>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item prop="content" label="公告内容" required>
        <html-editor v-model="model.content"></html-editor>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <base-button size="sm" type="primary" @click="submit">保存</base-button>
      <base-button size="sm" type="link" class="ml-auto" @click="close">取消</base-button>
    </div>
  </el-dialog>
</template>
<script>
import { detail, create, modify } from '@/api/config/announcement'
import HtmlEditor from '@/components/Inputs/HtmlEditor'
export default {
  name: 'AnnouncementForm',
  props: ['visible', 'action', 'id'],
  components: {
    HtmlEditor
  },
  data () {
    return {
      model: {
        id: '',
        title: '',
        content: '',
        popup: false,
        popupTimes: 0,
        displayOrder: 0
      }
    }
  },
  created () {
    let that = this
    if (this.action==='modify') {
      detail(this.id).then(res => {
        if (res.success) {
          this.model = res.data
        }
      })
    }
  },
  methods: {
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.action === 'add') {
            create(this.model).then(res => {
              if (res.success) {
                this.close()
                this.$emit('submit')
              }
            })
          } else {
            modify(this.model).then(res => {
              if (res.success) {
                this.close()
                this.$emit('submit')
              }
            })
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    close () {
      this.$emit('update:visible', false)
    }
  }
}
</script>
<style lang="scss">
.announcement-form {
  .el-upload--picture-card,
  .el-upload-list--picture-card .el-upload-list__item {
    width: 390px;
    height: 150px;
  }
}
</style>