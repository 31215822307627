<template>
  <el-dialog
    v-modal-drag
    :title="'管理游戏 - '+category.label"
    :visible.sync="visible"
    width="70%"
    :before-close="close">
    <div class="flex justify-between">
      <div style="width:50%">
        <span class="text-bold">可选</span>
        <el-radio-group v-model="thirdPart" style="margin-left:10px" size="mini">
          <el-radio-button label="OWN">自有</el-radio-button>
          <el-radio-button label="NG">NG</el-radio-button>
        </el-radio-group>
        <el-table :data="availableList"
          size="mini"
          row-key="id"
          max-height="500"
          border
          v-loading="loading"
          header-row-class-name="thead-light">
          <el-table-column prop="id" label="ID" width="120" align="center" header-align="center">
            <template v-slot="{row}">
              {{row.id}}
            </template>
          </el-table-column>
          <el-table-column prop="type" label="类型" width="150" align="center" header-align="center">
            <template v-slot="{row}">
              <div>{{row.type}}<el-tag v-if="row.self" type="success">自开</el-tag></div>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="名称" align="center" header-align="center"></el-table-column>
          <el-table-column width="120px" align="center" label="操作">
            <div slot-scope="{$index, row}" class="flex justify-center">
              <base-button
                @click.native="handleAdd(row)"
                type="primary"
                size="sm"
                outline
              >
                <i class="el-icon-d-arrow-right"></i>添加
              </base-button>
            </div>
          </el-table-column>
        </el-table>
      </div>
      <div style="width:50%;margin-left:20px">
        <span class="text-bold">已选</span>
        <el-table :data="selectList"
                  size="mini"
                  row-key="id"
                  max-height="500"
                  border
                  v-loading="loading"
                  header-row-class-name="thead-light"
                  id="game-table">
            <el-table-column prop="id" label="ID" width="120" align="center" header-align="center">
              <template v-slot="{row}">
              <span><i class="el-icon-sort margin-right drag-column" style="cursor:move" title="按住拖动排序"></i></span>
              {{row.id}}
            </template>
          </el-table-column>
          <el-table-column prop="type" label="类型" width="150" align="center" header-align="center">
            <template v-slot="{row}">
              <div>{{row.type}}<el-tag v-if="row.self" type="success">自开</el-tag></div>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="名称" align="center" header-align="center"></el-table-column>
          <el-table-column width="120px" align="center" label="操作">
            <div slot-scope="{$index, row}" class="flex justify-center">
              <base-button
                @click.native="handleDelete($index)"
                type="danger"
                size="sm"
                outline
              >
                <i class="fa fa-trash"></i>删除
              </base-button>
            </div>
          </el-table-column>
        </el-table>
      </div>     
    </div>
    <div slot="footer">
      <base-button size="sm" type="primary" outline @click="getData">刷新</base-button>
      <base-button size="sm" type="primary" @click="submit">保存</base-button>
      <base-button size="sm" type="link" class="ml-auto" @click="close">取消</base-button>
    </div>
  </el-dialog>
</template>
<script>
import Sortable from 'sortablejs'
import { Modal } from '@/components';
import { list } from '@/api/config/game'
import { listGame, saveGame } from '@/api/config/category'
export default {
  name: 'CategoryForm',
  props: ['visible', 'category'],
  components: {
    Modal
  },
  data () {
    return {
      loading: false,
      query: {
        filter: {
          id: '',
          thirdPart: '',
          platType: '',
          code: '',
          name: '',
          self: '',
          type: ''
        },
        start: 0,
        limit: 0,
        sort: null
      },
      totalCount: 0,
      thirdPart: 'OWN',
      gameList: [],
      selectList: [],
      categoryGames: []
    }
  },
  computed: {
    availableList () {
      const that = this
      return this.gameList.filter(game => {
        console.log('game.thirdPart='+game.thirdPart+' that.thirdPart='+that.thirdPart)
        return game.thirdPart==that.thirdPart && that.categoryGames.indexOf(game.id)<0
      })
    }
  },
  created () {
    this.getData()
  },
  methods: {
    getData() {
      this.gameList = []
      this.selectList = []
      this.categoryGames = []
      this.loading = true
      list(this.query).then(res => {
        this.loading = false
        if (res.success) {
          this.totalCount = res.totalCount
          this.gameList = res.data
          this.loading = true
          listGame(this.category.id).then(res => {
            this.loading = false
            if (res.success) {
              this.categoryGames = res.data
              this.categoryGames.forEach(gameId => {
                for (let i=0;i<this.gameList.length;i++) {
                  const game = this.gameList[i]
                  if (game.id==gameId) {
                    this.selectList.push(game)
                  }
                }
              })
              this.$nextTick(() => {
                this.initSortable()
              })
            }
          }) 
        }
      })
    },
    initSelect () {

    },
    handleAdd(row) {
      console.log(row)
      if (this.categoryGames.indexOf(row.id)<0) {
        this.selectList.push(row)
        this.categoryGames.push(row.id)
      }
    },
    handleDelete(index) {
      let row = this.selectList[index]
      this.selectList.splice(index, 1)
      this.categoryGames.splice(this.categoryGames.indexOf(row.id), 1)
    },
    submit() {
      saveGame(this.category.id, this.categoryGames).then(res => {
        this.$bvToast.toast('操作成功', {
          title: '提示',
          solid: true,
          variant: 'success',
          autoHideDelay: 3000,
          toaster: 'b-toaster-top-center'
        })
      })
    },
    close () {
      this.$emit('update:visible', false)
    },
    initSortable () {
      const tbody = document.querySelector('#game-table .el-table__body-wrapper tbody')
      const that = this
      Sortable.create(tbody, {
        handle: ".drag-column",
        onEnd({ newIndex, oldIndex }) {
          const currRow = that.selectList.splice(oldIndex, 1)[0]
          that.selectList.splice(newIndex, 0, currRow)
          that.categoryGames = []
          that.selectList.forEach(record => {
            that.categoryGames.push(record.id)
          })
        }
      })
    }
  }
}
</script>
<style lang="scss">
</style>