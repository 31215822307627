import { formFetch, jsonFetch } from '@/utils/fetch'
import Qs from 'qs'

// 期号列表
export function list (query) {
  return jsonFetch.post('/issue/list', query)
}
// 手动预开奖
export function preOpen (issue) {
  return jsonFetch.post('/issue/preOpen', issue)
}
// 撤销预开奖
export function cancelOpen (issue) {
  return jsonFetch.post('/issue/cancelOpen', issue)
}
// 卡奖列表
export function listException (query) {
  return jsonFetch.post('/issue/listException', query)
}
// 手动补奖
export function repairOpen (issue) {
  return jsonFetch.post('/issue/repairOpen', issue)
}
// 作废订单
export function invalidateOrder (issue) {
  return jsonFetch.post('/issue/invalidateOrder', issue)
}