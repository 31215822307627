<template>    
  <div class="content member">
    <base-header class="pb-6">
      <b-row align-v="center" class="py-4">
        <b-col cols="7" lg="6">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-breadcrumb></route-breadcrumb>
          </nav>
        </b-col>
        <b-col lg="6" cols="5" class="text-right">
        </b-col>
      </b-row>
    </base-header>
    <b-container fluid class="mt--6">
      <div>
        <card class="no-border-card" body-classes="px-0 pb-1" footer-classes="pb-2">
          <!-- <template slot="header">
            <h3 class="mb-0">Paginated tables</h3>
            <p class="text-sm mb-0">
              This is a client side example of paginated tables using element-ui tables.
            </p>
          </template> -->
          <div>
            <el-form inline class="pl-3">
              <el-form-item label="类型">
                <el-select v-model="query.filter.type" style="width:150px">
                  <el-option value="" label="全部"></el-option>
                  <el-option value="experience" label="体验金会员"></el-option>
                  <el-option value="balance" label="正式会员"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="状态">
                <el-select v-model="query.filter.available" style="width:150px">
                  <el-option value="" label="全部"></el-option>
                  <el-option :value="1" label="正常"></el-option>
                  <el-option :value="0" label="封号"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="UID">
                <el-input v-model="query.filter.uid" style="width:150px" clearable></el-input>
              </el-form-item>
              <el-form-item label="昵称">
                <el-input v-model="query.filter.nickName" style="width:150px" clearable></el-input>
              </el-form-item>
              <el-form-item>
                <base-button type="primary" size="sm" icon class="btn-icon" @click="getData">
                  <span><i class="fa fa-search"></i></span>
                  <span>搜索</span>
                </base-button>
                <base-button type="secondary" size="sm" icon class="btn-icon" @click="reset">
                  <span><i class="fa fa-mail-reply"></i></span>
                  <span>重置</span>
                </base-button>
              </el-form-item>
            </el-form>
            <el-table :data="tableData"
                      size="small"
                      row-key="id"
                      border
                      v-loading="loading"
                      header-row-class-name="thead-light"
                      :default-sort = "{prop: 'regTime', order: 'descending'}"
                      @sort-change="handleSortChange"
                      @selection-change="selectionChange">
              <el-table-column prop="uid" label="UID" width="80" align="center" sortable="custom">
                <template v-slot="{row}">
                  <div>{{row.uid}}</div>
                  <el-tag v-if="row.type=='experience'" type="warning">体验金</el-tag>
                </template>
              </el-table-column>
              <el-table-column label="头像昵称" align="left" header-align="center"width="200">
                <template v-slot="{row}">
                  <div class="flex align-center">
                    <member-info :member="row"></member-info>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="上级" align="center" width="80">
                <template slot-scope="scope">
                  <span>{{scope.row.parent===0?'无上级':scope.row.parent}}</span>
                </template>
              </el-table-column>
              <el-table-column prop="children" label="下级" width="80" align="center" sortable="custom"></el-table-column>
              <el-table-column prop="balance" label="余额" width="80" align="right" sortable="custom">
                <template v-slot="{row}">
                  <span v-if="row.type=='experience'">
                    <span :class="row.experience>=0?'text-green':'text-red'">{{row.experience}}</span>
                  </span>
                  <span v-else :class="row.balance>=0?'text-green':'text-red'">{{row.balance}}</span>
                </template>
              </el-table-column>
              <el-table-column prop="profitToday" label="今日盈亏" width="90" align="right" header-align="center" sortable="custom">
                <template slot-scope="scope">
                  <span :class="scope.row.profitToday>=0?'text-green':'text-red'">{{scope.row.profitToday}}</span>
                </template>
              </el-table-column>
              <el-table-column prop="thirdPartBalance" label="三方额度" width="90" align="right" header-align="center">
                <template slot-scope="scope" class="flex">
                  <span class="margin-right-sm" :class="scope.row.thirdPartBalance>=0?'text-green':''">{{scope.row.thirdPartBalance}}</span>
                  <el-dropdown @command="handleThirdPart">
                    <span class="el-dropdown-link">
                      <i class="el-icon-setting el-icon--right"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item :command="'refresh:'+scope.row.uid" icon="el-icon-refresh">刷新</el-dropdown-item>
                      <el-dropdown-item :command="'retrieve:'+scope.row.uid" icon="el-icon-back">回收</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </template>
              </el-table-column>
              <el-table-column prop="regTime" label="注册时间" width="100" sortable="custom">
                <template v-slot="{row}">
                  {{row.regTime.split(' ')[0]}}<br/>{{row.regTime.split(' ')[1]}}
                </template>
              </el-table-column>
              <el-table-column prop="loginTime" label="最后登录" width="100" sortable="custom">
                <template v-slot="{row}">
                  {{row.loginTime.split(' ')[0]}}<br/>{{row.loginTime.split(' ')[1]}}
                </template>
              </el-table-column>
              <el-table-column label="状态" width="70" sortable="custom">
                <template v-slot="{row}">
                  <base-switch 
                    v-model="row.available" on-text="正常" off-text="封禁" 
                    type="success" size="sm" @change="handleSwitchStatus(row)">
                  </base-switch>
                </template>
              </el-table-column>
              <el-table-column width="230px" align="center" label="操作" fit>
                <div slot-scope="{$index, row}" class="flex justify-center">
                  <b-button-group>
                    <base-button
                      @click.native="showModal(row, 'editProfile')"
                      type="info"
                      title="编辑"
                      outline
                      icon
                    >
                      <span class="btn-inner--icon"><i class="cuIcon-write"></i></span>
                    </base-button>
                    <base-button
                      @click.native="showModal(row, 'changeBalance')"
                      type="info"
                      title="改分"
                      outline
                      icon
                    >
                      <span class="btn-inner--icon"><i class="cuIcon-order"></i></span>
                    </base-button>
                    <base-button
                      @click.native="showModal(row, 'orderList')"
                      type="info"
                      title="记录"
                      outline
                      icon
                    >
                      <span class="btn-inner--icon"><i class="cuIcon-form"></i></span>
                    </base-button>
                    <base-button
                      @click.native="showModal(row, 'balanceHis')"
                      type="info"
                      title="账变"
                      outline
                      icon
                    >
                      <span class="btn-inner--icon"><i class="cuIcon-refund"></i></span>
                    </base-button>
                    <base-button
                      @click.native="showModal(row, 'commissionHis')"
                      type="info"
                      title="佣金"
                      outline
                      icon
                    >
                      <span class="btn-inner--icon"><i class="cuIcon-redpacket"></i></span>
                    </base-button>
                    <base-button
                      @click.native="openChat(row.uid)"
                      type="info"
                      title="发送消息"
                      outline
                      icon
                    >
                      <span class="btn-inner--icon"><i class="cuIcon-mark"></i></span>
                    </base-button>
                  </b-button-group>
                </div>
              </el-table-column>
            </el-table>
          </div>
          <div slot="footer">
            <base-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :query="query"
              :total="totalCount">
            </base-pagination>
          </div>
        </card>
      </div>
    </b-container>
    <!--Modals-->
    <change-balance v-if="modal.changeBalance" :visible.sync="modal.changeBalance" :uid="uid" @submit="handleChangeBalanceDone"></change-balance>
    <order-list v-if="modal.orderList" :visible.sync="modal.orderList" :uid="uid"></order-list>
    <balance-his v-if="modal.balanceHis" :visible.sync="modal.balanceHis" :uid="uid"></balance-his>
    <commission-his v-if="modal.commissionHis" :visible.sync="modal.commissionHis" :uid="uid"></commission-his>
    <edit-profile v-if="modal.editProfile" :visible.sync="modal.editProfile" :uid="uid" @submit="getData"></edit-profile>
  </div>
</template>
<script>
import { list, switchStatus, retrieveThirdPartBalance, queryThirdPartBalance } from '@/api/member'
import ChangeBalance from './change-balance'
import OrderList from './order-list'
import BalanceHis from './balance-his'
import CommissionHis from './commission-his'
import EditProfile from './edit-profile'
import MemberInfo from '@/views/Components/MemberInfo'
export default {
  components: {
    ChangeBalance,
    OrderList,
    BalanceHis,
    CommissionHis,
    EditProfile,
    MemberInfo
  },
  data() {
    return {
      loading: false,
      query: {
        filter: {
          type: '',
          available: '',
          uid: '',
          nickName: ''
        },
        start: 0,
        limit: 10,
        sort: {
          prop: 'regTime',
          order: 'desc'
        }
      },
      totalCount: 0,
      tableData: [],
      selectedRows: [],
      uid: '',
      modal: {
        changeBalance: false,
        orderList: false,
        balanceHis: false,
        editProfile: false,
        commissionHis: false
      }
    };
  },
  created () {
    this.getData()
  },
  methods: {
    handleSizeChange () {
      this.getData()
    },
    handleCurrentChange () {
      this.getData()
    },
    handleSortChange ({column, prop, order}) {
      if (order === 'ascending') {
        order = 'asc'
      } else {
        order = 'desc'
      }
      this.query.sort = { prop, order }
      this.getData()
    },
    getData() {
      this.loading = true
      list(this.query).then(res => {
        this.loading = false
        if (res.success) {
          this.totalCount = res.totalCount
          this.tableData = res.data
          this.$bvToast.toast('数据已更新', {
            title: '提示',
            solid: true,
            variant: 'success',
            autoHideDelay: 2000,
            toaster: 'b-toaster-top-center'
          })
        }
      })
    },
    reset () {
      this.query = {
        filter: {
          type: '',
          available: '',
          uid: '',
          nickName: ''
        },
        start: 0,
        limit: 10,
        sort: null
      }
      this.getData()
    },
    showModal(row, type) {
      this.uid = row.uid
      this.modal[type] = true
    },
    handleChangeBalanceDone () {
      this.modal.changeBalance = false
      this.getData()
    },
    handleSwitchStatus (row) {
      switchStatus(row.uid, row.available).then(res => {
        if (res.success) {
          this.getData()
        }
      })
    },
    handleDelete(index, row) {
      
    },
    selectionChange(selectedRows) {
      this.selectedRows = selectedRows
    },
    handleThirdPart (command) {
      this.loading = true
      let type = command.split(':')[0]
      let uid = command.split(':')[1]
      if (type == 'refresh') {
        queryThirdPartBalance(uid).then(res => {
          if (res.success) {
            this.$bvToast.toast('查询成功', {
              title: '提示',
              solid: true,
              variant: 'success',
              autoHideDelay: 2000,
              toaster: 'b-toaster-top-center'
            })
            this.getData()
          }
        })
      } else if (type == 'retrieve') {
        retrieveThirdPartBalance(uid).then(res => {
          if (res.success) {
            this.$bvToast.toast('回收成功，本次共回收额度：' + res.data, {
              title: '提示',
              solid: true,
              variant: 'success',
              autoHideDelay: 2000,
              toaster: 'b-toaster-top-center'
            })
            this.getData()
          }
        })
      }
    },
    openChat (uid) {
      if (!window.chatPage || window.chatPage.closed) {
        window.chatPage = window.open('/chat/'+uid, 'chatPage')
      }
      window.chatPage.focus()
      console.log('window.chatPage', window.chatPage)
      window.chatPage.location.href = '/chat/'+uid
    },
  }
}
</script>
<style lang="scss">
.member {
  .no-border-card .card-footer{
    border-top: 0;
  }
  .btn-group {
    .btn {
      padding: 0.225rem .325rem;
      font-size: 1rem;
    }
  }
}
</style>
