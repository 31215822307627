<template>
  <div :ref="id" style="border: 1px solid #ccc;">
      <Toolbar
          style="border-bottom: 1px solid #ccc"
          :editor="editor"
          :defaultConfig="toolbarConfig"
          :mode="mode"
      />
      <Editor
          style="height: 500px; overflow-y: hidden;"
          v-model="content"
          :defaultConfig="editorConfig"
          :mode="mode"
          @onCreated="handleCreate"
          @onChange="handleChange"
      />
  </div>
</template>

<script>
  import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
  import { BASE_URL } from '@/utils/fetch'

  export default {
    name: 'WangEditor',
    components: { Editor, Toolbar },
    props: {
      id: '',
      value: '',
      disabled: false
    },
    data () {
      const that = this
      return {
        editor: null,
        content: '',
        toolbarConfig: {
          modalAppendToBody: true
        },
        editorConfig: {
          placeholder: '请输入内容...',
          MENU_CONF: {
            // 图片上传配置
            uploadImage: {
              maxFileSize: 1 * 1024 * 1024, // 1M
              server: BASE_URL + '/upload',
              fieldName: 'file',
              // 自定义插入图片
              customInsert (res, insertFn) {
                  // res 即服务端的返回结果
                  if (res.success) {
                    var url = BASE_URL + '/static/system/' + res.data
                    // 从 res 中找到 url alt href ，然后插图图片
                    insertFn(url)
                  } else {
                    that.$swal.fire({
                      title: '出错了',
                      buttonsStyling: false,
                      confirmButtonClass: 'btn btn-warning btn-fill'
                    })
                  }
              },
              // 上传进度的回调函数
              onProgress (progress) {
                // progress 是 0-100 的数字
                // console.log('progress', progress)
              },
              // 单个文件上传成功之后
              onSuccess (file, res) {
                // console.log(`${file.name} 上传成功`, res)
              },
              // 单个文件上传失败
              onFailed (file, res) {
                that.$swal.fire({
                  title: '出错了',
                  buttonsStyling: false,
                  confirmButtonClass: 'btn btn-warning btn-fill'
                })
              },
              // 上传错误，或者触发 timeout 超时
              onError (file, err, res) {
                that.$swal.fire({
                  title: '出错了',
                  buttonsStyling: false,
                  confirmButtonClass: 'btn btn-warning btn-fill'
                })
              }
            },
            // 视频上传配置
            uploadVideo: {
              maxFileSize: 50 * 1024 * 1024, // 100M
              server: BASE_URL + '/upload',
              fieldName: 'file',
              // 自定义插入视频
              customInsert (res, insertFn) {
                  // res 即服务端的返回结果
                  if (res.success) {
                    var url = BASE_URL + '/static/system/' + res.data
                    // 从 res 中找到 url alt href ，然后插图图片
                    insertFn(url)
                  } else {
                    that.$swal.fire({
                      title: '出错了',
                      buttonsStyling: false,
                      confirmButtonClass: 'btn btn-warning btn-fill'
                    })
                  }
              },
              // 上传进度的回调函数
              onProgress (progress) {
                // progress 是 0-100 的数字
                // console.log('progress', progress)
              },
              // 单个文件上传成功之后
              onSuccess (file, res) {
                // console.log(`${file.name} 上传成功`, res)
              },
              // 单个文件上传失败
              onFailed (file, res) {
                that.$swal.fire({
                  title: '出错了',
                  buttonsStyling: false,
                  confirmButtonClass: 'btn btn-warning btn-fill'
                })
              },
              // 上传错误，或者触发 timeout 超时
              onError (file, err, res) {
                that.$swal.fire({
                  title: '出错了',
                  buttonsStyling: false,
                  confirmButtonClass: 'btn btn-warning btn-fill'
                })
              }
            }
          }
        },
        mode: 'default', // or 'simple'
      }
    },
    watch: {
      'value': {
        handler: function (val) {
          // 赋初始值
          if (this.editor) {
            // console.log('wangeditor', val)
            // this.editor.setHtml(val)
            /**
             * 20230106 解决编辑模式下报错“Uncaught (in promise) Error: Cannot resolve a DOM node from Slate node”的问题
             * setHtml 会异步更新（像 Vue 和 React），用 setTimeout 隔离
             * @see https://github.com/wangeditor-team/wangEditor/issues/4493
             */
            // setTimeout(() => this.editor.setHtml(val))
            this.content = val
          }
        }
      }
    },
    mounted () {},
    beforeDestroy () {
      const editor = this.editor
      if (editor == null) return
      editor.destroy() // 组件销毁时，及时销毁编辑器
    },
    methods: {
      handleCreate (editor) {
        this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
        editor.on('modalOrPanelShow', modalOrPanel => {
          if (modalOrPanel.type !== 'modal') return
          const { $elem } = modalOrPanel // modal element

          // 设置 modal 样式（定位、z-index）
          const width = $elem.width()
          const height = $elem.height()
          $elem.css({
            left: '50%',
            top: '50%',
            marginLeft: `-${width / 2}px`,
            marginTop: `-${height / 2}px`,
            zIndex: 99999
          })

                    // 显示蒙层
          document.getElementById('mask').style.display = 'block'
        })
      },
      handleChange (editor) {
        // console.log('------handleChange-----', editor.getHtml())
        this.content = editor.getHtml()
        this.$emit('input', this.content)
      },
      cleanPastedHTML (input) {
        // 1. remove line breaks / Mso classes
        var stringStripper = /(\n|\r| class=(")?Mso[a-zA-Z]+(")?)/g
        var output = input.replace(stringStripper, ' ')
        // 2. strip Word generated HTML comments
        var commentSripper = new RegExp('<!--(.*?)-->', 'g')
        output = output.replace(commentSripper, '')
        commentSripper = new RegExp('<!(.*?)>', 'g')
        output = output.replace(commentSripper, '')

        var tagStripper = new RegExp('<(/)*(meta|link|span|\\?xml:|st1:|o:|font)(.*?)>', 'gi')
        // 3. remove tags leave content if any
        output = output.replace(tagStripper, '')

        // 4. Remove everything in between and including tags '<style(.)style(.)>'
        var badTags = ['style', 'script', 'applet', 'embed', 'noframes', 'noscript']
        for (var i = 0; i < badTags.length; i++) {
          tagStripper = new RegExp('<' + badTags[i] + '.*?' + badTags[i] + '(.*?)>', 'gi')
          output = output.replace(tagStripper, '')
        }

        // 5. remove attributes ' style="..."'
        var badAttributes = ['style', 'start']
        for (let i = 0; i < badAttributes.length; i++) {
          var attributeStripper = new RegExp(' ' + badAttributes[i] + '="(.*?)"', 'gi')
          output = output.replace(attributeStripper, '')
        }

        return output
      },
      getContent () {
        alert(this.content)
      }
    }
  }
</script>
<style src="@wangeditor/editor/dist/css/style.css"></style>