<template>
  <el-dialog
    v-modal-drag
    width="30%"
    :visible.sync="visible"
    :title="action==='add'?'新增快捷回复':'编辑快捷回复'"
    @close="close">
    <el-form :model="model" ref="form" label-position="top">
      <el-row :gutter="20">
        <el-col :span="24">
          <el-form-item prop="content" label="内容" required>
            <el-input v-model="model.content" type="textarea" rows="5" maxlength="100" show-word-limit></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div slot="footer">
      <base-button size="sm" type="primary" @click="submit">保存</base-button>
      <base-button size="sm" type="link" class="ml-auto" @click="close">取消</base-button>
    </div>
  </el-dialog>
</template>
<script>
import { detail, create, modify } from '@/api/service/quick-reply'
export default {
  name: 'QuickReplyForm',
  props: ['visible', 'action', 'id'],
  data () {
    return {
      model: {
        id: '',
        content: ''
      }
    }
  },
  created () {
    if (this.action==='modify') {
      detail(this.id).then(res => {
        if (res.success) {
          this.model = res.data
        }
      })
    }
  },
  methods: {
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.action === 'add') {
            create(this.model).then(res => {
              if (res.success) {
                this.close()
                this.$emit('submit')
              }
            })
          } else {
            modify(this.model).then(res => {
              if (res.success) {
                this.close()
                this.$emit('submit')
              }
            })
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    close () {
      this.$emit('update:visible', false)
    }
  }
}
</script>
<style lang="scss">

</style>