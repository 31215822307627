<template>    
  <div class="turn-table">
    <div class="flex justify-between align-center padding-sm">
      <div>转盘设置</div>
      <div>
        <span class="margin-right-sm">类型</span>
        <el-radio-group v-model="layout">
          <el-radio label="grid">九宫格</el-radio>
          <el-radio label="wheel">转盘</el-radio>
        </el-radio-group>
      </div>
      <div class="margin-left">
        <base-button
            @click.native="handleAdd"
            type="primary"
            size="sm"
            icon
          >
          <i class="fa fa-plus"></i>新增
        </base-button>
        <base-button
            @click.native="getData"
            size="sm"
            icon
          >
          <i class="fa fa-refresh"></i>刷新
        </base-button>
      </div>
    </div>
    <el-alert type="warning" show-icon>
      九宫格模式必须设置8个奖项，转盘模式建议设置6个或8个奖项
    </el-alert>
    <el-table :data="tableData"
              size="mini"
              row-key="id"
              border
              v-loading="loading"
              header-row-class-name="thead-light">
      <el-table-column label="位置" width="80" align="center">
        <template v-slot="{row,column,$index}">
          <span>
            <i class="el-icon-sort margin-right drag-column" style="cursor:move" title="按住拖动排序"></i>
            {{$index+1}}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="label" label="文字" width="150" align="center">
        <template v-slot="{row}">
          <el-input size="mini" v-model="row.label" maxlength="20"></el-input>
        </template>
      </el-table-column>
      <el-table-column prop="img" label="图片" width="100">
        <template v-slot="{$index, row}">
          <base-upload
            :img-name="row.img"
            :img-size="20"
            @success="handleUploadSuccess($index, $event)">
          </base-upload>
        </template>
      </el-table-column>
      <el-table-column prop="award" label="奖品" width="100" align="center">
        <template v-slot="{row}">
          <el-input size="mini" v-model="row.award" type="number"></el-input>
        </template>
      </el-table-column>
      <el-table-column prop="rate" label="中将概率" width="100" align="center">
        <template v-slot="{row}">
          <el-input size="mini" v-model="row.rate" type="number" :max="1"></el-input>
        </template>
      </el-table-column>
      <el-table-column prop="status" label="状态" width="100" align="center">
        <template v-slot="{row}">
          <base-switch 
              v-model="row.available" on-text="启用" off-text="禁用" 
              type="success" size="sm" @change="handleSwitchStatus(row)">
            </base-switch>
        </template>
      </el-table-column>
      <el-table-column width="100px" label="操作" align="center">
        <div slot-scope="{$index, row}" class="d-flex">
          <base-button
            @click.native="handleDelete($index)"
            type="danger"
            size="sm"
            outline
          >
            <i class="fa fa-trash"></i>删除
          </base-button>
        </div>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
import Sortable from 'sortablejs'
import BaseUpload from '@/components/BaseUpload'
import { list, switchStatus, sort, del } from '@/api/config/turnTable'
export default {
  props: ['activityId', 'layout'],
  components: {
    BaseUpload
  },
  data() {
    return {
      tableData: [],
      loading: false,
      id: ''
    };
  },
  watch: {
    layout (val) {
      this.$emit('update:layout', val)
    },
    'tableData': {
      handler (val) {
        console.log('tableData change', val)
        this.$emit('change', val)
      },
      immediate: true
    }
  },
  created () {
    this.getData()
  },
  methods: {
    getData(clear) {
      this.loading = true
      if (clear) {
        this.tableData = []
      }
      list(this.activityId).then(res => {
        this.loading = false
        if (res.success) {
          this.tableData = res.data
        }
        this.$nextTick(() => {
          this.initSortable()
        })
      })
    },
    handleAdd () {
      this.tableData.push({
        label: '',
        award: 0,
        img: '',
        rate: 0,
        available: true
      })
    },
    handleSwitchStatus(row) {
      switchStatus(row.id, row.available).then(res => {
        if (res.success) {
          this.getData()
        }
      })
    },
    handleDelete(index) {
      this.tableData.splice(index, 1)
    },
    handleUploadSuccess (index, fileName) {
      this.tableData[index].img = fileName
    },
    initSortable () {
      const tbody = document.querySelector('.turn-table .el-table__body-wrapper tbody')
      const that = this
      Sortable.create(tbody, {
        handle: ".drag-column", 
        onEnd({ newIndex, oldIndex }) {
          const currRow = that.tableData.splice(oldIndex, 1)[0]
          that.tableData.splice(newIndex, 0, currRow)
        }
      })
    }
  }
};
</script>
<style lang="scss">
  .turn-table {
    .el-upload--picture-card,
    .el-upload-list--picture-card .el-upload-list__item {
      width: 50px;
      height: 50px;
      line-height: 45px;
    }
    .el-upload--picture-card i {
      font-size: 18px;
    }
    .el-upload-list--picture-card .el-upload-list__item-actions span + span {
      margin-left: 0;
    }
  }
</style>
