<template>    
  <div class="content">
    <base-header class="pb-6">
      <b-row align-v="center" class="py-4">
        <b-col cols="7" lg="6">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-breadcrumb></route-breadcrumb>
          </nav>
        </b-col>
        <b-col lg="6" cols="5" class="text-right">
        </b-col>
      </b-row>
    </base-header>
    <b-container fluid class="mt--6">
      <div>
        <card class="no-border-card" body-classes="px-0 pb-1" footer-classes="pb-2">
          <div>
            <el-form inline class="pl-3">
              <el-form-item label="类型">
                <el-select v-model="query.filter.type" clearable="">
                  <el-option value="checkIn" label="签到"></el-option>
                  <el-option value="charge" label="充值"></el-option>
                  <el-option value="singleCharge" label="单笔充值"></el-option>
                  <el-option value="order" label="流水"></el-option>
                  <el-option value="lose" label="亏损"></el-option>
                  <el-option value="invite" label="邀请"></el-option>
                  <el-option value="turnTable" label="大转盘"></el-option>
                  <el-option value="experience" label="体验金"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <base-button type="primary" size="sm" icon class="btn-icon" @click="getData">
                  <span><i class="fa fa-search"></i></span>
                  <span>搜索</span>
                </base-button>
              </el-form-item>
              <div class="float-right">
                <el-form-item>
                  <base-button
                    @click.native="showModal(0, 'form', 'add')"
                    type="primary"
                    size="sm"
                    icon
                  >
                    <i class="fa fa-plus"></i>新增
                  </base-button>
                </el-form-item>
              </div>
            </el-form>
            <el-table :data="tableData"
                      size="small"
                      row-key="id"
                      border
                      v-loading="loading"
                      header-row-class-name="thead-light">
              <el-table-column prop="id" label="排序" width="100" align="center">
                <template v-slot="{$index, row}">
                  <span>
                    <i class="el-icon-sort margin-right drag-column" style="cursor:move" title="按住拖动排序"></i>
                    {{ $index+1 }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column prop="name" label="名称" width="200" align="center"></el-table-column>
              <el-table-column prop="type" label="类型" width="100" align="center">
                <template v-slot="{row}">
                  {{ row.type | activityType }}
                </template>
              </el-table-column>
              <el-table-column prop="target" label="活动奖励" width="280" align="center">
                <template v-slot="{$index, row}">
                  <div v-for="target in row.targets">
                    {{ (row.seriesDays > 0 ? '连续' + row.seriesDays + '天' : '') }}
                    {{ row.targetType | targetType}}满{{ target.target + getUnit(row.type).unit}}，
                    奖励{{ target.award + getUnit(row.type).award }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="dailyLimit" label="每日领取限制" width="90" align="center">
                <template v-slot="{$index, row}">
                  {{ row.dailyLimit > 0 ? row.dailyLimit + '次' : '不限' }}
                </template>
              </el-table-column>
              <el-table-column prop="sumLimit" label="总领取限制" width="90" align="center">
                <template v-slot="{$index, row}">
                  {{ row.sumLimit > 0 ? row.sumLimit + '次' : '不限' }}
                </template>
              </el-table-column>
              <el-table-column prop="status" label="状态" width="90" align="center">
                <div slot-scope="{$index, row}" class="flex justify-center">
                  <base-switch 
                      v-model="row.available" on-text="启用" off-text="禁用" 
                      type="success" size="sm" @change="handleSwitchStatus(row)">
                    </base-switch>
                </div>
              </el-table-column>
              <el-table-column width="250px" align="center" label="操作">
                <div slot-scope="{$index, row}" class="flex justify-center">
                  <base-button
                    @click.native="showModal(row.id, 'form', 'modify')"
                    type="info"
                    size="sm"
                    outline
                  >
                    <i class="fa fa-edit"></i>编辑
                  </base-button>
                  <base-button
                    @click.native="showModal(row.id, 'record')"
                    type="info"
                    size="sm"
                    outline
                  >
                    <i class="fa fa-list"></i>领取记录
                  </base-button>
                  <base-button
                    @click.native="handleDelete(row.id)"
                    type="danger"
                    size="sm"
                    outline
                  >
                    <i class="fa fa-trash"></i>删除
                  </base-button>
                </div>
              </el-table-column>
            </el-table>
          </div>
        </card>
      </div>
    </b-container>
    <!--Modals-->
    <edit-form v-if="modal.form" :visible.sync="modal.form" :id="id" :action="formAction" @submit="handleEditDone"></edit-form>
    <record v-if="modal.record" :visible.sync="modal.record" :activityId="id"></record>
  </div>
</template>
<script>
import Sortable from 'sortablejs'
import { list, switchStatus, sort, del } from '@/api/config/activity'
import EditForm from './form'
import Record from './record'
export default {
  components: {
    EditForm,
    Record
  },
  data() {
    return {
      loading: false,
      query: {
        filter: {
          type: ''
        },
      },
      totalCount: 0,
      tableData: [],
      selectedRows: [],
      formAction: '',
      id: '',
      modal: {
        form: false,
        record: false
      }
    };
  },
  filters: {
    targetType (value) {
      if (value=='order') {
        return '流水'
      }
      if (value=='charge') {
        return '充值'
      }
      if (value=='singleCharge') {
        return '单笔充值'
      }
      if (value=='lose') {
        return '亏损'
      }
      if (value=='invite') {
        return '邀请'
      }
      if (value=='experience') {
        return '体验金'
      }
      return value
    }
  },
  created () {
    this.getData()
  },
  methods: {
    getData(clear) {
      this.loading = true
      if (clear) {
        this.tableData = []
      }
      list(this.query).then(res => {
        this.loading = false
        if (res.success) {
          this.totalCount = res.totalCount
          this.tableData = res.data
        }
        this.$nextTick(() => {
          this.initSortable()
        })
      })
    },
    handleSwitchStatus(row) {
      switchStatus(row.id, row.available).then(res => {
        if (res.success) {
          this.getData()
        }
      })
    },
    showModal(id, type, action) {
      this.id = id
      this.formAction = action
      this.modal[type] = true
    },
    handleEditDone () {
      this.modal.form = false
      this.getData(true)
    },
    handleDelete(id) {
      this.$bvModal.msgBoxConfirm('确定删除?', {
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: '确定',
        cancelTitle: '取消',
        centered: true
      })
        .then(value => {
          if (value) {
            del(id).then(res => {
              if (res.success) {
                this.getData()
                this.$bvToast.toast('操作成功', {
                  title: '提示',
                  solid: true,
                  variant: 'success',
                  autoHideDelay: 3000,
                  toaster: 'b-toaster-top-center'
                })
              }
            })
          }
        })
    },
    initSortable () {
      const tbody = document.querySelector('.el-table__body-wrapper tbody')
      const that = this
      Sortable.create(tbody, {
        handle: ".drag-column", 
        onEnd({ newIndex, oldIndex }) {
          const currRow = that.tableData.splice(oldIndex, 1)[0]
          that.tableData.splice(newIndex, 0, currRow)
          let ids = [];
          that.tableData.forEach(record => {
            ids.push(record.id)
          })
          that.loading = true
          sort(ids).then(res => {
            that.getData()
            that.$bvToast.toast('排序已更新', {
              title: '提示',
              solid: true,
              variant: 'success',
              autoHideDelay: 3000,
              toaster: 'b-toaster-top-center'
            })
          })
        }
      })
    },
    getUnit (activityType) {
      let o = {}
      if (activityType=='checkIn') {
        o.action = '流水'
        o.unit = '元宝'
        o.award = '元宝'
      } else if (activityType=='charge') {
        o.action = '充值'
        o.unit = '元宝'
        o.award = '元宝'
      } else if (activityType=='singleCharge') {
        o.action = '单笔充值'
        o.unit = '元宝'
        o.award = '元宝'
      } else if (activityType=='order') {
        o.action = '流水'
        o.unit = '元宝'
        o.award = '元宝'
      } else if (activityType=='lose') {
        o.action = '亏损'
        o.unit = '元宝'
        o.award = '元宝'
      } else if (activityType=='invite') {
        o.action = '邀请'
        o.unit = '人'
        o.award = '元宝'
      } else if (activityType=='turnTable') {
        o.action = '流水'
        o.unit = '元宝'
        o.award = '次抽奖机会'
      } else if (activityType=='experience') {
        o.action = '体验金余额'
        o.unit = ''
        o.award = '元宝'
      }
      return o
    }
  }
};
</script>
<style>
  .no-border-card .card-footer{
    border-top: 0;
  }
</style>
