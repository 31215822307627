import { formFetch, jsonFetch } from '@/utils/fetch'
import Qs from 'qs'

// 总账单报表
export function getSumReport (query) {
  return jsonFetch.post('/report/sum', query)
}
// 用户报表
export function getMemberReport (query) {
  return jsonFetch.post('/report/member', query)
}
// 彩种报表
export function getGameReport (query) {
  return jsonFetch.post('/report/game', query)
}