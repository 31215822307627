<template>
  <el-dialog
    v-modal-drag
    width="30%"
    :visible.sync="visible"
    :title="'帐号密码修改-'+uid"
    @close="close">
    <el-form :model="model" ref="form">
      <el-row :gutter="20">
        <el-col :span="24">
          <el-form-item prop="uid" label="UID">
            <div style="margin-top:3px">
              <span>{{model.uid}}</span>
              <span class="margin-left">
                <el-tag v-if="model.type=='experience'" type="warning">体验金会员</el-tag>
                <el-tag v-else type="success">正式会员</el-tag>
              </span>
              <span class="margin-left">
                <el-tag v-if="model.available" type="success">正常</el-tag>
                <el-tag v-else type="danger">封禁</el-tag>
              </span>
            </div>
          </el-form-item>
        </el-col>
        <el-col :span="12">
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item prop="userName" label="帐号" required>
            <el-input v-model="model.userName"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop="nickName" label="昵称" required>
            <el-input v-model="model.nickName"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item prop="password" label="密码">
            <el-input v-model="model.password" placeholder="需要修改才填写" :minlength="6" :maxlength="20"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop="parent" label="上级ID" required>
            <el-input v-model="model.parent" :maxlength="10"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="24">
          <el-form-item prop="commissionRate" label="返佣比例(千分之几，为0则按照全局设置)" required>
            <el-input v-model="model.commissionRate" type="number" :maxlength="6"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div slot="footer">
      <base-button size="sm" type="primary" @click="submit">保存</base-button>
      <base-button size="sm" type="link" class="ml-auto" @click="close">取消</base-button>
    </div>
  </el-dialog>
</template>
<script>
import { find, saveProfile } from '@/api/member'
export default {
  props: ['visible','uid'],
  data() {
    return {
      loading: false,
      model: {
        uid: '',
        type: '',
        userName: '',
        nickName: '',
        password: '',
        parent: 0,
        available: true,
        commissionRate: 0
      }
    };
  },
  created() {
    this.getData()
  },
  methods: {
    getData () {
      this.loading = true
      find(this.uid).then(res => {
        this.loading = false
        if (res.success) {
          this.model.uid = this.uid
          this.model.type = res.data.type
          this.model.userName = res.data.userName
          this.model.nickName = res.data.nickName
          this.model.parent = res.data.parent
          this.model.available = res.data.available
          this.model.commissionRate = res.data.commissionRate
        }
      })
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          saveProfile(this.model).then(res => {
            if (res.success) {
              this.close()
              this.$emit('submit')
            }
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    close () {
      this.$emit('update:visible', false)
    },
  }
};
</script>