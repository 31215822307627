<template>    
  <div class="content">
    <base-header class="pb-6">
      <b-row align-v="center" class="py-4">
        <b-col cols="7" lg="6">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-breadcrumb></route-breadcrumb>
          </nav>
        </b-col>
        <b-col lg="6" cols="5" class="text-right">
        </b-col>
      </b-row>
    </base-header>
    <b-container fluid class="mt--6">
      <div>
        <card class="no-border-card" body-classes="px-0 pb-1" footer-classes="pb-2">
          <div>
            <el-form inline class="pl-3">
              <el-form-item label="合作方" v-show="show_third_part_game">
                <el-radio-group v-model="query.filter.thirdPart" size="small" @change="handleThirdPartChange">
                  <el-radio-button label="OWN">自有</el-radio-button>
                  <el-radio-button label="NG">NG</el-radio-button>
                </el-radio-group>
              </el-form-item>
              <el-form-item prop="platType" label="平台类型" v-show="query.filter.thirdPart=='NG'" style="margin-left:20px">
                <el-select v-model="query.filter.platType" @change="getData" clearable style="width:200px">
                  <el-option v-for="item in platTypeList" :key="item.id" :value="item.code">
                    <div class="flex align-center justify-between">
                      <span>{{ item.code }}</span>
                      <span>{{ item.name }}</span>
                    </div>
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="开奖来源" v-show="query.filter.thirdPart=='OWN'" style="margin-left:20px">
                <el-radio-group v-model="query.filter.self" size="small" @change="getData">
                  <el-radio-button label="">全部</el-radio-button>
                  <el-radio-button label="1">自开</el-radio-button>
                  <el-radio-button label="0">公开</el-radio-button>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="游戏类型" style="margin-left:20px">
                <el-select v-model="query.filter.type" @change="getData" clearable style="width:200px">
                  <el-option v-for="item in gameTypes" :value="item.value" :label="item.label"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <base-button type="primary" size="sm" icon class="btn-icon" @click="getData">
                  <span><i class="fa fa-search"></i></span>
                  <span>搜索</span>
                </base-button>
              </el-form-item>
              <div class="float-right">
                <el-form-item>
                  <base-button
                    @click.native="showModal(0, 'form', 'add')"
                    type="primary"
                    size="sm"
                    icon
                  >
                    <i class="fa fa-plus"></i>新增
                  </base-button>
                </el-form-item>
              </div>
            </el-form>
            <el-table
              ref="table"
              :data="tableData"
              size="small"
              row-key="id"
              border
              v-loading="loading"
              header-row-class-name="thead-light">
              <el-table-column key="id" prop="id" label="ID" width="50" align="center" header-align="center"></el-table-column>
              <el-table-column key="thirdPart" v-if="query.filter.thirdPart=='NG'" prop="platType" label="平台类型" width="100" align="center" header-align="center"></el-table-column>
              <el-table-column key="type" prop="type" label="游戏类型" width="80" align="center" header-align="center">
                <template v-slot="{row}">
                  <div>{{row.type}}</div>
                  <el-tag v-if="row.self" type="success">自开</el-tag>
                </template>
              </el-table-column>
              <el-table-column key="code" prop="code" label="游戏代码" width="100" align="center" header-align="center"></el-table-column>
              <el-table-column key="name" prop="name" label="游戏名称" width="150" align="center" header-align="center"></el-table-column>
              <el-table-column key="logoImg" prop="logoImg" label="logo图" width="110" align="center" header-align="center">
                <template v-slot="{row}">
                  <base-image v-if="row.logoImg" :src="serverHost+'static/system/'+row.logoImg" :height="50" />
                </template>
              </el-table-column>
              <el-table-column key="bgImg" prop="bgImg" label="封面图" width="110" align="center" header-align="center">
                <template v-slot="{row}">
                  <base-image v-if="row.bgImg" :src="serverHost+'static/system/'+row.bgImg" :height="50" />
                </template>
              </el-table-column>
              <el-table-column key="limit" v-if="query.filter.thirdPart=='OWN'" label="投注限额" width="150" align="left" header-align="center">
                <template v-slot="{row}">
                  <div>单笔最小: {{row.minBetOrder}}</div>
                  <div>单笔最大: {{row.maxBetOrder}}</div>
                  <div>当期最大: {{row.maxBetIssue}}</div>
                </template>
              </el-table-column>
              <el-table-column key="rate" label="返水比例" width="100" align="center" header-align="center">
                <template v-slot="{row}">
                  <div>返水: <span class="text-red">{{row.returnRate}}</span>‰</div>
                  <div>返佣: <span class="text-red">{{row.commissionRate}}</span>‰</div>
                </template>
              </el-table-column>
              <el-table-column key="robotNum" v-if="query.filter.thirdPart=='OWN'" prop="robotNum" label="机器人" width="80" align="center" header-align="center"></el-table-column>
              <el-table-column key="available" label="状态" width="100" align="center" header-align="center">
                <div slot-scope="{$index, row}" class="flex justify-center">
                  <base-switch 
                    v-model="row.available" on-text="启用" off-text="禁用" 
                    type="success" size="sm" @change="handleSwitchStatus(row)">
                  </base-switch>
                </div>
              </el-table-column>
              <el-table-column key="operate" width="170px" align="center" label="操作">
                <div slot-scope="{$index, row}" class="flex justify-center">
                  <base-button
                    @click.native="showModal(row.id, 'form', 'modify')"
                    type="info"
                    size="sm"
                    outline
                  >
                    <i class="fa fa-edit"></i>编辑
                  </base-button>
                  <base-button
                    @click.native="handleDelete(row.id)"
                    type="danger"
                    size="sm"
                    outline
                  >
                    <i class="fa fa-trash"></i>删除
                  </base-button>
                </div>
              </el-table-column>
            </el-table>
          </div>
          <div slot="footer">
            <base-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :query="query"
              :total="totalCount">
            </base-pagination>
          </div>
        </card>
      </div>
    </b-container>
    <!--Modals-->
    <edit-form v-if="modal.form" :visible.sync="modal.form" :id="id" :action="formAction" :thirdPart="query.filter.thirdPart" @submit="handleEditDone"></edit-form>
  </div>
</template>
<script>
import { get } from '@/api/config/param'
import { list, switchStatus, del, listNgPlatType } from '@/api/config/game'
import EditForm from './form'
import { BASE_URL } from '@/utils/fetch' 
export default {
  components: {
    EditForm
  },
  data() {
    return {
      loading: false,
      show_third_part_game: false,
      platTypeList: [],
      query: {
        filter: {
          id: '',
          thirdPart: 'OWN',
          platType: '',
          code: '',
          name: '',
          self: '',
          type: ''
        },
        start: 0,
        limit: 10,
        sort: null
      },
      totalCount: 0,
      tableData: [],
      selectedRows: [],
      formAction: '',
      id: '',
      modal: {
        form: false
      },
      serverHost: BASE_URL
    };
  },
  computed: {
    gameTypes () {
      if (this.query.filter.thirdPart=='OWN') {
        return [
          {value: 'PK', label:'PK'},
          {value: 'SS', label:'SS'},
          {value: 'PC20', label:'PC20'},
          {value: 'PC28', label:'PC28'},
          {value: 'PC32', label:'PC32'}
        ]
      } else {
        return [
          {value: '1', label:'视讯'},
          {value: '2', label:'老虎机'},
          {value: '3', label:'彩票'},
          {value: '4', label:'体育'},
          {value: '5', label:'电竞'},
          {value: '6', label:'捕猎'},
          {value: '7', label:'棋牌'}
        ]
      }
    }
  },
  created () {
    get('show_third_part_game').then(res => {
      if (res.success) {
        this.show_third_part_game = res.data.show_third_part_game=='true'
      }
    })
    listNgPlatType().then(res => {
      if (res.success) {
        this.platTypeList = res.data
      }
    })
    this.getData()
  },
  methods: {
    getData(clear) {
      this.loading = true
      if (clear) {
        this.tableData = []
      }
      list(this.query).then(res => {
        this.loading = false
        if (res.success) {
          this.totalCount = res.totalCount
          this.tableData = res.data
          this.$nextTick(() => {
            this.$refs.table.doLayout()
          })
          this.$bvToast.toast('数据已更新', {
            title: '提示',
            solid: true,
            variant: 'success',
            autoHideDelay: 2000,
            toaster: 'b-toaster-top-center'
          })
        }
      })
    },
    handleSwitchStatus(row) {
      switchStatus(row.id, row.available).then(res => {
        if (res.success) {
          this.getData()
        }
      })
    },
    showModal(id, type, action) {
      this.id = id
      this.formAction = action
      this.modal[type] = true
    },
    handleEditDone () {
      this.modal.form = false
      this.getData(true)
    },
    handleDelete(id) {
      this.$bvModal.msgBoxConfirm('确定删除?', {
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: '确定',
        cancelTitle: '取消',
        centered: true
      })
        .then(value => {
          if (value) {
            del(id).then(res => {
              if (res.success) {
                this.getData()
                this.$bvToast.toast('操作成功', {
                  title: '提示',
                  solid: true,
                  variant: 'success',
                  autoHideDelay: 3000,
                  toaster: 'b-toaster-top-center'
                })
              }
            })
          }
        })
    },
    handleSizeChange () {
      this.getData()
    },
    handleCurrentChange () {
      this.getData()
    },
    handleThirdPartChange () {
      this.query.filter.platType = ''
      this.query.filter.type = ''
      this.query.filter.self = ''
      this.getData()
    }
  }
};
</script>
<style>
  .no-border-card .card-footer{
    border-top: 0;
  }
</style>
