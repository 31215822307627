<template>
  <div>
    <validation-observer v-slot="{handleSubmit}" ref="formValidator">
      <form @submit.prevent="handleSubmit(submit)">
        <base-input name="name" label="名称" v-model="model.name" rules="required"/>
        <base-input name="label" label="标题" v-model="model.label" rules="required"/>
        <base-input name="url" label="URL" v-model="model.url"/>
        <base-input name="order" label="排序" type="number" v-model="model.order" rules="required"/>
      </form>
    </validation-observer>
    <!-- <template slot="footer">
      <div class="float-right">
        <base-button size="sm" type="primary" native-type="submit">保存</base-button>
        <base-button size="sm" type="link" class="ml-auto" @click="modals.classic = false">取消</base-button>
      </div>
    </template> -->
  </div>
</template>
<script>
import { detail, create, modify } from '@/api/system/menu'
export default {
  name: 'MenuForm',
  props: ['action', 'id'],
  data () {
    return {
      model: {
        name: '',
        label: '',
        url: '',
        path: '0',
        order: 0
      }
    }
  },
  created () {
    let that = this
    if (this.action==='modify') {
      detail(this.id).then(res => {
        if (res.success) {
          this.model = res.data
        }
      })
    } else {
      this.model = {
        id: '',
        label: '',
        url: '',
        path: that.id?'0,'+that.id:'0',
        order: 0
      }
    }
  },
  methods: {
    submit() {
      this.$refs.formValidator.validate().then(valid => {
        if (valid) {
          if (this.action === 'modify') {
            modify(this.model).then(res => {
              if (res.success) {
                this.$emit('submit')
              }
            })
          } else {
            create(this.model).then(res => {
              if (res.success) {
                this.$emit('submit')
              }
            })
          }
        }
      })
    },
  }
}
</script>
