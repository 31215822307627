<template>
  <el-dialog
    v-modal-drag
    width="70%"
    :visible.sync="visible"
    :title="action==='add'?'新增活动':'编辑活动'"
    @close="close">
    <el-form :model="model" ref="form" label-position="top">
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item prop="name" label="名称" required>
            <el-input v-model="model.name"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop="daterange">
            <div>
              活动时间
              <el-tooltip
                v-show="model.type=='lose'"
                content="亏损活动起止时间根据参与条件中的天数自动计算，到期自动延续"
                effect="dark">
                <el-icon name="info"></el-icon>
              </el-tooltip>
            </div>
            <el-date-picker
                v-model="daterange"
                :disabled="model.type=='lose'"
                type="datetimerange"
                value-format="yyyy-MM-dd HH:mm:ss"
                format="yyyy-MM-dd HH:mm:ss"
                :default-time="['00:00:00','23:59:59']"
                range-separator="至"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
                @input="handleTimeChange">
              </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item prop="type" label="类型" required>
            <el-select v-model="model.type" @change="handleTypeChange">
              <el-option value="checkIn" label="签到"></el-option>
              <el-option value="charge" label="累计充值"></el-option>
              <el-option value="singleCharge" label="单笔充值"></el-option>
              <el-option value="order" label="流水"></el-option>
              <el-option value="lose" label="亏损"></el-option>
              <el-option value="invite" label="邀请"></el-option>
              <el-option value="experience" label="体验金"></el-option>
              <el-option value="turnTable" label="大转盘"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item prop="dailyLimit" label="每日领取次数，不限填0" required>
            <el-input v-model="model.dailyLimit" type="number" :min="0" class="margin-right-sm" style="width: 100px"></el-input>次
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item prop="sumLimit" label="总领取次数，不限填0" required>
            <el-input v-model="model.sumLimit" type="number" :min="0" class="margin-right-sm" style="width: 100px"></el-input>次
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item label="参与条件" required>
        <span v-show="model.type==='checkIn'" class="margin-right-sm">
          连续<el-input v-model="model.seriesDays" type="number" :min="1" class="margin-lr-sm" style="width: 100px"></el-input>天
        </span>
        <span v-show="model.type==='lose'" class="margin-right-sm">
          <el-input ref="cycleDays" v-model="model.cycleDays" type="number" :min="1" class="margin-lr-sm" style="width: 100px"></el-input>天内
        </span>
        <el-select ref="targetType" v-model="model.targetType" class="margin-right-sm" style="width:150px">
          <el-option v-for="targetType in unit.targetTypes" :key="targetType.value" :value="targetType.value" :label="targetType.label"></el-option>
        </el-select>
        <el-checkbox v-model="model.firstOfDay" v-show="model.type==='singleCharge'">
          限制当日首次充值
        </el-checkbox>
      </el-form-item>
      <el-form-item>
        <div slot="label">
          <span>活动奖励</span>
          <base-button
            @click.native="addTarget"
            type="icon"
            size="sm"
            icon
            v-show="model.type==='singleCharge'"
            class="margin-left-sm"
          >
            <i class="fa fa-plus"></i>
          </base-button>
        </div>
        <div class="flex" v-for="(target, index) in model.targets" >
          <activity-target
            v-model="model.targets[index]" 
            :unit="unit"
            :type="model.type"
            class="margin-top-xs">
          </activity-target>
          <base-button
            @click.native="delTarget(index)"
            type="icon"
            size="sm"
            icon
            v-show="index!=0"
            class="margin-left-sm"
          >
            <i class="fa fa-minus"></i>
          </base-button>
        </div>
      </el-form-item>  
      <el-form-item prop="description" label="活动说明" required>
        <html-editor v-model="model.description"></html-editor>
      </el-form-item>
      <turn-table v-show="model.type=='turnTable'" :activityId="id" :layout.sync="model.turnTableLayout" @change="handleTurnTableChange"></turn-table>
    </el-form>
    <div slot="footer">
      <base-button size="sm" type="primary" @click="submit">保存</base-button>
      <base-button size="sm" type="link" class="ml-auto" @click="close">取消</base-button>
    </div>
  </el-dialog>
</template>
<script>
import { detail, create, modify } from '@/api/config/activity'
import HtmlEditor from '@/components/Inputs/HtmlEditor'
import TurnTable from './turn-table'
import ActivityTarget from './activity-target'
import dayjs from 'dayjs'
export default {
  name: 'ActivityForm',
  props: ['visible', 'action', 'id'],
  components: {
    HtmlEditor,
    TurnTable,
    ActivityTarget
  },
  data () {
    return {
      model: {
        id: '',
        name: '',
        description: '',
        type: '',
        targetType: '',
        targets: [{
          target: '',
          award: ''
        }],
        startTime: '',
        endTime: '',
        dailyLimit: 0,
        sumLimit: 0,
        seriesDays: 0,
        cycleDays: 0,
        turnTableLayout: 'grid',
        turnTableItems: [],
      },
      daterange: ['', '']
    }
  },
  computed: {
    unit () {
      let o = {}
      if (this.model.type=='checkIn') {
        o.target = ''
        o.award = '元宝'
        o.targetTypes = [
          {value: 'order', label: '流水'}
        ]
      } else if (this.model.type=='charge') {
        o.target = ''
        o.award = '元宝'
        o.targetTypes = [
          {value: 'charge', label: '充值'}
        ]
      } else if (this.model.type=='singleCharge') {
        o.target = ''
        o.award = '元宝'
        o.targetTypes = [
          {value: 'singleCharge', label: '单笔充值'}
        ]
      } else if (this.model.type=='order') {
        o.target = ''
        o.award = '元宝'
        o.targetTypes = [
          {value: 'order', label: '流水'}
        ]
      } else if (this.model.type=='lose') {
        o.target = ''
        o.award = '元宝'
        o.targetTypes = [
          {value: 'lose', label: '亏损'}
        ]
      } else if (this.model.type=='invite') {
        o.target = '人'
        o.award = '元宝'
        o.targetTypes = [
          {value: 'invite', label: '邀请'},
        ]
      } else if (this.model.type=='turnTable') {
        o.target = ''
        o.award = '次抽奖机会'
        o.targetTypes = [
          {value: 'charge', label: '充值'},
          {value: 'order', label: '流水'},
          {value: 'lose', label: '亏损'}
        ]
      } else if (this.model.type=='experience') {
        o.target = ''
        o.award = '元宝'
        o.targetTypes = [
          {value: 'experience', label: '体验金余额'}
        ]
      }
      return o
    }
  },
  created () {
    let that = this
    if (this.action==='modify') {
      detail(this.id).then(res => {
        if (res.success) {
          this.model = res.data
          if (!this.model.targets || this.model.targets.length==0) {
            this.model.targets = [{
              target: '',
              award: ''
            }]
          }
          this.daterange = [res.data.startTime, res.data.endTime]
        }
      })
    }
  },
  watch: {
    'model.cycleDays': {
      handler: function(val) {
        console.log('model.cycleDays', val)
        if (this.model.type=='lose') {
          this.daterange = [dayjs().format('YYYY-MM-DD HH:mm:ss'), dayjs().add(val, 'day').endOf('day').format('YYYY-MM-DD HH:mm:ss')]
        }
      }
    }
  },
  methods: {
    addTarget () {
      this.model.targets.push({
        target: '',
        award: ''
      })
    },
    delTarget(index) {
      this.model.targets.splice(index, 1)
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (!this.model.startTime || !this.model.endTime) {
            this.$bvToast.toast('请选择活动起始日期', {
              title: '提示',
              solid: true,
              variant: 'danger',
              autoHideDelay: 3000,
              toaster: 'b-toaster-top-center'
            })
            return
          }
          if (!this.model.targetType) {
            this.$refs.targetType.focus()
            this.$bvToast.toast('请选择条件类型', {
              title: '提示',
              solid: true,
              variant: 'danger',
              autoHideDelay: 3000,
              toaster: 'b-toaster-top-center'
            })
            return
          }
          for (let i=0;i<this.model.targets.length;i++) {
            if (this.model.targets[i].target<=0) {
              this.$bvToast.toast('目标必须大于0', {
                title: '提示',
                solid: true,
                variant: 'danger',
                autoHideDelay: 3000,
                toaster: 'b-toaster-top-center'
              })
              return
            }
            if (this.model.targets[i].award<=0) {
              this.$bvToast.toast('奖励必须大于0', {
                title: '提示',
                solid: true,
                variant: 'danger',
                autoHideDelay: 3000,
                toaster: 'b-toaster-top-center'
              })
              return
            }
          }
          if (this.model.type==='lose') {
            console.log('this.model.cycleDays', this.model.cycleDays)
            if (!this.model.cycleDays || this.model.cycleDays<=0) {
              this.$refs.cycleDays.focus()
              this.$bvToast.toast('亏损活动周期天数必须大于0', {
                title: '提示',
                solid: true,
                variant: 'danger',
                autoHideDelay: 3000,
                toaster: 'b-toaster-top-center'
              })
              return
            }
          }
          if (this.model.type==='turnTable') {
            if (this.model.turnTableLayout==='grid' && this.model.turnTableItems.length!=8) {
              this.$bvToast.toast('九宫格模式必须设置8个奖项', {
                title: '提示',
                solid: true,
                variant: 'danger',
                autoHideDelay: 3000,
                toaster: 'b-toaster-top-center'
              })
              return
            }
          }
          if (this.action === 'add') {
            create(this.model).then(res => {
              if (res.success) {
                this.close()
                this.$emit('submit')
              }
            })
          } else {
            modify(this.model).then(res => {
              if (res.success) {
                this.close()
                this.$emit('submit')
              }
            })
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    close () {
      this.$emit('update:visible', false)
    },
    handleTypeChange (type) {
      if (type == 'turnTable') {
        this.model.targetType = 'order'
      } else if (type == 'checkIn') {
        this.model.targetType = 'order'
      } else {
        this.model.targetType = type
      }
    },
    handleTimeChange (val) {
      if (val && val.length==2) {
        this.model.startTime = val[0]
        this.model.endTime = val[1]
      } else {
        this.model.startTime = ''
        this.model.endTime = ''
      }
    },
    handleTurnTableChange (items) {
      // console.log('handleTurnTableChange', items)
      this.model.turnTableItems = items
    }
  }
}
</script>
<style lang="scss">

</style>