import { formFetch, jsonFetch } from '@/utils/fetch'
import Qs from 'qs'

export function list () {
  return formFetch.post('/category/list')
}

export function switchStatus (id, available) {
  return formFetch.post(`/category/${id}/status`, Qs.stringify({ available }))
}

export function detail (id) {
  return formFetch.post(`/category/${id}/detail`)
}

export function create (category) {
  return jsonFetch.post(`/category/create`, category)
}

export function modify (category) {
  return jsonFetch.post(`/category/${category.id}/modify`, category)
}

export function del (id) {
  return formFetch.post(`/category/${id}/delete`)
}

export function sort (ids) {
  return jsonFetch.post(`/category/sort`, ids)
}

export function listGame (id) {
  return formFetch.post(`/category/${id}/listGame`)
}

export function saveGame (id, gameIds) {
  return jsonFetch.post(`/category/${id}/saveGame`, gameIds)
}