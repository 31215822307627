<template>    
  <div class="content">
    <el-table :data="tableData"
              ref="roleTable"
              size="mini"
              row-key="id"
              v-loading="loading"
              element-loading-text="加载中..."
              header-row-class-name="thead-light"
              @row-click="selectSingle">
      <el-table-column
        align="center"
      >
        <template slot-scope="scope">
          <el-radio v-model="selectedRole" :label="scope.row.id">
            &nbsp;
          </el-radio>
        </template>
      </el-table-column>
      <el-table-column
        v-for="column in tableColumns"
        :key="column.prop"
        :prop="column.prop"
        v-bind="column"
      >
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
import { Table, TableColumn, Select, Option, Radio } from 'element-ui';
import { list } from '@/api/system/role'
import { selectRole, grantRole } from '@/api/system/user'
export default {
  props: ['userId'],
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Radio.name]: Radio
  },
  data() {
    return {
      loading: false,
      tableColumns: [
        {
          prop: 'name',
          label: '名称',
          minWidth: 150,
          sortable: true
        },
        {
          prop: 'description',
          label: '说明',
          minWidth: 150
        }
      ],
      tableData: [],
      selectedRole: '',
    };
  },
  created() {
    this.getData()
  },
  methods: {
    selectSingle (row) {
      this.selectedRole = row.id
    },
    getData () {
      this.loading = true
      list().then(res => {
        if (res.success) {
          this.tableData = res.data
          selectRole(this.userId).then(res => {
            if (res.success) {
              this.$nextTick(function () {
                if (res.data.length > 0) {
                  this.selectedRole = res.data[0]
                  this.loading = false
                } else {
                  this.loading = false
                }
              })
            }
          })
        }
      })
    },
    grantRole () {
      if (this.selectedRole==='') {
        this.$bvToast.toast('请选择一个角色', {
          title: '提示',
          solid: true,
          variant: 'warning',
          autoHideDelay: 3000,
          toaster: 'b-toaster-top-center'
        })
        return
      }
      let roleIds = [this.selectedRole]
      this.loading = true
      grantRole(this.userId, roleIds).then(res => {
        this.loading = false
        if (res.success) {
          this.$emit('submit')
          this.$bvToast.toast('操作成功', {
            title: '提示',
            solid: true,
            variant: 'success',
            autoHideDelay: 3000,
            toaster: 'b-toaster-top-center'
          })
        }
      })
    }
  }
};
</script>
<style>
  .no-border-card .card-footer{
    border-top: 0;
  }
</style>
