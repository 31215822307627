<template>
  <el-dialog
    v-modal-drag
    width="80%"
    :visible.sync="visible"
    :title="'投注详情-'+issue"
    @close="close">
    <div class="content">
      <el-table :data="tableData"
                ref="table"
                size="mini"
                row-key="id"
                show-summary
                :summary-method="getSummaries"
                v-loading="loading"
                element-loading-text="加载中..."
                header-row-class-name="thead-light">
        <el-table-column prop="gameName" label="游戏" width="150">
        </el-table-column>
        <el-table-column prop="issue" label="期号" width="150">
        </el-table-column>
        <el-table-column label="玩法" width="150">
          <div slot-scope="{$index, row}" class="d-flex">
            {{row.optionName}} - {{row.betName}}
          </div>
        </el-table-column>
        <el-table-column prop="odds" label="赔率" width="80">
        </el-table-column>
        <el-table-column prop="amount" label="金额" width="150">
          <div slot-scope="{$index, row}" class="d-flex">
            {{row.amount}}
            <el-tag v-if="row.memberType=='experience'" class="margin-left-sm" type="warning">体验金</el-tag>
          </div>
        </el-table-column>
        <el-table-column prop="state" label="状态" align="center">
          <template slot-scope="scope">
            {{scope.row.state | orderState}}
          </template>
        </el-table-column>
        <el-table-column prop="profit" label="结果" width="100">
          <template slot-scope="scope">
            <span :class="scope.row.profit>=0?'text-green':'text-red'">{{scope.row.profit}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="投注时间" width="150">
        </el-table-column>
        <el-table-column prop="drawCode" label="开奖结果">
        </el-table-column>
      </el-table>
    </div>
    <div slot="footer">
    </div>
  </el-dialog>
</template>

<script>
import { orderDetail } from '@/api/member'
import dayjs from 'dayjs'
export default {
  props: ['visible','uid','issue'],
  data() {
    return {
      loading: false,
      tableData: []
    };
  },
  created() {
    this.getData()
  },
  methods: {
    getData () {
      this.loading = true
      orderDetail(this.uid, this.issue).then(res => {
        this.loading = false
        if (res.success) {
          this.totalCount = res.totalCount
          this.tableData = res.data
        }
      })
    },
    close () {
      this.$emit('update:visible', false)
    },
    getSummaries (param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        }
        if (!column.property || column.property!='amount' && column.property!='profit') {
          sums[index] = ''
          return
        }
        const values = data.map(item => Number(item[column.property]))
        console.log('column', column)
        if (!values.every(value => isNaN(value))) {
          sums[index] = values
            .reduce((prev, curr) => {
              const value = Number(curr)
              if (!isNaN(value)) {
                return prev + curr
              } else {
                return prev
              }
            }, 0)
            .toFixed(2)
        } else {
          sums[index] = 'N/A'
        }
      })
      return sums
    }
  }
};
</script>