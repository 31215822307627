<template>    
  <div class="content issue">
    <base-header class="pb-6">
      <b-row align-v="center" class="py-4">
        <b-col cols="7" lg="6">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-breadcrumb></route-breadcrumb>
          </nav>
        </b-col>
        <b-col lg="6" cols="5" class="text-right">
        </b-col>
      </b-row>
    </base-header>
    <b-container fluid class="mt--6">
      <div>
        <card class="no-border-card" body-classes="px-0 pb-1" footer-classes="pb-2">
          <div>
            <el-form inline class="pl-3">
              <el-form-item prop="gameCode" label="彩种">
                <game-select v-model="query.filter.gameCode" thirdPart="OWN" @change="getData" clearable></game-select>
              </el-form-item>
              <el-form-item prop="drawIssue" label="期号">
                <el-input v-model="query.filter.drawIssue" clearable style="width:150"></el-input>
              </el-form-item>
              <el-form-item label="">
                <date-range v-model="daterange" mode="button" @change="handleDateRangeChange"></date-range>
              </el-form-item>
              <el-form-item>
                <base-button type="primary" size="sm" icon class="btn-icon" @click="getData">
                  <span><i class="fa fa-search"></i></span>
                  <span>搜索</span>
                </base-button>
                <base-button type="secondary" size="sm" icon class="btn-icon" @click="reset">
                  <span><i class="fa fa-mail-reply"></i></span>
                  <span>重置</span>
                </base-button>
              </el-form-item>
              <div class="float-right">
              </div>
            </el-form>
            <el-table :data="tableData"
                      size="small"
                      row-key="id"
                      border
                      v-loading="loading"
                      header-row-class-name="thead-light"
                      :row-class-name="tableRowClassName">
              <el-table-column prop="id" label="序号" width="100" align="center">
              </el-table-column>
              <el-table-column prop="gameName" label="游戏名称" width="250" align="center">
                <template v-slot="{row}">
                  <div class="flex justify-center">
                    <span>{{ row.gameCode }}-{{ row.gameName }}</span>
                    <div class="flex">
                      <el-tag v-if="row.self" type="success" class="margin-left-sm">自开</el-tag>
                      <el-tag type="warning" class="margin-left-sm">{{ row.gameType }}</el-tag>
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="drawIssue" label="期号" width="150" align="center"></el-table-column>
              <el-table-column prop="drawTime" label="开奖时间" width="150" align="center"></el-table-column>
              <el-table-column prop="drawCode" label="开奖结果" width="350" align="center">
                <div slot-scope="{$index, row}" class="flex justify-center">
                  <vac v-if="isNew(row) && row.self" :end-time="row.drawTime" @finish="handleCountDownFinished">
                    <template
                      v-slot:process="anyYouWantedScopName">
                      <span class="text-red text-bold text-xl">{{ anyYouWantedScopName.timeObj.ceil.s }}</span><span> 秒后开奖</span>
                    </template>
                    <div
                      slot="finish"
                      v-loading="opening"
                      element-loading-spinner="el-icon-loading"
                      element-loading-background="#ffe8cc"
                      element-loading-text="开奖中...">
                      <div style="width:100px">&nbsp;</div>
                    </div>
                  </vac>
                  <span class="margin-left">
                    <draw-code-result v-if="row.drawCode" :gameType="row.gameType" :code="row.drawCode"></draw-code-result>
                  </span>
                </div>
              </el-table-column>
              <el-table-column width="170px" align="center" label="操作/备注">
                <div slot-scope="{$index, row}" class="flex justify-center">
                  <base-button
                    v-if="!row.opened && row.self"
                    @click.native="showModal(row, 'form')"
                    type="info"
                    size="sm"
                    outline
                  >
                    <i class="fa fa-edit"></i>手动开奖
                  </base-button>
                  <base-button
                    v-if="!row.opened && row.self && row.drawCode"
                    @click.native="cancelDraw(row)"
                    type="danger"
                    size="sm"
                    outline
                  >
                    <i class="fa fa-edit"></i>撤销
                  </base-button>
                  <span v-if="row.opened && row.manual">{{row.operator}}手动开奖</span>
                </div>
              </el-table-column>
            </el-table>
          </div>
          <div slot="footer">
            <base-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :query="query"
              :total="totalCount">
            </base-pagination>
          </div>
        </card>
      </div>
    </b-container>
    <!--Modals-->
    <open-form v-if="modal.form" :visible.sync="modal.form" :issue="issue" @submit="handleOpenDone"></open-form>
  </div>
</template>
<script>
import dayjs from 'dayjs'
import DateRange from '@/components/DateRange'
import GameSelect from '@/views/Components/game-select'
import DrawCodeResult from '@/views/Components/DrawCodeResult'
import OpenForm from './form'
import { list, cancelOpen } from '@/api/config/issue'
export default {
  components: {
    DateRange,
    GameSelect,
    DrawCodeResult,
    OpenForm
  },
  data() {
    return {
      loading: false,
      opening: false,
      query: {
        filter: {
          gameCode: '',
          drawIssue: '',
          startDate: dayjs().format('YYYY-MM-DD'),
          endDate: dayjs().format('YYYY-MM-DD')
        },
        start: 0,
        limit: 10,
        sort: null
      },
      daterange: ['', ''],
      totalCount: 0,
      tableData: [],
      selectedRows: [],
      issue: '',
      modal: {
        form: false
      },
    };
  },
  created () {
    this.getData()
  },
  methods: {
    handleSizeChange () {
      this.getData()
    },
    handleCurrentChange () {
      this.getData()
    },
    getData(clear) {
      this.loading = true
      if (clear) {
        this.tableData = []
      }
      list(this.query).then(res => {
        this.loading = false
        if (res.success) {
          this.totalCount = res.totalCount
          this.tableData = res.data
        }
      })
    },
    reset () {
      this.query = {
        filter: {
          gameCode: '',
          drawIssue: '',
          startDate: this.daterange[0],
          endDate: this.daterange[1]
        },
        start: 0,
        limit: 10,
        sort: null
      }
      this.getData()
    },
    handleDateRangeChange (val) {
      if (!val || !val.daterange) {
        val = {daterange: ['', '']}
      }
      this.daterange = val.daterange
      this.query.filter.startDate = val.daterange[0]
      this.query.filter.endDate = val.daterange[1]
      this.getData()
    },
    isNew(issue) {
      if (!issue.opened && dayjs().isBefore(dayjs(issue.drawTime).add(3,'second'))) {
        return true
      }
      return false
    },
    tableRowClassName ({row, rowIndex}) {
      if (this.isNew(row)) {
        return 'warning-row'
      }
      return ''
    },
    handleCountDownFinished () {
      let that = this
      that.opening = true
      setTimeout(function () {
        that.opening = false
        that.getData()
      }, 2000)
    },
    showModal(issue, type) {
      this.issue = issue
      this.modal[type] = true
    },
    handleOpenDone () {
      this.modal.form = false
      this.getData()
    },
    cancelDraw (row) {
      this.$bvModal.msgBoxConfirm('确定撤销?', {
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: '确定',
        cancelTitle: '取消',
        centered: true
      })
        .then(value => {
          if (value) {
            cancelOpen(row).then(res => {
              if (res.success) {
                this.getData()
                this.$bvToast.toast('操作成功', {
                  title: '提示',
                  solid: true,
                  variant: 'success',
                  autoHideDelay: 3000,
                  toaster: 'b-toaster-top-center'
                })
              }
            })
          }
        })
    }
  }
};
</script>
<style lang="scss">
  .no-border-card .card-footer{
    border-top: 0;
  }
  .issue {
    .el-loading-spinner {
      display: flex;
      align-items: center;
      margin-top: -15px;
    }
    .el-loading-spinner i {
      font-size: 24px;
    }
    .el-table .cell {
      white-space: inherit!important;
    }
    .el-table .warning-row {
      background-color: #ffe8cc;
    }
  }
</style>
