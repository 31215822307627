import { formFetch, jsonFetch } from '@/utils/fetch'
import Qs from 'qs'

export function list (query) {
  return jsonFetch.post('/red-pack/list', query)
}
export function switchStatus (id, available) {
  return formFetch.post(`/red-pack/${id}/status`, Qs.stringify({ available }))
}

export function detail (id) {
  return formFetch.post(`/red-pack/${id}/detail`)
}

export function create (redPack) {
  return jsonFetch.post(`/red-pack/create`, redPack)
}

export function modify (redPack) {
  return jsonFetch.post(`/red-pack/${redPack.id}/modify`, redPack)
}

export function record (id, query) {
  return jsonFetch.post(`/red-pack/${id}/record`, query)
}