<template>
  <div>
    <el-form inline>
      <el-form-item label="平台类型">
        <el-select v-model="platType">
          <el-option v-for="item in platTypeList" :key="item.id" :value="item.code" :label="item.code+' - '+item.name">
            <div class="flex align-center justify-between">
              <span>{{ item.code }}</span>
              <span>{{ item.name }}</span>
            </div>
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="游戏类型">
        <el-select v-model="gameType" clearable>
          <el-option v-for="item in gameTypeList" :value="item.value" :label="item.label"></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <el-table :data="gameList"
              size="mini"
              max-height="500"
              row-key="gameCode"
              border
              v-loading="loading"
              header-row-class-name="thead-light">
      <el-table-column prop="gameCode" label="游戏代码" width="100" align="center"></el-table-column>
      <el-table-column prop="gameType" label="游戏类型" width="100" align="center">
        <div slot-scope="{$index, row}" class="flex justify-center">
          {{ row.gameType | gameType }}
        </div>
      </el-table-column>
      <el-table-column prop="gameName" label="游戏名称" width="200" align="center">
        <div slot-scope="{$index, row}" class="flex justify-center">
          {{ row.gameName['zh-hans'] }}
        </div>
      </el-table-column>
      <el-table-column width="150px" align="center" label="操作">
        <div slot-scope="{$index, row}" class="flex justify-center">
          <base-button
            @click.native="handleSelect(row)"
            type="info"
            size="sm"
            outline
          >
            <i class="fa fa-edit"></i>选择
          </base-button>
        </div>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
import { listNgPlatType, listNgGame } from '@/api/config/game'
export default {
  data() {
    return {
      loading: false,
      platType: '',
      platTypeList: [],
      gameType: '',
      gameTypeList: [
        {value: '1', label:'视讯'},
        {value: '2', label:'老虎机'},
        {value: '3', label:'彩票'},
        {value: '4', label:'体育'},
        {value: '5', label:'电竞'},
        {value: '6', label:'捕猎'},
        {value: '7', label:'棋牌'}
      ],
      tableData: [],
    }
  },
  computed: {
    gameList () {
      const that = this
      if (this.gameType=='') return that.tableData
      return this.tableData.filter(item => {
        return item.gameType == that.gameType
      })
    }
  },
  watch: {
    'platType': {
      handler: function(val) {
        if (val) {
          this.getData()
        }
      },
      immediate: true
    }
  },
  filters: {
    gameType (value) {
      if (value=='1') return '视讯'
      if (value=='2') return '老虎机'
      if (value=='3') return '彩票'
      if (value=='4') return '体育'
      if (value=='5') return '电竞'
      if (value=='6') return '捕猎'
      if (value=='7') return '棋牌'
      return value
    }
  },
  created () {
    listNgPlatType().then(res => {
      if (res.success) {
        this.platTypeList = res.data
      }
    })
  },
  methods: {
    getData () {
      this.loading = true
      listNgGame(this.platType).then(res => {
        this.loading = false
        if (res.success) {
          this.tableData = res.data
        }
      })
    },
    handleSelect (row) {
      this.$emit('select', row)
    }
  }
}
</script>