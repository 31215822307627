<template>
  <div class="pc">
    <b-button
      id="edit-btn"
      class="edit-btn"
      variant="outline-primary"
      size="sm"
      icon
    >
      <i class="fa fa-edit"></i>批量修改
    </b-button>
    <base-button
      class="save-btn"
      @click.native="save"
      :loading="loading"
      type="primary"
      size="sm"
      icon
    >
      <i class="fa fa-check"></i>保存
    </base-button>
    <el-form label-position="right" label-width="50px">
      <el-tabs v-model="activeName" type="border-card">
        <el-tab-pane label="双面" name="sm">
          <title-bar title="极大/极小设置"></title-bar>
          <el-row class="flex align-center">
            <el-col :span="8">
              <el-form-item label="极大：3个数字总和>=" label-width="160px">
                <el-input size="mini" type="number" maxlength="2" v-model="conf.param['pc28_extreme_big']" style="width:60px"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="极小：3个数字总和<=" label-width="160px">
                <el-input size="mini" type="number" maxlength="2" v-model="conf.param['pc28_extreme_small']" style="width:60px"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <title-bar title="双面"></title-bar>
          <el-row class="flex align-center">
            <el-col :span="3">
              <el-form-item label="大"><el-input size="mini" v-model="conf.sm['big']"></el-input></el-form-item>
            </el-col>
            <el-col :span="3">
              <el-form-item label="小"><el-input size="mini" v-model="conf.sm['small']"></el-input></el-form-item>
            </el-col>
            <el-col :span="3">
              <el-form-item label="单"><el-input size="mini" v-model="conf.sm['single']"></el-input></el-form-item>
            </el-col>
            <el-col :span="3">
              <el-form-item label="双"><el-input size="mini" v-model="conf.sm['double']"></el-input></el-form-item>
            </el-col>
          </el-row>
          <el-row class="flex align-center">
            <el-col :span="3">
              <el-form-item label="小单"><el-input size="mini" v-model="conf.sm['smallsingle']"></el-input></el-form-item>
            </el-col>
            <el-col :span="3">
              <el-form-item label="小双"><el-input size="mini" v-model="conf.sm['smalldouble']"></el-input></el-form-item>
            </el-col>
            <el-col :span="3">
              <el-form-item label="大单"><el-input size="mini" v-model="conf.sm['bigsingle']"></el-input></el-form-item>
            </el-col>
            <el-col :span="3">
              <el-form-item label="大双"><el-input size="mini" v-model="conf.sm['bigdouble']"></el-input></el-form-item>
            </el-col>
          </el-row>
          <el-row class="flex align-center">
            <el-col :span="3">
              <el-form-item label="极大"><el-input size="mini" v-model="conf.sm['extremebig']"></el-input></el-form-item>
            </el-col>
            <el-col :span="3">
              <el-form-item label="极小"><el-input size="mini" v-model="conf.sm['extremesmall']"></el-input></el-form-item>
            </el-col>
          </el-row>
          <title-bar title="特殊情况(总和=13/14，或开出对子/顺子/豹子)" class="margin-top"></title-bar>
          <el-row class="flex align-center">
            <el-col :span="3">
              <el-form-item label="大"><el-input size="mini" v-model="conf.sm['big-2']"></el-input></el-form-item>
            </el-col>
            <el-col :span="3">
              <el-form-item label="小"><el-input size="mini" v-model="conf.sm['small-2']"></el-input></el-form-item>
            </el-col>
            <el-col :span="3">
              <el-form-item label="单"><el-input size="mini" v-model="conf.sm['single-2']"></el-input></el-form-item>
            </el-col>
            <el-col :span="3">
              <el-form-item label="双"><el-input size="mini" v-model="conf.sm['double-2']"></el-input></el-form-item>
            </el-col>
          </el-row>
          <el-row class="flex align-center">
            <el-col :span="3">
              <el-form-item label="小单"><el-input size="mini" v-model="conf.sm['smallsingle-2']"></el-input></el-form-item>
            </el-col>
            <el-col :span="3">
              <el-form-item label="小双"><el-input size="mini" v-model="conf.sm['smalldouble-2']"></el-input></el-form-item>
            </el-col>
            <el-col :span="3">
              <el-form-item label="大单"><el-input size="mini" v-model="conf.sm['bigsingle-2']"></el-input></el-form-item>
            </el-col>
            <el-col :span="3">
              <el-form-item label="大双"><el-input size="mini" v-model="conf.sm['bigdouble-2']"></el-input></el-form-item>
            </el-col>
          </el-row>
        </el-tab-pane>
        <el-tab-pane label="特码" name="tm">
          <el-row class="flex align-center">
            <el-col :span="3">
              <el-form-item label="0"><el-input size="mini" v-model="conf.tm['0']"></el-input></el-form-item>
            </el-col>
            <el-col :span="3">
              <el-form-item label="1"><el-input size="mini" v-model="conf.tm['1']"></el-input></el-form-item>
            </el-col>
            <el-col :span="3">
              <el-form-item label="2"><el-input size="mini" v-model="conf.tm['2']"></el-input></el-form-item>
            </el-col>
            <el-col :span="3">
              <el-form-item label="3"><el-input size="mini" v-model="conf.tm['3']"></el-input></el-form-item>
            </el-col>
            <el-col :span="3">
              <el-form-item label="4"><el-input size="mini" v-model="conf.tm['4']"></el-input></el-form-item>
            </el-col>
            <el-col :span="3">
              <el-form-item label="5"><el-input size="mini" v-model="conf.tm['5']"></el-input></el-form-item>
            </el-col>
            <el-col :span="3">
              <el-form-item label="6"><el-input size="mini" v-model="conf.tm['6']"></el-input></el-form-item>
            </el-col>
            <el-col :span="3">
              <el-form-item label="7"><el-input size="mini" v-model="conf.tm['7']"></el-input></el-form-item>
            </el-col> 
          </el-row>
          <el-row class="flex align-center">
            <el-col :span="3">
              <el-form-item label="8"><el-input size="mini" v-model="conf.tm['8']"></el-input></el-form-item>
            </el-col>
            <el-col :span="3">
              <el-form-item label="9"><el-input size="mini" v-model="conf.tm['9']"></el-input></el-form-item>
            </el-col>
            <el-col :span="3">
              <el-form-item label="10"><el-input size="mini" v-model="conf.tm['10']"></el-input></el-form-item>
            </el-col>
            <el-col :span="3">
              <el-form-item label="11"><el-input size="mini" v-model="conf.tm['11']"></el-input></el-form-item>
            </el-col>
            <el-col :span="3">
              <el-form-item label="12"><el-input size="mini" v-model="conf.tm['12']"></el-input></el-form-item>
            </el-col>
            <el-col :span="3">
              <el-form-item label="13"><el-input size="mini" v-model="conf.tm['13']"></el-input></el-form-item>
            </el-col>
            <el-col :span="3">
              <el-form-item label="14"><el-input size="mini" v-model="conf.tm['14']"></el-input></el-form-item>
            </el-col>
            <el-col :span="3">
              <el-form-item label="15"><el-input size="mini" v-model="conf.tm['15']"></el-input></el-form-item>
            </el-col> 
          </el-row>
          <el-row class="flex align-center">
            <el-col :span="3">
              <el-form-item label="16"><el-input size="mini" v-model="conf.tm['16']"></el-input></el-form-item>
            </el-col>
            <el-col :span="3">
              <el-form-item label="17"><el-input size="mini" v-model="conf.tm['17']"></el-input></el-form-item>
            </el-col>
            <el-col :span="3">
              <el-form-item label="18"><el-input size="mini" v-model="conf.tm['18']"></el-input></el-form-item>
            </el-col>
            <el-col :span="3">
              <el-form-item label="19"><el-input size="mini" v-model="conf.tm['19']"></el-input></el-form-item>
            </el-col>
            <el-col :span="3">
              <el-form-item label="20"><el-input size="mini" v-model="conf.tm['20']"></el-input></el-form-item>
            </el-col>
            <el-col :span="3">
              <el-form-item label="21"><el-input size="mini" v-model="conf.tm['21']"></el-input></el-form-item>
            </el-col>
            <el-col :span="3">
              <el-form-item label="22"><el-input size="mini" v-model="conf.tm['22']"></el-input></el-form-item>
            </el-col>
            <el-col :span="3">
              <el-form-item label="23"><el-input size="mini" v-model="conf.tm['23']"></el-input></el-form-item>
            </el-col> 
          </el-row>
          <el-row class="flex align-center">
            <el-col :span="3">
              <el-form-item label="24"><el-input size="mini" v-model="conf.tm['24']"></el-input></el-form-item>
            </el-col>
            <el-col :span="3">
              <el-form-item label="25"><el-input size="mini" v-model="conf.tm['25']"></el-input></el-form-item>
            </el-col>
            <el-col :span="3">
              <el-form-item label="26"><el-input size="mini" v-model="conf.tm['26']"></el-input></el-form-item>
            </el-col>
            <el-col :span="3">
              <el-form-item label="27"><el-input size="mini" v-model="conf.tm['27']"></el-input></el-form-item>
            </el-col>
          </el-row>
        </el-tab-pane>
        <el-tab-pane label="特殊" name="ts">
          <el-row class="flex align-center">
            <el-col :span="4">
              <el-form-item label="对子"><el-input size="mini" v-model="conf.ts['3']"></el-input></el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item label="顺子"><el-input size="mini" v-model="conf.ts['2']"></el-input></el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item label="豹子"><el-input size="mini" v-model="conf.ts['4']"></el-input></el-form-item>
            </el-col>
          </el-row>
        </el-tab-pane>
      </el-tabs>
    </el-form>
    <b-popover target="edit-btn" triggers="click blur">
      <template #title>
        <b-button @click="hideBatchEdit" class="close" aria-label="Close">
          <span></span>
        </b-button>
        批量修改
      </template>
      <b-input-group>
        <b-form-input v-model="globalVal"></b-form-input>
        <b-input-group-append>
          <b-button size="sm" variant="outline-primary" @click="submitBatchEdit">确定</b-button>
        </b-input-group-append>
      </b-input-group>
    </b-popover>
  </div>
</template>

<script>
const initOdds = {
  sm: {'option': 'sm', 
    'extremebig':0, 'extremesmall':0, 'big':0, 'small':0, 'single':0, 'double':0, 'bigdouble':0, 'smallsingle':0, 'bigsingle':0, 'smalldouble':0,
    'big-2':0, 'small-2':0, 'single-2':0, 'double-2':0, 'bigdouble-2':0, 'smallsingle-2':0, 'bigsingle-2':0, 'smalldouble-2':0},
  tm: {'option': 'tm', '0':0, '1':0, '2':0, '3':0, '4':0, '5':0, '6':0, '7':0, '8':0, 
    '9':0, '10':0,'11':0, '12':0, '13':0, '14':0, '15':0, '16':0, '17':0, 
    '18':0, '19':0, '20':0, '21':0, '22':0, '23':0, '24':0, '25':0, '26':0, '27':0},
  ts: {'option': 'ts', '3':0, '2':0, '4':0},
  param: {
    'pc28_extreme_big': 20,
    'pc28_extreme_small': 5
  }
}
import TitleBar from '@/components/TitleBar'
import { list, save } from '@/api/config/odds'
import { get, modify } from '@/api/config/param'
export default {
  props: ['gameCode'],
  components: {
    TitleBar
  },
  data () {
    return {
      loading: false,
      conf: initOdds,
      activeName: 'sm',
      globalVal: 0
    }
  },
  computed: {
    config() {
      return [].concat(this.conf.sm).concat(this.conf.tm).concat(this.conf.ts)
    }
  },
  watch: {
    'gameCode': {
      handler (val) {
        this.loading = true
        this.conf = initOdds
        get('pc28_extreme_big', 'pc28_extreme_small').then(res => {
          if (res.success) {
            this.conf.param.pc28_extreme_big = res.data.pc28_extreme_big ? res.data.pc28_extreme_big : 20
            this.conf.param.pc28_extreme_small = res.data.pc28_extreme_small ? res.data.pc28_extreme_small : 5
          }
        })
        list(val).then(res => {
          this.loading = false
          if (res.success) {
            for (let key in this.conf.sm) {
              if (res.data.sm[key]) {
                this.conf.sm[key] = res.data.sm[key]
              }
            }
            for (let key in this.conf.tm) {
              if (res.data.tm[key]) {
                this.conf.tm[key] = res.data.tm[key]
              }
            }
            for (let key in this.conf.ts) {
              if (res.data.ts[key]) {
                this.conf.ts[key] = res.data.ts[key]
              }
            }
          }
        })
      },
      immediate: true
    }
  },
  methods: {
    save () {
      this.loading = true
      modify(this.conf.param).then(res => {
        if (res.success) {
          save(this.gameCode, this.config).then(res => {
            this.loading = false
            if (res.success) {
              this.$bvToast.toast('操作成功', {
                title: '提示',
                solid: true,
                variant: 'success',
                autoHideDelay: 3000,
                toaster: 'b-toaster-top-center'
              })
            }
          })
        }
      })
    },
    hideBatchEdit () {
      this.$root.$emit('bv::hide::popover')
    },
    submitBatchEdit () {
      if (this.activeName=='sm') {
        for (let key in this.conf.sm) {
          if (key!='option') {
            this.conf.sm[key] = this.globalVal
          }
        }
      } else if (this.activeName=='tm') {
        for (let key in this.conf.tm) {
          if (key!='option') {
            this.conf.tm[key] = this.globalVal
          }
        }
      } else if (this.activeName=='ts') {
        for (let key in this.conf.ts) {
          if (key!='option') {
            this.conf.ts[key] = this.globalVal
          }
        }
      }
      this.hideBatchEdit()
    }
  }
}
</script>

<style lang="scss">
.pc {
  .el-input {
    width: 65px;
  }
  .save-btn {
    position: absolute;
    z-index: 100;
    top: 95px;
    right: 16px
  }
  .edit-btn {
    position: absolute;
    z-index: 100;
    top: 95px;
    right: 100px;
  }
  .el-form-item {
    margin-bottom: 0;
  }
}
</style>