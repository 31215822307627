import { formFetch, jsonFetch } from '@/utils/fetch'
import Qs from 'qs'

export function list () {
  return formFetch.post('/menu/list')
}

export function switchStatus (id, available) {
  return formFetch.post(`/menu/${id}/status`, Qs.stringify({ available }))
}

export function detail (id) {
  return formFetch.post(`/menu/${id}/detail`)
}

export function create (menu) {
  return jsonFetch.post(`/menu/create`, menu)
}

export function modify (menu) {
  return jsonFetch.post(`/menu/${menu.id}/modify`, menu)
}

export function del (id) {
  return formFetch.post(`/menu/${id}/delete`)
}