<template>
  <div v-loading="loading" class="param">
    <base-header class="pb-6">
      <b-row align-v="center" class="py-4">
        <b-col cols="7" lg="6">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-breadcrumb></route-breadcrumb>
          </nav>
        </b-col>
        <b-col lg="6" cols="5" class="text-right">
        </b-col>
      </b-row>
    </base-header>
    <b-container fluid class="mt--6">
      <b-card no-body>
        <!-- Card header -->
        <b-card-header>
          <div>
            <div class="float-left"><h3 class="mb-0">机器人配置</h3></div>
            <div class="float-right">
              <base-button type="secondary" size="sm" icon class="btn-icon" @click="getData">
                <span><i class="fa fa-refresh"></i></span>
                <span>刷新</span>
              </base-button>
              <base-button type="primary" size="sm" icon @click.native="save">
                <i class="fa fa-check"></i>保存
              </base-button>
            </div>
          </div>
        </b-card-header>
        <!-- Card body -->
        <b-card-body>
          <el-form :model="model" ref="form" label-position="top">
            <el-row :gutter="10">
              <el-col :span="12">
                <el-form-item prop="robot_play_min" label="最小投注次数" required>
                  <el-input v-model="model.robot_play_min"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item prop="robot_play_max" label="最大投注次数" required>
                  <el-input v-model="model.robot_play_max"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="10">
              <el-col :span="24">
                <el-form-item prop="robot_amount_list" label="可选投注金额，逗号分隔" required>
                  <el-input v-model="model.robot_amount_list"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </b-card-body>
      </b-card>
    </b-container>
  </div>
</template>

<script>
import { get, modify } from '@/api/config/param'
export default {
  data () {
    return {
      loading: false,
      model: {
        robot_play_min: 0,
        robot_play_max: 0,
        robot_amount_list: ''
      }
    }
  },
  created () {
    this.getData()
  },
  methods: {
    getData () {
      this.loading = true
      get('robot_play_min', 'robot_play_max', 'robot_amount_list').then(res => {
        this.loading = false
        if (res.success) {
          this.model = res.data
        }
      })
    },
    save () {
      if (this.model.robot_play_min > this.model.robot_play_max) {
        this.$bvToast.toast('最小次数大于最大次数', {
          title: '提示',
          solid: true,
          variant: 'error',
          autoHideDelay: 2000,
          toaster: 'b-toaster-top-center'
        })
        return false
      }
      this.model.robot_amount_list = this.model.robot_amount_list.replaceAll('，', ',')
      modify(this.model).then(res => {
        if (res.success) {
          this.$bvToast.toast('操作成功', {
            title: '提示',
            solid: true,
            variant: 'success',
            autoHideDelay: 3000,
            toaster: 'b-toaster-top-center'
          })
        }
      })
    }
  }
}
</script>
<style lang="scss">
.param {
  .el-upload-list--picture-card .el-upload-list__item {
    height: 100%;
  }
  .el-upload-list--picture-card .el-upload-list__item-thumbnail {
  width: 148px;
  height: 100%;
}
}
</style>