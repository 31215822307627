import { formFetch, jsonFetch } from '@/utils/fetch'
import Qs from 'qs'

export function list (query) {
  return jsonFetch.post('/robot/list', query)
}

export function switchStatus (id, available) {
  return formFetch.post(`/robot/${id}/status`, Qs.stringify({ available }))
}

export function detail (id) {
  return formFetch.post(`/robot/${id}/detail`)
}

export function create (robot) {
  return jsonFetch.post(`/robot/create`, robot)
}

export function modify (robot) {
  return jsonFetch.post(`/robot/${robot.id}/modify`, robot)
}

export function del (id) {
  return formFetch.post(`/robot/${id}/delete`)
}