import { formFetch, jsonFetch } from '@/utils/fetch'
import Qs from 'qs'

export function list (query) {
  return jsonFetch.post('/activity/list', query)
}

export function switchStatus (id, available) {
  return formFetch.post(`/activity/${id}/status`, Qs.stringify({ available }))
}

export function detail (id) {
  return formFetch.post(`/activity/${id}/detail`)
}

export function create (activity) {
  return jsonFetch.post(`/activity/create`, activity)
}

export function modify (activity) {
  return jsonFetch.post(`/activity/${activity.id}/modify`, activity)
}

export function del (id) {
  return formFetch.post(`/activity/${id}/delete`)
}

export function sort (ids) {
  return jsonFetch.post(`/activity/sort`, ids)
}

export function record (id, query) {
  return jsonFetch.post(`/activity/${id}/record`, query)
}
