<template>
  <div v-loading="loading" class="param">
    <base-header class="pb-6">
      <b-row align-v="center" class="py-4">
        <b-col cols="7" lg="6">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-breadcrumb></route-breadcrumb>
          </nav>
        </b-col>
        <b-col lg="6" cols="5" class="text-right">
        </b-col>
      </b-row>
    </base-header>
    <b-container fluid class="mt--6">
      <b-card no-body>
        <!-- Card header -->
        <b-card-header>
          <div>
            <div class="float-left"><h3 class="mb-0">系统配置</h3></div>
            <div class="float-right">
              <base-button type="secondary" size="sm" icon class="btn-icon" @click="getData">
                <span><i class="fa fa-refresh"></i></span>
                <span>刷新</span>
              </base-button>
              <base-button type="primary" size="sm" icon @click.native="save">
                <i class="fa fa-check"></i>保存
              </base-button>
            </div>
          </div>
        </b-card-header>
        <!-- Card body -->
        <b-card-body>
          <el-form :model="model" ref="form" label-position="left">
            <el-row :gutter="10">
              <el-col :span="16">
                <el-form-item prop="site_state" label="站点开关" class="flex align-center" required>
                  <div class="flex align-center">
                    <base-switch 
                      v-model="model.site_state" on-text="开启" off-text="关闭" 
                      type="success" size="sm">
                    </base-switch>
                    <span class="margin-left text-gray">站点关闭后将不能访问，后台可正常登录</span>
                  </div>
                </el-form-item>
                <el-form-item prop="force_wx" label="强制微信浏览器" class="flex align-center" required>
                  <div class="flex align-center">
                    <base-switch 
                      v-model="model.force_wx" on-text="开启" off-text="关闭" 
                      type="success" size="sm">
                    </base-switch>
                  </div>
                </el-form-item>
                <el-form-item prop="allow_register" label="开放自主注册" class="flex align-center" required>
                  <div class="flex align-center">
                    <base-switch 
                      v-model="model.allow_register" on-text="开启" off-text="关闭" 
                      type="success" size="sm">
                    </base-switch>
                  </div>
                </el-form-item>
                <el-form-item prop="show_third_part_game" label="显示第三方游戏" class="flex align-center" required>
                  <div class="flex align-center">
                    <base-switch 
                      v-model="model.show_third_part_game" on-text="开启" off-text="关闭" 
                      type="success" size="sm">
                    </base-switch>
                  </div>
                </el-form-item>
                <el-form-item prop="site_title" label="站点标题" required>
                  <el-input v-model="model.site_title"></el-input>
                </el-form-item>
                <el-form-item prop="api_url" label="数据接口" required>
                  <el-input v-model="model.api_url"></el-input>
                </el-form-item>
                <el-form-item prop="redirect_url" label="跳转网址" required>
                  <el-input v-model="model.redirect_url"></el-input>
                </el-form-item>
                <el-form-item prop="room_quick_amounts" label="快捷投注金额，逗号分隔，最多7个" required>
                  <el-input v-model="model.room_quick_amounts"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="推广页背景图" label-width="150px">
                  <base-upload
                    :img-name="model.promotionBgImg"
                    :img-size="50" 
                    @success="handlePromotionBgUploadSuccess">
                  </base-upload>
                </el-form-item>
                <el-form-item label="推广二维码LOGO" label-width="150px">
                  <base-upload
                    :img-name="model.promotionLogoImg"
                    :img-size="50" 
                    @success="handlePromotionLogoUploadSuccess">
                  </base-upload>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </b-card-body>
      </b-card>
    </b-container>
  </div>
</template>

<script>
import BaseUpload from '@/components/BaseUpload'
import { get, modify } from '@/api/config/param'
export default {
  components: {
    BaseUpload
  },
  data () {
    return {
      loading: false,
      model: {
        api_url: '',
        redirect_url: '',
        site_state: true,
        site_title: '',
        force_wx: true,
        allow_register: false,
        promotionBgImg: '',
        promotionLogoImg: '',
        room_quick_amounts: '',
        show_third_part_game: false
      }
    }
  },
  created () {
    this.getData()
  },
  methods: {
    getData () {
      this.loading = true
      get('site_state','site_title','api_url','redirect_url','force_wx', 'allow_register',
      'promotionBgImg', 'promotionLogoImg', 'room_quick_amounts', 'show_third_part_game').then(res => {
        this.loading = false
        if (res.success) {
          this.model = res.data
          this.model.site_state = res.data.site_state=='true'
          this.model.force_wx = res.data.force_wx=='true'
          this.model.allow_register = res.data.allow_register=='true'
          this.model.show_third_part_game = res.data.show_third_part_game=='true'
        }
      })
    },
    save () {
      this.model.room_quick_amounts = this.model.room_quick_amounts.replaceAll('，', ',')
      modify(this.model).then(res => {
        if (res.success) {
          this.$bvToast.toast('操作成功', {
            title: '提示',
            solid: true,
            variant: 'success',
            autoHideDelay: 3000,
            toaster: 'b-toaster-top-center'
          })
        }
      })
    },
    handlePromotionBgUploadSuccess (fileName) {
      this.model.promotionBgImg = fileName
    },
    handlePromotionLogoUploadSuccess (fileName) {
      this.model.promotionLogoImg = fileName
    },
  }
}
</script>
<style lang="scss">
.param {
  .el-upload-list--picture-card .el-upload-list__item {
    height: 100%;
  }
  .el-upload-list--picture-card .el-upload-list__item-thumbnail {
  width: 148px;
  height: 100%;
}
}
</style>