import { formFetch, jsonFetch } from '@/utils/fetch'
import Qs from 'qs'

export function orderList (query) {
  return jsonFetch.post('/monitor/orderList', query)
}

export function orderStat (query) {
  return jsonFetch.post('/monitor/orderStat', query)
}
