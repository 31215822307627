<template>
  <div v-loading="loading" class="param">
    <base-header class="pb-6">
      <b-row align-v="center" class="py-4">
        <b-col cols="7" lg="6">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-breadcrumb></route-breadcrumb>
          </nav>
        </b-col>
        <b-col lg="6" cols="5" class="text-right">
        </b-col>
      </b-row>
    </base-header>
    <b-container fluid class="mt--6">
      <b-card no-body>
        <!-- Card header -->
        <b-card-header>
          <div>
            <div class="float-left"><h3 class="mb-0">提现配置</h3></div>
            <div class="float-right">
              <base-button type="secondary" size="sm" icon class="btn-icon" @click="getData">
                <span><i class="fa fa-refresh"></i></span>
                <span>刷新</span>
              </base-button>
              <base-button type="primary" size="sm" icon @click.native="save">
                <i class="fa fa-check"></i>保存
              </base-button>
            </div>
          </div>
        </b-card-header>
        <!-- Card body -->
        <b-card-body>
          <el-form :model="model" ref="form" label-position="top">
            <el-row :gutter="10">
              <el-col :span="5">
                <el-form-item prop="down_daily_times" label="每日可提现次数" required>
                  <el-input v-model="model.down_daily_times" type="number"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="5">  
                <el-form-item prop="down_min_amount" label="最低提现金额" required>
                  <el-input v-model="model.down_min_amount" type="number"></el-input>
                </el-form-item>
              </el-col>
              <el-col>
                <el-form-item label="可提现时间段">
                  <el-time-select
                    v-model="model.down_start_time"
                    :picker-options="{
                      start: '00:00',
                      step: '00:10',
                      end: '23:50'
                    }"
                    placeholder="任意时间点">
                  </el-time-select>
                  至
                  <el-time-select
                    arrow-control
                    v-model="model.down_end_time"
                    :picker-options="{
                      start: '00:00',
                      step: '00:10',
                      end: '23:50',
                      minTime: model.down_start_time
                    }"
                    placeholder="任意时间点">
                  </el-time-select>
                </el-form-item>
                <el-form-item prop="down_comment" label="提现页面说明文字" required>
                  <el-input v-model="model.down_comment"></el-input>
                </el-form-item>
                <el-form-item prop="down_time_invalid_prompt" label="非提现时间段提示语" required>
                  <el-input v-model="model.down_time_invalid_prompt"></el-input>
                </el-form-item>
                <el-form-item prop="down_over_daily_times_prompt" label="提现次数已满提示语" required>
                  <el-input v-model="model.down_over_daily_times_prompt"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </b-card-body>
      </b-card>
    </b-container>
  </div>
</template>

<script>
import { get, modify } from '@/api/config/param'
export default {
  data () {
    return {
      loading: false,
      model: {
        down_daily_times: 0,
        down_min_amount: 0,
        down_start_time: '',
        down_end_time: '',
        down_comment: '',
        down_time_invalid_prompt: '',
        down_over_daily_times_prompt: ''
      }
    }
  },
  created () {
    this.getData()
  },
  methods: {
    getData () {
      this.loading = true
      get('down_daily_times', 'down_min_amount', 'down_start_time', 'down_end_time', 'down_comment', 'down_time_invalid_prompt',
        'down_over_daily_times_prompt').then(res => {
        this.loading = false
        if (res.success) {
          this.model = res.data
        }
      })
    },
    save () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          modify(this.model).then(res => {
            if (res.success) {
              this.$bvToast.toast('操作成功', {
                title: '提示',
                solid: true,
                variant: 'success',
                autoHideDelay: 3000,
                toaster: 'b-toaster-top-center'
              })
            }
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    }
  }
}
</script>
<style lang="scss">
.param {
  .el-upload-list--picture-card .el-upload-list__item {
    height: 100%;
  }
  .el-upload-list--picture-card .el-upload-list__item-thumbnail {
  width: 148px;
  height: 100%;
}
}
</style>