<template>
  <div>
    <base-header class="pb-6">
      <b-row align-v="center" class="py-4">
        <b-col cols="7" lg="6">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-breadcrumb></route-breadcrumb>
          </nav>
        </b-col>
        <b-col lg="6" cols="5" class="text-right">
        </b-col>
      </b-row>
    </base-header>
    <b-container fluid class="mt--6">
      <b-card no-body>
        <!-- Card header -->
        <b-card-header>
          <div>
            <div class="float-left"><h3 class="mb-0">公众号配置</h3></div>
            <div class="float-right">
              <base-button
                @click.native="save"
                type="primary"
                size="sm"
                icon
              >
                <i class="fa fa-check"></i>保存
              </base-button>
            </div>
          </div>
        </b-card-header>
        <!-- Card body -->
        <b-card-body>
          <el-form :model="model" ref="form" label-position="top">
            <el-form-item prop="mp_appid" label="公众号appid" required>
              <el-input v-model="model.mp_appid"></el-input>
            </el-form-item>
            <el-form-item prop="mp_secret" label="公众号密钥" required>
              <el-input v-model="model.mp_secret"></el-input>
            </el-form-item>
            <el-form-item prop="mp_redirect_uri" label="公众号跳转地址" required>
              <el-input v-model="model.mp_redirect_uri"></el-input>
            </el-form-item>
          </el-form>
        </b-card-body>
      </b-card>
    </b-container>
  </div>
</template>

<script>
import { get, modify } from '@/api/config/param'
export default {
  data () {
    return {
      model: {
        mp_appid: '',
        mp_secret: '',
        mp_redirect_uri: ''
      }
    }
  },
  created () {
    get('mp_appid', 'mp_secret', 'mp_redirect_uri').then(res => {
      if (res.success) {
        this.model = res.data
      }
    })
  },
  methods: {
    save () {
      modify(this.model).then(res => {
        if (res.success) {
          this.$bvToast.toast('操作成功', {
            title: '提示',
            solid: true,
            variant: 'success',
            autoHideDelay: 3000,
            toaster: 'b-toaster-top-center'
          })
        }
      })
    }
  }
}
</script>