<template>
  <el-dialog
    v-modal-drag
    :title="'手动补奖：'+model.gameCode+'-'+model.gameName"
    :visible.sync="visible"
    width="30%"
    class="issue-form"
    :before-close="close">
    <el-form :model="model" ref="form" :rules="rules" label-position="top" @keyup.enter.native="submit">
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="游戏ID">
            <span class="text-red">{{model.gameCode}}</span>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="游戏名称">
            <span class="text-red">{{model.gameName}}</span>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="期号">
            <span class="text-red">{{model.drawIssue}}</span>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="开奖时间">
            <span class="text-red">{{model.drawTime}}</span>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="24">
          <el-form-item label="开奖号码" prop="drawCode" required>
            <draw-code-input ref="drawCodeInput" :gameType="issue.gameType" v-model="model.drawCode"></draw-code-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div slot="footer">
      <base-button size="sm" type="primary" @click="submit">保存</base-button>
      <base-button size="sm" type="link" class="ml-auto" @click="close">取消</base-button>
    </div>
  </el-dialog>
</template>
<script>
import { Modal } from '@/components';
import DrawCodeInput from '@/views/Components/DrawCodeInput'
import { repairOpen } from '@/api/config/issue'
export default {
  name: 'CategoryForm',
  props: ['visible', 'issue'],
  components: {
    Modal,
    DrawCodeInput
  },
  data () {
    let drawCodeValidator = (rule, value, callback) => {
      // console.log('drawCodeValidator value='+value+' gameType='+this.issue.gameType)
      let codeArr = value.split(',')
      if (this.issue.gameType == 'PK') {
        if (codeArr.length!=10) {
          callback(new Error('请填写开奖号码'))
        }
      } else if (this.issue.gameType == 'SS') {
        if (codeArr.length!=5) {
          callback(new Error('请填写开奖号码'))
        }
        codeArr.forEach(code => {
          if (!/^[0-9]$/.test(code)) {
            callback(new Error('请填写数字0-9'))
          }
        })
      } else if (this.issue.gameType == 'PC20' || this.issue.gameType == 'PC28' || this.issue.gameType == 'PC32') {
        if (codeArr.length!=3) {
          callback(new Error('请填写开奖号码'))
        }
        codeArr.forEach(code => {
          if (!/^[0-9]$/.test(code)) {
            callback(new Error('请填写数字0-9'))
          }
        })
      }
      callback()
    }
    return {
      model: {
        id: '',
        gameCode: '',
        gameName: '',
        gameType: '',
        drawIssue: '',
        drawCode: ''
      },
      rules: {
        drawCode: [{
          validator: drawCodeValidator,
          trigger: blur
        }]
      }
    }
  },
  watch: {
    'issue': {
      handler: function (val) {
        if (val) {
          this.model = Object.assign(this.model, val)
        }
        this.model.drawCode = ''
      },
      immediate: true
    }
  },
  methods: {
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          repairOpen(this.model).then(res => {
            if (res.success) {
              this.close()
              this.$emit('submit')
            }
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    close () {
      this.model.drawCode = ''
      console.log('this.model.drawCode', this.model.drawCode)
      this.$emit('update:visible', false)
    },

  }
}
</script>
<style lang="scss">
.issue-form {
  .el-form-item,
  .el-form-item__content {
    display:flex;
    align-items:center;
  }
}
</style>