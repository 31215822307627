import { formFetch, jsonFetch } from '@/utils/fetch'
import Qs from 'qs'

export function list () {
  return formFetch.post('/charge/list')
}

export function switchStatus (id, available) {
  return formFetch.post(`/charge/${id}/status`, Qs.stringify({ available }))
}

export function detail (id) {
  return formFetch.post(`/charge/${id}/detail`)
}

export function create (model) {
  return jsonFetch.post(`/charge/create`, model)
}

export function modify (model) {
  return jsonFetch.post(`/charge/${model.id}/modify`, model)
}

export function del (id) {
  return formFetch.post(`/charge/${id}/delete`)
}

export function sort (ids) {
  return jsonFetch.post(`/charge/sort`, ids)
}