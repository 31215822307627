import { formFetch, jsonFetch } from '@/utils/fetch'
import Qs from 'qs'

// 待返佣列表
export function listUnsettled (query) {
  return jsonFetch.post('/return-commission/unsettled', query)
}
// 已返佣列表
export function listSettled (query) {
  return jsonFetch.post('/return-commission/settled', query)
}
// 通过所有
export function acceptAll(query) {
  return jsonFetch.post('/return-commission/acceptAll', query)
}
// 通过
export function accept(date, uid, amount) {
  return formFetch.post('/return-commission/accept', Qs.stringify({date, uid, amount}))
}
// 拒绝
export function reject(date, uid) {
  return formFetch.post('/return-commission/reject', Qs.stringify({date, uid}))
}