<template>
  <div v-loading="loading">
    <base-header class="pb-6">
      <b-row align-v="center" class="py-4">
        <b-col cols="7" lg="6">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-breadcrumb></route-breadcrumb>
          </nav>
        </b-col>
        <b-col lg="6" cols="5" class="text-right">
        </b-col>
      </b-row>
    </base-header>
    <b-container fluid class="mt--6">
      <b-card no-body>
        <!-- Card header -->
        <b-card-header>
          <div>
            <div class="float-left"><h3 class="mb-0">房间入口</h3></div>
            <div class="float-right">
              <base-button type="secondary" size="sm" icon class="btn-icon" @click="getData">
                <span><i class="fa fa-refresh"></i></span>
                <span>刷新</span>
              </base-button>
            </div>
          </div>
        </b-card-header>
        <!-- Card body -->
        <b-card-body>
          <div class="flex justify-center">
            <div v-for="item in domains">
              <figure class="qrcode text-center">
                <vue-qrcode
                  :value="item.domain+'/'+rootId"
                  :options="{width:200}"
                  >
                </vue-qrcode>
                <div class="text-center">{{item.domain+'/'+rootId}}</div>
                <el-button icon="el-icon-document-copy" v-clipboard:copy="item.domain+'/'+rootId" v-clipboard:success="onCopy">复制</el-button>
              </figure>
            </div>
          </div>
        </b-card-body>
      </b-card>
    </b-container>
  </div>
</template>

<script>
import Vue from 'vue'
import VueQrcode from '@chenfengyuan/vue-qrcode'
import { list } from '@/api/config/domain'
import { find } from '@/api/member'
import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)
export default {
  components: {
    VueQrcode
  },
  data () {
    return {
      loading: false,
      query: {
        filter: {
          type: 'entry',
          available: 1
        },
      },
      totalCount: 0,
      domains: [],
      rootId: ''
    }
  },
  created () {
    this.getData()
  },
  methods: {
    getData () {
      this.loading = true
      find(0).then(res => {
        if (res.success) {
          this.rootId = res.data.code
          list(this.query).then(res => {
            this.loading = false
            if (res.success) {
              this.totalCount = res.totalCount
              this.domains = res.data
            }
          })
        }
      })
    },
    onCopy() {
      this.$notify({
        type: 'success',
        timeout: 1000,
        message: '链接已复制'
      })
    }
  }
}
</script>