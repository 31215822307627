<template>
  <el-dialog
    v-modal-drag
    :visible.sync="visible"
    width="50%"
    :title="action==='add'?'新增机器人':'编辑机器人'"
    @hide="close">
    <el-form :model="model" ref="form" label-position="top">
      <el-form-item prop="id" label="ID" required v-if="action==='modify'">
        <el-input v-model="model.id" disabled></el-input>
      </el-form-item>
      <el-form-item prop="nickName" label="昵称" required>
        <el-input v-model="model.nickName" maxlength="20"></el-input>
      </el-form-item>
      <el-form-item prop="avatar" label="头像链接">
        <el-input v-model="model.avatar" type="textarea" :rows="3" maxlength="200" show-word-limit></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <base-button size="sm" type="primary" @click="submit">保存</base-button>
      <base-button size="sm" type="link" class="ml-auto" @click="close">取消</base-button>
    </div>
  </el-dialog>
</template>
<script>
import GameSelect from '@/views/Components/game-select'
import { detail, create, modify } from '@/api/config/robot'
export default {
  name: 'RobotForm',
  props: ['visible', 'action', 'id'],
  components: {
    GameSelect
  },
  data () {
    return {
      model: {
        id: '',
        nickName: '',
        avatar: '',
        gameCode: ''
      }
    }
  },
  created () {
    let that = this
    if (this.action==='modify') {
      detail(this.id).then(res => {
        if (res.success) {
          this.model = res.data
        }
      })
    }
  },
  methods: {
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.action === 'add') {
            create(this.model).then(res => {
              if (res.success) {
                this.close()
                this.$emit('submit')
              }
            })
          } else {
            modify(this.model).then(res => {
              if (res.success) {
                this.close()
                this.$emit('submit')
              }
            })
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    close () {
      this.$emit('update:visible', false)
    },
  }
}
</script>
<style lang="scss">

</style>