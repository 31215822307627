import { formFetch, jsonFetch } from '@/utils/fetch'
import Qs from 'qs'

// 代理列表
export function list (query) {
  return jsonFetch.post('/agent/list', query)
}
// 删除代理
export function del(uid) {
  return formFetch.post(`/agent/${uid}/delete`)
}