<template>    
  <div class="content">
    <base-header class="pb-6">
      <b-row align-v="center" class="py-4">
        <b-col cols="7" lg="6">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-breadcrumb></route-breadcrumb>
          </nav>
        </b-col>
        <b-col lg="6" cols="5" class="text-right">
        </b-col>
      </b-row>
    </base-header>
    <b-container fluid class="mt--6">
      <div>
        <card class="no-border-card" body-classes="px-0 pb-1" footer-classes="pb-2">
          <!-- <template slot="header">
            <h3 class="mb-0">Paginated tables</h3>
            <p class="text-sm mb-0">
              This is a client side example of paginated tables using element-ui tables.
            </p>
          </template> -->
          <div>
            <b-col cols="12" class="flex justify-between margin-bottom"
            >
              <div>
                <!-- <base-input v-model="query.filter.name"
                            prepend-icon="fa fa-search"
                            placeholder="Search...">
                </base-input> -->
              </div>
              <div class="float:right">
                <base-button
                  @click.native="handleExpand"
                  type="secondary"
                  size="sm"
                  icon
                >
                  <i class="fa fa-plus"></i>展开/收起
                </base-button>
                <base-button
                  @click.native="handleAdd"
                  type="primary"
                  size="sm"
                  icon
                >
                  <i class="fa fa-plus"></i>新增
                </base-button>
              </div>
            </b-col>
            <el-table :data="tableData"
                      ref="table"
                      size="small"
                      row-key="id"
                      border
                      default-expand-all
                      :tree-props="{children: 'childNodes', hasChildren: 'hasChildren'}"
                      v-loading="loading"
                      element-loading-text="加载中..."
                      header-row-class-name="thead-light"
                      @sort-change="sortChange"
                      @selection-change="selectionChange">
              <el-table-column
                v-for="column in tableColumns"
                :key="column.prop"
                :prop="column.prop"
                align="center"
                v-bind="column"
              >
                <template v-slot="{row}">
                  <base-switch 
                      v-if="column.prop==='available'"
                      v-model="row.available" on-text="启用" off-text="禁用" 
                      type="success" size="sm" @change="handleSwitchStatus(row)">
                    </base-switch>
                  <span v-else>{{ row[column.prop] }}</span>
                </template>
              </el-table-column>
              <el-table-column min-width="250px" header-align="center" align="center" label="操作">
                <div slot-scope="{$index, row}" class="d-flex">
                  <base-button
                    @click.native="handleEdit($index, row)"
                    type="info"
                    size="sm"
                    outline
                    icon
                  >
                    <i class="fa fa-edit"></i>编辑
                  </base-button>
                  <base-button
                    @click.native="handleDelete($index, row)"
                    type="danger"
                    size="sm"
                    outline
                    icon
                  >
                    <i class="fa fa-trash"></i>删除
                  </base-button>
                  <base-button
                    v-if="row.path==='0'"
                    @click.native="handleAddChild($index, row)"
                    type="info"
                    size="sm"
                    outline
                    icon
                  >
                    <i class="fa fa-plus"></i>添加子菜单
                  </base-button>
                </div>
              </el-table-column>
            </el-table>
          </div>
          <div
            slot="footer"
          >
          </div>
        </card>
      </div>
    </b-container>
    <el-dialog
      v-modal-drag
      :visible.sync="formModal"
      width="30%"
      :title="formAction==='create' ? '新增菜单' : '编辑菜单'">
      <menu-form :action="formAction" :id="formId" ref="form" @submit="handleSubmit"></menu-form>
      <div slot="footer">
        <base-button size="sm" type="primary" @click="handleSave">保存</base-button>
        <base-button size="sm" type="link" class="ml-auto" @click="formModal = false">取消</base-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { Table, TableColumn, Select, Option, Link } from 'element-ui';
import { BasePagination } from '@/components';
import clientPaginationMixin from '@/views/Tables/PaginatedTables/clientPaginationMixin'
import { list, switchStatus, del } from '@/api/system/menu'
import { Modal, BaseAlert } from '@/components';
import MenuForm from './form'
export default {
  mixins: [clientPaginationMixin],
  components: {
    Modal,
    BaseAlert,
    MenuForm,
    BasePagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Link.name]: Link
  },
  data() {
    return {
      loading: false,
      formModal: false,
      propsToSearch: ['id', 'label', 'url'],
      tableColumns: [
        {
          prop: 'id',
          label: 'ID',
          minWidth: 150,
          sortable: true
        },
        {
          prop: 'name',
          label: '名称',
          minWidth: 150,
          sortable: true
        },
        {
          prop: 'label',
          label: '标题',
          minWidth: 150,
          sortable: true
        },
        {
          prop: 'url',
          label: 'url',
          minWidth: 150
        },
        {
          prop: 'order',
          label: '排序',
          minWidth: 100,
          sortable: true
        },
        {
          prop: 'available',
          label: '状态',
          minWidth: 100,
          sortable: true
        }
      ],
      expand: true,
      tableData: [],
      selectedRows: [],
      formAction: '',
      formId: ''
    };
  },
  created() {
    this.getData()
  },
  methods: {
    handleExpand () {
      this.tableData.forEach(row => {
        if (row.childNodes.length>0) {
          this.$refs.table.toggleRowExpansion(row, !this.expand)
        }
      })
      this.expand = !this.expand
    },
    handleAdd() {
      this.formAction = 'create'
      this.formId = ''
      this.formModal = true
    },
    handleAddChild(index, row) {
      this.formAction = 'create'
      this.formId = row.id
      this.formModal = true
    },
    handleEdit(index, row) {
      this.formAction = 'modify'
      this.formId = row.id
      this.formModal = true
    },
    handleDelete(index, row) {
      this.$bvModal.msgBoxConfirm('确定删除?', {
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: '确定',
        cancelTitle: '取消',
        centered: true
      })
        .then(value => {
          if (value) {
            del(row.id).then(res => {
              if (res.success) {
                this.getData()
                this.$bvToast.toast('操作成功', {
                  title: '提示',
                  solid: true,
                  variant: 'success',
                  autoHideDelay: 3000,
                  toaster: 'b-toaster-top-center'
                })
              }
            })
          }
        })
    },
    selectionChange(selectedRows) {
      this.selectedRows = selectedRows
    },
    getData () {
      this.loading = true
      list().then(res => {
        this.loading = false
        if (res.success) {
          this.tableData = res.data
        }
      })
    },
    handleSwitchStatus(row) {
      switchStatus(row.id, row.available).then(res => {
        if (res.success) {
          this.getData()
        }
      })
    },
    handleSave () {
      this.$refs.form.submit()
    },
    handleSubmit() {
      this.formModal = false
      this.getData()
      this.$bvToast.toast('操作成功', {
        title: '提示',
        solid: true,
        variant: 'success',
        autoHideDelay: 3000,
        toaster: 'b-toaster-top-center'
      })
    },
  }
};
</script>
<style>
  .no-border-card .card-footer{
    border-top: 0;
  }
</style>
