<template>    
  <div class="content">
    <base-header class="pb-6">
      <b-row align-v="center" class="py-4">
        <b-col cols="7" lg="6">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-breadcrumb></route-breadcrumb>
          </nav>
        </b-col>
        <b-col lg="6" cols="5" class="text-right">
        </b-col>
      </b-row>
    </base-header>
    <b-container fluid class="mt--6">
      <div>
        <card class="no-border-card" body-classes="px-0 pb-1" footer-classes="pb-2">
          <div>
            <div class="pl-3 pr-3 pb-3 float-right">
              <base-button
                @click.native="showModal(0, 'form', 'add')"
                type="primary"
                size="sm"
                icon
              >
                <i class="fa fa-plus"></i>新增
              </base-button>
            </div>
            <el-table :data="tableData"
                      size="small"
                      row-key="id"
                      border
                      v-loading="loading"
                      header-row-class-name="thead-light">
              <el-table-column prop="id" label="ID" width="100" align="center">
                <template v-slot="{row}">
                  <span><i class="el-icon-sort margin-right drag-column" style="cursor:move" title="按住拖动排序"></i></span>
                  {{row.id}}
                </template>
              </el-table-column>
              <el-table-column prop="title" label="标题" width="300" align="center"></el-table-column>
              <el-table-column prop="status" label="状态" width="100" align="center">
                <div slot-scope="{$index, row}" class="flex justify-center">
                  <base-switch 
                    v-model="row.available" on-text="激活" off-text="未激活" 
                    type="success" size="sm" @change="handleSwitchStatus(row)">
                  </base-switch>
                </div>
              </el-table-column>
              <el-table-column prop="popup" label="是否弹窗" width="100" align="center">
                <div slot-scope="{$index, row}" class="flex justify-center">
                  <base-switch 
                    v-model="row.popup" on-text="是" off-text="否" 
                    type="success" size="sm" disabled>
                  </base-switch>
                </div>
              </el-table-column>
              <el-table-column prop="popupTimes" label="弹窗次数" width="100" align="center"></el-table-column>
              <el-table-column prop="createTime" label="创建时间" width="150" align="center" sortable="custom"></el-table-column>
              <el-table-column width="170px" align="center" label="操作">
                <div slot-scope="{$index, row}" class="flex justify-center">
                  <base-button
                    @click.native="showModal(row.id, 'form', 'modify')"
                    type="info"
                    size="sm"
                    outline
                  >
                    <i class="fa fa-edit"></i>编辑
                  </base-button>
                  <base-button
                    @click.native="handleDelete(row.id)"
                    type="danger"
                    size="sm"
                    outline
                  >
                    <i class="fa fa-trash"></i>删除
                  </base-button>
                </div>
              </el-table-column>
            </el-table>
          </div>
          <div
            slot="footer"
          >
          </div>
        </card>
      </div>
    </b-container>
    <!--Modals-->
    <edit-form v-if="modal.form" :visible.sync="modal.form" :id="id" :action="formAction" @submit="handleEditDone"></edit-form>
  </div>
</template>
<script>
import Sortable from 'sortablejs'
import { list, switchStatus, del } from '@/api/config/announcement'
import EditForm from './form'
import { BASE_URL } from '@/utils/fetch' 
export default {
  components: {
    EditForm
  },
  data() {
    return {
      loading: false,
      query: {
        filter: {
          id: '',
          type: ''
        },
        start: 0,
        limit: 10,
        sort: null
      },
      totalCount: 0,
      tableData: [],
      selectedRows: [],
      formAction: '',
      id: '',
      modal: {
        form: false
      }
    };
  },
  created () {
    this.getData()
  },
  methods: {
    getData(clear) {
      this.loading = true
      if (clear) {
        this.tableData = []
      }
      list(this.query).then(res => {
        this.loading = false
        if (res.success) {
          this.totalCount = res.totalCount
          this.tableData = res.data
        }
        this.$nextTick(() => {
          this.initSortable()
        })
      })
    },
    handleSwitchStatus(row) {
      switchStatus(row.id, row.available).then(res => {
        if (res.success) {
          this.getData()
        }
      })
    },
    showModal(id, type, action) {
      this.id = id
      this.formAction = action
      this.modal[type] = true
    },
    handleEditDone () {
      this.modal.form = false
      this.getData(true)
    },
    handleDelete(id) {
      this.$bvModal.msgBoxConfirm('确定删除?', {
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: '确定',
        cancelTitle: '取消',
        centered: true
      })
        .then(value => {
          if (value) {
            del(id).then(res => {
              if (res.success) {
                this.getData()
                this.$bvToast.toast('操作成功', {
                  title: '提示',
                  solid: true,
                  variant: 'success',
                  autoHideDelay: 3000,
                  toaster: 'b-toaster-top-center'
                })
              }
            })
          }
        })
    },
    initSortable () {
      const tbody = document.querySelector('.el-table__body-wrapper tbody')
      const that = this
      Sortable.create(tbody, {
        handle: ".drag-column", 
        onEnd({ newIndex, oldIndex }) {
          const currRow = that.tableData.splice(oldIndex, 1)[0]
          that.tableData.splice(newIndex, 0, currRow)
          let ids = [];
          that.tableData.forEach(record => {
            ids.push(record.id)
          })
          that.loading = true
          sort(ids).then(res => {
            that.getData()
            that.$bvToast.toast('排序已更新', {
              title: '提示',
              solid: true,
              variant: 'success',
              autoHideDelay: 3000,
              toaster: 'b-toaster-top-center'
            })
          })
        }
      })
    }
  }
};
</script>
<style>
  .no-border-card .card-footer{
    border-top: 0;
  }
</style>
