<template>
  <div>
    <base-header class="pb-6">
      <b-row align-v="center" class="py-4">
        <b-col cols="7" lg="6">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-breadcrumb></route-breadcrumb>
          </nav>
        </b-col>
        <b-col lg="6" cols="5" class="text-right">
        </b-col>
      </b-row>
    </base-header>
    <b-container fluid class="mt--6">
      <b-card no-body>
        <!-- Card header -->
        <b-card-header>
          <div>
            <div class="float-left"><h3 class="mb-0">滚动公告</h3></div>
            <div class="float-right">
              <base-button
                @click.native="save('rolling_notice')"
                type="primary"
                size="sm"
                icon
              >
                <i class="fa fa-check"></i>保存
              </base-button>
            </div>
          </div>
        </b-card-header>
        <!-- Card body -->
        <b-card-body>
          <form>
            <el-input v-model="notice.rolling_notice" type="textarea" :rows="5" maxlength="100" show-word-limit></el-input>
          </form>
        </b-card-body>
      </b-card>
    </b-container>
  </div>
</template>

<script>
import HtmlEditor from '@/components/Inputs/HtmlEditor'
import { get, modify } from '@/api/config/param'
export default {
  components: {
    HtmlEditor
  },
  data () {
    return {
      notice: {
        rolling_notice: ''
      }
    }
  },
  created () {
    get('rolling_notice').then(res => {
      if (res.success) {
        this.notice = res.data
      }
    })
  },
  methods: {
    save (key) {
      let paramMap = {}
      paramMap[key] = this.notice[key]
      modify(paramMap).then(res => {
        if (res.success) {
          this.$bvToast.toast('操作成功', {
            title: '提示',
            solid: true,
            variant: 'success',
            autoHideDelay: 3000,
            toaster: 'b-toaster-top-center'
          })
        }
      })
    }
  }
}
</script>