<template>
  <div class="draw-code-input flex">
    <!-- code:{{code}} -->
    <div v-if="gameType=='PK'">
      <draggable :list="codeArray" group="pk" @start="drag=true" @end="drag=false">
        <img
          v-for="tempCode in codeArray"
          :key="tempCode"
          :src="'/img/num/'+tempCode+'.png'"
          class="num"
          :class="'bg-num-'+parseInt(tempCode)">
        </img>
      </draggable>
    </div>
    <div v-else-if="gameType=='SS'">
      <el-input ref="ss_code_1" v-model="ss_code_1" minlength="1" maxlength="1" min="0" max="9" style="width:32px"
        @input="handleInput('ss_code_1')"
        @keyup.right.native="handleRight('ss_code_1')"
        @keyup.backspace.native="handleBackspace('ss_code_1')">>
      </el-input>
      <el-input ref="ss_code_2" v-model="ss_code_2" minlength="1" maxlength="1" min="0" max="9" style="width:32px;margin-left:10px"
        @input="handleInput('ss_code_2')"
        @keyup.left.native="handleLeft('ss_code_2')"
        @keyup.right.native="handleRight('ss_code_2')"
        @keyup.backspace.native="handleBackspace('ss_code_2')">
      </el-input>
      <el-input ref="ss_code_3" v-model="ss_code_3" minlength="1" maxlength="1" min="0" max="9" style="width:32px;margin-left:10px"
        @input="handleInput('ss_code_3')"
        @keyup.left.native="handleLeft('ss_code_3')"
        @keyup.right.native="handleRight('ss_code_3')"
        @keyup.backspace.native="handleBackspace('ss_code_3')">
      </el-input>
      <el-input ref="ss_code_4" v-model="ss_code_4" minlength="1" maxlength="1" min="0" max="9" style="width:32px;margin-left:10px"
        @input="handleInput('ss_code_4')"
        @keyup.left.native="handleLeft('ss_code_4')"
        @keyup.right.native="handleRight('ss_code_4')"
        @keyup.backspace.native="handleBackspace('ss_code_4')">
      </el-input>
      <el-input ref="ss_code_5" v-model="ss_code_5" minlength="1" maxlength="1" min="0" max="9" style="width:32px;margin-left:10px"
        @input="handleInput('ss_code_5')"
        @keyup.left.native="handleLeft('ss_code_5')"
        @keyup.backspace.native="handleBackspace('ss_code_5')">
      </el-input>
    </div>
    <div v-else-if="gameType=='PC20' || gameType=='PC28' || gameType=='PC32'">
      <el-input ref="pc_code_1" v-model="pc_code_1" minlength="1" maxlength="1" min="0" max="9" style="width:32px"
        @input="handleInput('pc_code_1')"
        @keyup.right.native="handleRight('pc_code_1')"
        @keyup.backspace.native="handleBackspace('pc_code_1')">
      </el-input>
      <el-input ref="pc_code_2" v-model="pc_code_2" minlength="1" maxlength="1" min="0" max="9" style="width:32px;margin-left:10px"
        @input="handleInput('pc_code_2')"
        @keyup.left.native="handleLeft('pc_code_2')"
        @keyup.right.native="handleRight('pc_code_2')"
        @keyup.backspace.native="handleBackspace('pc_code_2')">
      </el-input>
      <el-input ref="pc_code_3" v-model="pc_code_3" minlength="1" maxlength="1" min="0" max="9" style="width:32px;margin-left:10px"
        @input="handleInput('pc_code_3')"
        @keyup.left.native="handleLeft('pc_code_3')"
        @keyup.backspace.native="handleBackspace('pc_code_3')">
      </el-input>
    </div>
    <el-button v-show="gameType=='SS' || gameType=='PC20' || gameType=='PC28' || gameType=='PC32'" type="danger" plain class="margin-left" @click="handleClear">清除</el-button>
  </div>
</template>
<script>
import draggable from 'vuedraggable'
export default {
  props: ['gameType','value'],
  components: {
    draggable
  },
  data () {
    return {
      code: '',
      ss_code_1: '',
      ss_code_2: '',
      ss_code_3: '',
      ss_code_4: '',
      ss_code_5: '',
      pc_code_1: '',
      pc_code_2: '',
      pc_code_3: '',
      codeArray: ['01','02','03','04','05','06','07','08','09','10']
    }
  },
  watch: {
    'value': {
      handler: function (val) {
        this.code = val
      },
      immediate: true
    },
    'codeArray': {
      handler: function (val) {
        if (this.gameType=='PK') {
          this.code = val.toString()
          this.handleChange()
        }
      },
      immediate: true
    }
  },
  mounted () {
    this.initFocus()
  },
  beforeDestroy () {
    this.handleClear()
  },
  methods: {
    initFocus () {
      if (this.gameType=='SS') {
        this.$refs.ss_code_1.focus()
      } else if (this.gameType=='PC20' || this.gameType=='PC28' || this.gameType=='PC32') {
        this.$refs.pc_code_1.focus()
      }
    },
    handleChange () {
      if (this.code) {
        this.code = this.code.replaceAll('，', ',')
      }
      this.$emit('input', this.code)
      this.$emit('change', this.code)
    },
    checkSSCode () {
      this.code = this.ss_code_1 + ',' + this.ss_code_2 + ',' + this.ss_code_3 + ',' + this.ss_code_4 + ',' + this.ss_code_5
      this.handleChange()
    },
    checkPCCode () {
      this.code = this.pc_code_1 + ',' + this.pc_code_2 + ',' + this.pc_code_3
      this.handleChange()
    },
    handleInput (inputCode) {
      const input = this.$refs[inputCode]
      const arr = inputCode.split('_')
      const nextCode = parseInt(arr[2])+1
      const nextInputCode = arr[0] + '_' + arr[1] + '_' + nextCode
      const nextInput = this.$refs[nextInputCode]
      if (this[inputCode]) {
        if (/^[0-9]$/.test(this[inputCode])) {
          if (arr[0]=='ss') {
            this.checkSSCode()
          } else if (arr[0]=='pc') {
            this.checkPCCode()
          }
          if (nextInput) {
            nextInput.focus()
            nextInput.select()
          }
        } else {
          this[inputCode] = ''
        }

      }
    },
    handleBackspace (inputCode) {
      const input = this.$refs[inputCode]
      const arr = inputCode.split('_')
      const lastCode = parseInt(arr[2])-1
      const lastInputCode = arr[0] + '_' + arr[1] + '_' + lastCode
      const lastInput = this.$refs[lastInputCode]
      if (!this[inputCode]) {
        // lastInput.focus()
        if (lastInput) {
          lastInput.select()
        }
      }
      if (arr[0]=='ss') {
        this.checkSSCode()
      } else if (arr[0]=='pc') {
        this.checkPCCode()
      }
    },
    handleLeft (inputCode) {
      const input = this.$refs[inputCode]
      const arr = inputCode.split('_')
      const lastCode = parseInt(arr[2])-1
      const lastInputCode = arr[0] + '_' + arr[1] + '_' + lastCode
      const lastInput = this.$refs[lastInputCode]
      if (lastInput) {
        // lastInput.focus()
        lastInput.select()
      }
    },
    handleRight (inputCode) {
      const input = this.$refs[inputCode]
      const arr = inputCode.split('_')
      const nextCode = parseInt(arr[2])+1
      const nextInputCode = arr[0] + '_' + arr[1] + '_' + nextCode
      const nextInput = this.$refs[nextInputCode]
      if (nextInput) {
        nextInput.focus()
        nextInput.select()
      }
    },
    handleClear () {
      this.ss_code_1 = ''
      this.ss_code_2 = ''
      this.ss_code_3 = ''
      this.ss_code_4 = ''
      this.ss_code_5 = ''
      this.pc_code_1 = ''
      this.pc_code_2 = ''
      this.pc_code_3 = ''
      this.code = ''
      this.handleChange()
      this.initFocus()
    }
  }
}
</script>
<style lang="scss">
.draw-code-input {
  .num {
    margin-right:2px;
    width:25px;
    height:25px;
  }
  .el-input__inner {
    text-align: center;
    padding: 0 5px;
    font-weight: bold;
  }
}
</style>