<template>
  <el-dialog
    v-modal-drag
    width="80%"
    :visible.sync="visible"
    :title="title"
    @close="close">
    <div class="content">
      <el-form inline class="">
        <el-form-item>
          <el-radio-group v-model="query.filter.type" size="small">
            <el-radio-button label="">全部</el-radio-button>
            <el-radio-button label="sz">数字</el-radio-button>
            <el-radio-button label="sm">双面</el-radio-button>
            <el-radio-button label="ts">特殊</el-radio-button>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <el-table :data="tableData"
                ref="table"
                size="mini"
                row-key="id"
                show-summary
                :summary-method="getSummaries"
                v-loading="loading"
                element-loading-text="加载中..."
                header-row-class-name="thead-light">
        <el-table-column prop="uid" label="UID" width="100"></el-table-column>
        <el-table-column prop="gameName" label="游戏" width="120">
        </el-table-column>
        <el-table-column prop="issue" label="期号" width="120">
        </el-table-column>
        <el-table-column label="玩法" width="150">
          <div slot-scope="{$index, row}" class="d-flex">
            {{row.optionName}} - {{row.betName}}
          </div>
        </el-table-column>
        <el-table-column prop="odds" label="赔率" width="80">
        </el-table-column>
        <el-table-column prop="amount" label="金额" width="100">
        </el-table-column>
        <el-table-column prop="profit" label="结果" width="100">
          <template slot-scope="scope">
            <span :class="scope.row.profit>=0?'text-green':'text-red'">{{scope.row.profit}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="投注时间" width="150">
        </el-table-column>
        <el-table-column prop="drawCode" label="开奖结果">
        </el-table-column>
      </el-table>
      <base-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :query="query"
        :total="totalCount">
      </base-pagination>
    </div>
    <div slot="footer">
    </div>
  </el-dialog>
</template>

<script>
import { orderDetailList } from '@/api/member'
export default {
  props: ['visible','uid','parent','date','type','filter'],
  components: {
  },
  data() {
    return {
      loading: false,
      query: {
        filter: {
          uid: this.uid,
          parent: this.parent,
          date: this.date,
          type: this.type,
        },
        start: 0,
        limit: 10,
        sort: null
      },
      totalCount: 0,
      tableData: []
    };
  },
  computed: {
    title () {
      if (this.uid) {
        return '注单列表-'+this.uid+'-'+this.date
      } else if (this.parent) {
        return '下级注单列表-'+this.parent+'-'+this.date
      }
    }
  },
  watch: {
    'query': {
      handler: function (val) {
        this.getData()
      },
      immediate: true,
      deep: true
    },
    'filter': {
      handler: function (val) {
        if (val) {
          this.query.filter = Object.assign(this.query.filter, val)
        }
      },
      immediate: true,
      deep: true
    }
  },
  created() {
  },
  methods: {
    getData () {
      this.loading = true
      orderDetailList(this.query).then(res => {
        this.loading = false
        if (res.success) {
          this.totalCount = res.totalCount
          this.tableData = res.data
        }
      })
    },
    close () {
      this.$emit('update:visible', false)
    },
    getSummaries (param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        }
        if (!column.property || column.property!='amount' && column.property!='profit') {
          sums[index] = ''
          return
        }
        const values = data.map(item => Number(item[column.property]))
        console.log('column', column)
        if (!values.every(value => isNaN(value))) {
          sums[index] = values
            .reduce((prev, curr) => {
              const value = Number(curr)
              if (!isNaN(value)) {
                return prev + curr
              } else {
                return prev
              }
            }, 0)
            .toFixed(2)
        } else {
          sums[index] = 'N/A'
        }
      })
      return sums
    },
    handleSizeChange () {
      this.getData()
    },
    handleCurrentChange () {
      this.getData()
    },
  }
};
</script>