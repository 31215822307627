import { formFetch, jsonFetch } from '@/utils/fetch'
import Qs from 'qs'

export function list () {
  return formFetch.post('/announcement/list')
}

export function switchStatus (id, available) {
  return formFetch.post(`/announcement/${id}/status`, Qs.stringify({ available }))
}

export function detail (id) {
  return formFetch.post(`/announcement/${id}/detail`)
}

export function create (carousel) {
  return jsonFetch.post(`/announcement/create`, carousel)
}

export function modify (carousel) {
  return jsonFetch.post(`/announcement/${carousel.id}/modify`, carousel)
}

export function del (id) {
  return formFetch.post(`/announcement/${id}/delete`)
}