<template>
  <div title="点击修改备注">
    <span v-if="readOnly" class="text-red">{{notes===null || notes===''?'空值':notes}}</span>
    <el-link v-else :id="'pop-notes-'+uid" type="danger" class="margin-left-sm">
      {{notes===null || notes===''?'空值':notes}}
      <i class="el-icon-edit el-icon--right"></i>
    </el-link>
    <b-popover v-if="!readOnly" :target="'pop-notes-'+uid" triggers="click blur">
      <template #title>
        <div class="flex justify-between">
          <div>修改备注</div>
          <div><i class="el-icon-close" style="cursor: pointer;" @click="hideNotes"></i></div>
        </div>
      </template>
      <b-input-group>
        <b-form-input v-model="notes"></b-form-input>
        <b-input-group-append>
          <b-button size="sm" variant="outline-primary" @click="saveNotes">保存</b-button>
        </b-input-group-append>
      </b-input-group>
    </b-popover>
  </div>
</template>

<script>
import { saveNotes } from '@/api/member'
export default {
  name: 'MemberNotes',
  props: {
    uid: Number,
    notes: String,
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {}
  },
  watch: {
    notes (val) {
      this.$emit('update:notes', val)
    },
  },
  methods: {
    saveNotes () {
      saveNotes(this.uid, this.notes).then(res => {
        if (res.success) {
          this.hideNotes()
          this.$emit('saved')
        }
      })
    },
    hideNotes () {
      this.$root.$emit('bv::hide::popover')
    },
  }
}
</script>