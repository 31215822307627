<template>
  <div>
    <!-- Header -->
    <div class="header bg-gradient-success py-8 pt-10">
      <b-container>
        <div class="header-body text-center">
          <b-row class="justify-content-center">
            <b-col xl="5" lg="6" md="8" class="px-5">
              <h1 class="text-white">Welcome!</h1>
            </b-col>
          </b-row>
        </div>
      </b-container>
      <div class="separator separator-bottom separator-skew zindex-100">
        <svg x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1"
             xmlns="http://www.w3.org/2000/svg">
          <polygon class="fill-default" points="2560 0 2560 100 0 100"></polygon>
        </svg>
      </div>
    </div>
    <!-- Page content -->
    <b-container class="mt--8">
      <b-row class="justify-content-center">
        <b-col lg="5" md="7">
          <b-card no-body class="bg-secondary border-0 mb-0">
            <b-card-body class="px-lg-5 py-lg-5">
              <validation-observer v-slot="{handleSubmit}" ref="formValidator">
                <b-form role="form" @submit.prevent="handleSubmit(onSubmit)">
                  <base-input alternative
                              class="mb-3"
                              name="username"
                              :rules="{required: true}"
                              prepend-icon="ni ni-single-02"
                              placeholder="用户名"
                              v-model="model.username">
                  </base-input>

                  <base-input alternative
                              class="mb-3 mt-3"
                              name="Password"
                              :rules="{required: true, min: 6}"
                              prepend-icon="ni ni-lock-circle-open"
                              type="password"
                              placeholder="Password"
                              v-model="model.password">
                  </base-input>

                  <base-input alternative
                              class="mb-3 captcha"
                              name="captcha"
                              :rules="{required: true, min: 4}"
                              prepend-icon="ni ni-lock-circle-open"
                              type="number"
                              placeholder="图形码"
                              v-model="model.captcha">
                    <small slot="append"><img :src="captcha" @click="loadCaptcha" /></small>
                  </base-input>
                  <b-form-checkbox v-model="model.rememberMe">记住我</b-form-checkbox>
                  <div class="text-center">
                    <base-button type="primary" native-type="submit" style="width:100%" :loading="loading">登录</base-button>
                  </div>
                </b-form>
              </validation-observer>
            </b-card-body>
          </b-card>
          <!-- <b-row class="mt-3">
            <b-col cols="6">
              <router-link to="/dashboard" class="text-light"><small>Forgot password?</small></router-link>
            </b-col>
            <b-col cols="6" class="text-right">
              <router-link to="/register" class="text-light"><small>Create new account</small></router-link>
            </b-col>
          </b-row> -->
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        loading: false,
        captcha: '/api/auth/captcha',
        model: {
          username: '',
          password: '',
          captcha: '',
          rememberMe: false
        }
      };
    },
    mounted () {
      this.loadCaptcha()
    },
    methods: {
      onSubmit() {
        // this will be called only after form is valid. You can do api call here to login
        this.loading = true
        this.$store.dispatch('token/SignIn', this.model).then(() => {
          console.log('3333')
          this.$router.push('/home')
          console.log('4444')
        }).catch(res => {
          this.loading = false
          this.loadCaptcha()
          this.$swal.fire({
            title: res.msg,
            buttonsStyling: false,
            confirmButtonClass: 'btn btn-success btn-fill'
          });
        })
      },
      loadCaptcha () {
        this.captcha = '/api/auth/captcha?d=' + new Date().getTime()
        this.model.captcha = ''
      }
    }
  };
</script>
<style lang="scss">
.captcha {
  .input-group-text {
    padding: 0.325rem 0.75rem;
  }
}
</style>